const INITIAL_STATE = {
    id: null,
    nomeFantasia: null,
    cnpj: null,
    emailNotaFiscal: null,
    filial: "TODOS",
    inscEstadual: null,
    inscMunicipal: null,
    razaoSocial: null,
    status: "TODOS",
    // idUsuario: null,
};

export const filtroGE = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_GRUPO_ECONOMICO":
      return { ...action.payload };
        case "SET_PAGINA":
            return { ...state, pagina: action.payload };
        case "SET_QTD_REGISTRO":
            return { ...state, qtdRegistros: action.payload };

        default:
            return state;
    }
};
