import _ from "lodash";
import moment from "moment";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addMovimentacaoListaLancamento, esconderLoading, exibirLoading, setFiltroMov } from "../../actions";
import { deleteMovimentacao, getMovimentacaoListaLancamento, deletarArquivosMovimentacao } from "../../api";
import { DetalhesListaPorLinha } from "../../components/DetalhesListaPorLinha";
import { FiltroMovimentacaoPagarReceber } from "../../components/FiltroMovimentacaoPagarReceber";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ModalDescricaoDeCores } from "../../components/ModalDescricaoDeCores";
import { Navbar } from "../../components/Navbar";
import { RateioTelaEditarAdicLista } from "../../components/RateioTelaEditarAdicLista";
import "../../components/RateioTelaEditarAdicLista/styles.css";
import { ReactHintComponents } from "../../components/ReactHint";
import { HeaderComCalculos } from "../../components/HeaderComCalculos";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { vencimentoDiferenteDeModificada } from "../../service/validacaoRegras";
import { formatISO8601ToUser, transformDateToEndOfDay, transformDateToStartOfDay } from "../../utils";
import { formatValue } from "../../utils/value";
import { useParams } from "react-router-dom";
import { redirecionar } from "../../service/redirecionarAoDfc";
import { getNaturezaById } from "../../api";
import "./styles.css";

export const Movimentacao = (props) => {

  const { naturezaId, ano, mes, empresaId, tipo } = useParams();
  const[visible, setVisible] = useState(true);
  const[paramsDfc, setParamsDfc] = useState();
  const[naturezaDfc, setNaturezaDfc] = useState()

  const pageRedux = useSelector(
    (state) => state.filterPage
  )
  const page = pageRedux.page || props?.location?.state?.page
  const dispatch = useDispatch();
  const movimentacaoListaLancamento = useSelector(
    (state) => state.movimentacaoListaLancamento
  );
  const vencimentoOriginalInicio = useSelector(
    (state) => state.filtroMov.vencimentoOriginalInicio
  );

  const vencimentoModificadoInicio = useSelector(
    (state) => state.filtroMov.vencimentoModificadoInicio
  );
  const vencimentoModificadoFim = useSelector(
    (state) => state.filtroMov.vencimentoModificadoFim
  );
  const baixaInicio = useSelector((state) => state.filtroMov.baixaInicio);
  const baixaFim = useSelector((state) => state.filtroMov.baixaFim);
  const emissaoInicio = useSelector((state) => state.filtroMov.emissaoInicio);
  const emissaoFim = useSelector((state) => state.filtroMov.emissaoFim);

  const cadastroInicio = useSelector((state) => state.filtroMov.cadastroInicio);
  const cadastroFim = useSelector((state) => state.filtroMov.cadastroFim);
  const ultimaAlteracaoInicio = useSelector(
    (state) => state.filtroMov.ultimaAlteracaoInicio
  );
  const ultimaAlteracaoFim = useSelector(
    (state) => state.filtroMov.ultimaAlteracaoFim
  );
  const conciliacaoInicio = useSelector(
    (state) => state.filtroMov.conciliacaoInicio
  );
  const conciliacaoFim = useSelector((state) => state.filtroMov.conciliacaoFim);
  const faturamentoPrevistoInicio = useSelector(
    (state) => state.filtroMov.faturamentoPrevistoInicio
  );
  const faturamentoPrevistoFim = useSelector(
    (state) => state.filtroMov.faturamentoPrevistoFim
  );
  const competenciaInicio = useSelector(
    (state) => state.filtroMov.competenciaInicio
  );
  const competenciaFim = useSelector((state) => state.filtroMov.competenciaFim);
  const numeroUnicoLancamento = useSelector(
    (state) => state.filtroMov.numeroUnicoLancamento
  );
  const idMovimentacao = useSelector((state) => state.filtroMov.idMovimentacao);
  const vencidoSemBaixa = useSelector(
    (state) => state.filtroMov.vencidoSemBaixa
  );
  const empresa = useSelector((state) => state.filtroMov.empresa);

  const parceiro = useSelector((state) => state.filtroMov.parceiro);
  const creditoDebitoConciliado = useSelector(
    (state) => state.filtroMov.creditoDebitoConciliado
  );
  const natureza = useSelector((state) => state.filtroMov.natureza);
  const contaCorrente = useSelector((state) => state.filtroMov.contaCorrente);
  const historico = useSelector((state) => state.filtroMov.historico);

  const [abrirRateio, setAbrirRateio] = useState(false);
  const [movimentacao, setMovimentacao] = useState("");
  const [filtroVisivelLista, setFiltroVisivelLista] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);

  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }

    const getNomeNatureza = async () => {
      const natureza = await getNaturezaById(naturezaId);
      const nomeNatureza = natureza?.descricao;  
      setNaturezaDfc(nomeNatureza)
    }

    if (naturezaId && ano && mes && empresaId && tipo) {
      setParamsDfc(naturezaId && ano && mes && empresaId && tipo)
      getNomeNatureza();
    }
  
  }, [props, page, naturezaId, ano, mes, empresaId, tipo])

  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );

  const history = useHistory(); //chama a função para a troca de url.
  const linkAdicionar = () => {
    history.push("/movimentacao/cadastro", { page: lazyParams }); //uso do hook para ir para a página /adicionar.
  };
  const [exibirDetalhesLinha, setExibirDetalhesLinha] = useState(false);
  const [conteudoPorLinha, setConteudoPorLinha] = useState(null);
  const [excluirMovimentacao, setExcluirMovimentacao] = useState("");
  const [descricaoModalExcluir, setDescricaoModalExcluir] = useState("");
  const [dialogExcluirMovimentacao, setDialogExcluirMovimentacao] =
    useState(false);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);

  const [clearInputs, setClearInputs] = useState(false);
  const [totalDebito, setTotalDebito] = useState(null);
  const [totalCredito, setTotalCredito] = useState(null);
  const [totalLiquido, setTotalLiquido] = useState(null);
  const [totalConciliado, setTotalConciliado] = useState(null);

  const baixadoEdit = useSelector((state) => state.filtroMov.baixado);
  const vencimentoOriginalFimEdit = useSelector((state) => state.filtroMov.vencimentoOriginalFim);
  const rateadoEdit = useSelector((state) => state.filtroMov.rateado);
  const tipoLancamentoEdit = useSelector((state) => state.filtroMov.tipoLancamento);

  const [vencimentoOriginalFim, setVencimentoOriginalFim] = useState(new Date());

  const selecionarDetalhesPorLinha = (rowData) => {
    setExibirDetalhesLinha(true);
    setConteudoPorLinha(rowData);
  };

  const selecionarIdContrato = (rowData) => {
    setConteudoPorLinha(rowData);
    history.push(`/movimentacao/contrato/${rowData?.parcela?.contrato?.idContrato}`, { idContrato: rowData?.parcela?.contrato?.idContrato, pageMov: lazyParams });
  };

  function functionExcluirMovimentacao(rowData) {
    setDialogExcluirMovimentacao(true);
    setExcluirMovimentacao(rowData.id);
    setDescricaoModalExcluir(rowData.historico);
  }

  function redirectMovimentacao(){
    history.push("/movimentacoes");
    window.location.reload();
  }

  function handleNavigateToFiles(rowData){
    history.push(`/movimentacao/${rowData?.id}/anexos`, {
        id: rowData.id,
        type: "movimentacao",
      })
  }

  function editarMovimentacao(rowData) {
    rowData.retorno = "/movimentacao";
    history.push(`/movimentacao/${rowData?.id}`, { page: lazyParams });
  }

  const toast = useRef(null);

  function colunaConciliada(rowData) {
    if (rowData?.dataConciliado) {
      return (
        <>
          <Button
            className="formattedStyles formattedStyles--check"
            icon="pi pi-check-circle"
            tooltip={
              "Data Conciliada:  " +
              moment(rowData?.dataConciliado, "YYYY-MM-DDT00:00:00").format(
                "DD/MM/YYYY"
              )
            }
          />
        </>
      );
    } else
      return (
        <Button
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times-circle"
          tooltip="Movimentação Desconciliada"
        />
      );
  }

  const deletarMovimentacao = async (rowData) => {
    setCarregandoRequisicao(true);
    try {
      await deleteMovimentacao(rowData).then(() => carregarMovimentacaoListaLancamento());
      await deletarArquivosRelacionados(rowData);
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: 'Movimentação excluída com sucesso!',
        life: 5000,
      });
      setDialogExcluirMovimentacao(false);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
      
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  };

  const deletarArquivosRelacionados = async (id) => {
    try {
      await deletarArquivosMovimentacao(id);
    } catch (error) {
      console.error("Erro ao excluir arquivos relacionados:", error);
    }
  };

  const corDataVencimentoReferenteAModificada = (rowData) => {
    //regras de cor na coluna da data verde
    if (vencimentoDiferenteDeModificada(rowData)) {
      return (
        <span className="information--red">
          {" "}
          {moment(rowData?.vencimentoOriginal, "YYYY-MM-DDT00:00:00").format(
            "DD/MM/YYYY"
          )}{" "}
        </span>
      );
    }
    return (
      <span>
        {moment(rowData?.vencimentoOriginal, "YYYY-MM-DDT00:00:00").format(
          "DD/MM/YYYY"
        )}
      </span>
    );
  };

  function abrirLinhaComId(movimentacaoListaLancamento) {
    //para passar o id da movimentação.
    setAbrirRateio(true);
    setMovimentacao(movimentacaoListaLancamento);
  }

  const atualizaMovimentacaoListaLancamento = useCallback(
    //pagar e recebr ---
    (movimentacaoListaLancamento) => {
      dispatch(addMovimentacaoListaLancamento(movimentacaoListaLancamento));
    },
    [dispatch]
  );

  const montarFiltro = useCallback(() => {
    const filtroMov = {
      baixaInicio,
      baixaFim,
      baixado: baixadoEdit === null ? "NAO" : baixadoEdit === "TODOS" ? "TODOS" : baixadoEdit,
      cadastroFim: cadastroFim && transformDateToEndOfDay(cadastroFim),
      cadastroInicio: cadastroInicio && transformDateToStartOfDay(cadastroInicio),
      competenciaFim,
      competenciaInicio,
      conciliacaoFim,
      conciliacaoInicio,
      contaCorrente,
      creditoDebitoConciliado: creditoDebitoConciliado === null ? "TODOS" : creditoDebitoConciliado,
      emissaoFim,
      emissaoInicio,
      faturamentoPrevistoFim,
      faturamentoPrevistoInicio,
      historico,
      idMovimentacao,
      natureza,
      numeroUnicoLancamento,
      parceiro,
      empresa,
      rateado: rateadoEdit === null ? "TODOS" : rateadoEdit,
      tipoLancamento: tipoLancamentoEdit === null ? "TODOS" : tipoLancamentoEdit,
      ultimaAlteracaoFim: ultimaAlteracaoFim && transformDateToEndOfDay(ultimaAlteracaoFim),
      ultimaAlteracaoInicio: ultimaAlteracaoInicio && transformDateToStartOfDay(ultimaAlteracaoInicio),
      vencidoSemBaixa,
      vencimentoModificadoFim,
      vencimentoModificadoInicio,
      vencimentoOriginalFim: vencimentoOriginalFimEdit === null ? vencimentoOriginalFim : vencimentoOriginalFimEdit,
      vencimentoOriginalInicio,
    };

    if (paramsDfc !== null && paramsDfc !== undefined){
      const competenciaInicioDfc = `${ano}-${mes < 10 ? '0' + mes : mes}-01`;
      const competenciaFimDfc = `${ano}-${mes < 10 ? '0' + mes : mes}-${new Date(new Date().getFullYear(), mes, 0).getDate()}`;
      filtroMov.creditoDebitoConciliado = null;
      filtroMov.empresa = { id: parseInt(empresaId) };
      filtroMov.rateado = null;
      filtroMov.tipoLancamento = null;
      filtroMov.baixado = null;
      filtroMov.natureza = naturezaId;
      filtroMov.competenciaFim = tipo === "C" ? competenciaFimDfc : null;
      filtroMov.competenciaInicio = tipo === "C" ? competenciaInicioDfc : null;
      filtroMov.vencimentoModificadoFim = tipo === "FC" ? competenciaFimDfc : null;
      filtroMov.vencimentoModificadoInicio = tipo === "FC" ? competenciaInicioDfc : null;
      filtroMov.vencimentoOriginalFim = null;
    }
    return filtroMov;
  }, [ano, baixadoEdit, baixaFim, empresa, mes, baixaInicio, vencimentoOriginalFimEdit, cadastroFim, cadastroInicio, competenciaFim, paramsDfc, competenciaInicio, conciliacaoFim, conciliacaoInicio, contaCorrente, creditoDebitoConciliado, emissaoFim, emissaoInicio, empresaId, faturamentoPrevistoFim, faturamentoPrevistoInicio, historico, idMovimentacao, natureza, naturezaId, numeroUnicoLancamento, parceiro, rateadoEdit, tipo, tipoLancamentoEdit, ultimaAlteracaoFim, ultimaAlteracaoInicio, vencidoSemBaixa, vencimentoModificadoFim, vencimentoModificadoInicio, vencimentoOriginalFim, vencimentoOriginalInicio]);
  

  async function clearFilter() {
    setClear(true);
    setClearInputs(true);
    setParamsDfc(null);
    const newFiltroMov = { ...reduxStateInicial.filtroMov };
    newFiltroMov.creditoDebitoConciliado = "TODOS";
    newFiltroMov.vencimentoOriginalFim = null;
    newFiltroMov.baixado = "TODOS";
    setVencimentoOriginalFim(null);
    dispatch(setFiltroMov(newFiltroMov));
  }

  const limparDados = useCallback(()=> {
    dispatch(esconderLoading());
    atualizaMovimentacaoListaLancamento([]);
    setTotalRecords(null);
    setTotalCredito(null);
    setTotalDebito(null);
    setTotalLiquido(null);
    setTotalConciliado(null);
  },[atualizaMovimentacaoListaLancamento, dispatch]);
  const carregarMovimentacaoListaLancamento = useCallback(async () => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };
    await getMovimentacaoListaLancamento(requestParams)
      .then((resultado) => {
        if (!resultado || resultado.content?.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "O filtro selecionado não obteve resultado!",
          });
          return limparDados();
        }
        atualizaMovimentacaoListaLancamento(resultado.content);
        setTotalRecords(resultado.totalElements);
        setTotalCredito(resultado.content[0].totalCredito)
        setTotalDebito(resultado.content[0].totalDebito)
        setTotalLiquido(resultado.content[0].vlrTotalLiquido)
        setTotalConciliado(resultado.content[0].totalConciliado)
        atualizaMovimentacaoListaLancamento(resultado.content);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: error?.response?.data?.erroMensagem,
        });
        atualizaMovimentacaoListaLancamento([]);
      });
    setFiltroVisivelLista(false);
  }, [atualizaMovimentacaoListaLancamento, dispatch, montarFiltro, limparDados, lazyParams]);

  useEffect(() => {
    const checkAndLoadMovimentacao = async (filtroParams) => {
      const filtroOriginal = montarFiltro();
      const areEqual = _.isEqual(filtroParams, filtroOriginal);
  
      if (!areEqual) {
        await carregarMovimentacaoListaLancamento();
      }
    };

    const loadMovimentacao = async () => {
      await carregarMovimentacaoListaLancamento();
    }
  
    if (naturezaId === null || naturezaId === undefined) {
      const filtroParams = reduxStateInicial.filtroMov;
      checkAndLoadMovimentacao(filtroParams);
    }
  
    if (paramsDfc !== null && paramsDfc !== undefined) {
      loadMovimentacao()
    }
    
  }, [lazyParams, montarFiltro, paramsDfc, naturezaId, carregarMovimentacaoListaLancamento, reduxStateInicial]);
  
  const columns = [
    {
      field: "",
      header: "",
      style: { minWidth: 35, maxWidth: "4vw" },
      type: "custom",
      customFunc: colunaConciliada,
    },
    {
      field: "id",
      header: "Código",
      style: { minWidth: 45, maxWidth: "5vw" },
    },
    {
      field: "valorLiquido",
      header: "Líquido",
      style: { minWidth: 85, maxWidth: "10vw" },
      type: "customRight",
      customFunc: (e) => formatValue(e?.valorLiquido ? e?.valorLiquido : 0),
    },
    {
      field: "desdobramento",
      header: "Bruto",
      style: { minWidth: 85, maxWidth: "10vw" },
      type: "customRight",
      customFunc: (e) => formatValue(e.desdobramento ? e.desdobramento : 0),
    },
    {
      field: "parceiro.nomeTratamento",
      header: "Parceiro",
      style: { minWidth: 85, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "vencimentoOriginal",
      header: "Vencimento",
      style: { minWidth: 70, maxWidth: "5vw" },
      type: "custom",
      customFunc: corDataVencimentoReferenteAModificada,
    },
    {
      field: "baixa",
      header: "Baixa",
      style: { minWidth: 70, maxWidth: "5vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.baixa),
    },
    {
      field: "emissao",
      header: "Emissão",
      style: { minWidth: 70, maxWidth: "5vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.emissao),
    },
    {
      field: "notaFiscalOuRDD",
      header: "Documento",
      style: { minWidth: 60, maxWidth: "8vw" },
      type: "customEllipsis",
    },
    {
      field: "empresa.nomeFantasia",
      header: "Empresa",
      style: { minWidth: 100, maxWidth: "20vw" },
      type: "customEllipsis",
    },
  ];

  function verificarDataMovimentacao(movimentacao) {
    const dataAtual = new Date();

    const dataUltAlteracao = new Date(movimentacao?.ultimaAlteracao);
    const diferencaAtualUltAlteracao =
      dataAtual.getTime() - dataUltAlteracao.getTime();
    const diferencaEmHorasAtualUltAlteracao =
      diferencaAtualUltAlteracao / (1000 * 60 * 60);

    const dataRegistro = new Date(movimentacao?.cadastro);
    const diferencaAtualRegistro = dataAtual.getTime() - dataRegistro.getTime();
    const diferencaEmHorasAtualRegistro =
      diferencaAtualRegistro / (1000 * 60 * 60);

    let rowClassName;

    if (
      diferencaEmHorasAtualUltAlteracao < 24 ||
      diferencaEmHorasAtualRegistro < 24
    ) {
      rowClassName = "status__alteradoOuIncluido";
    }

    return rowClassName;
  }

  const modalExcluir = [
    { label: "Código:", value: excluirMovimentacao },
    { label: "Histórico:", value: descricaoModalExcluir },
  ];

  const descricaoStatusParcela = [
    {
      nome: "RATEIO",
      descricao: "Totalmente rateado",
      icon: "pi pi-dollar",
      className: "table__opcoes--green modalDescriptionIcon",
    },
    {
      nome: "RATEIO",
      descricao: "Parcialmente rateado",
      icon: "pi pi-dollar",
      className: "table__opcoes--rateio modalDescriptionIcon",
    },
    {
      nome: "RATEIO",
      descricao: "Não rateado",
      icon: "pi pi-dollar",
      className: "table__opcoes--black modalDescriptionIcon",
    },
    {
      nome: "CONTRATO",
      description: "C",
      descricao: "Movimentação com contrato vinculado",
      descriptionClass: "modalDescriptionContrato",
    },
    {
      nome: "CONTRATO",
      description: "C",
      descricao: "Movimentação sem contrato vinculado",
      descriptionClass: "modalDescriptionSemContrato",
    },
    {
      nome: "DATA",
      descricao: "Vencimento Original e Vencimento Planejado são diferentes",
      icon: "pi pi-calendar",
      className: "modalDescriptionDate modalDescriptionIcon",
    },
    {
      nome: "MOVIMENTAÇÃO",
      descricao: "Movimentação cadastrada ou alterada nas últimas 24h",
      cor: "#29BEE4",
    },
  ];

  useEffect(() => {
    if (naturezaId === undefined || naturezaId === null) {
      const reduxObj = montarFiltro();
      const reduxObj2 =  reduxStateInicial.filtroMov;

      const copy1 = { ...reduxObj };
      const copy2 = { ...reduxObj2 };
      const { creditoDebitoConciliado, rateado, tipoLancamento, baixado } = copy1;

      if (creditoDebitoConciliado === "TODOS") copy1.creditoDebitoConciliado = null;
      if (rateado === "TODOS") copy1.rateado = null;
      if (tipoLancamento === "TODOS") copy1.tipoLancamento = null;
      if (baixado === "TODOS") copy1.baixado = null;

      const areEqual = _.isEqual(copy1, copy2);
      setVisible(!areEqual);
      carregarMovimentacaoListaLancamento();
    }
  }, [lazyParams, montarFiltro, carregarMovimentacaoListaLancamento, reduxStateInicial, naturezaId]);
  

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <DetalhesListaPorLinha
          setExibirDetalhesLinha={setExibirDetalhesLinha}
          exibirDetalhesLinha={exibirDetalhesLinha}
          conteudoPorLinha={conteudoPorLinha}
        />
        <Table
          rowClassName={(rowData) => verificarDataMovimentacao(rowData)}
          arrayData={movimentacaoListaLancamento.movimentacaoListaLancamento}
          loading={recarregando}
          columns={columns}
          headerComponent={
            <HeaderComCalculos
              title={"Movimentações"}
              totalCredito={totalCredito}
              totalDebito={totalDebito}
              totalLiquido={totalLiquido}
              totalConciliado={totalConciliado}
              onClickHeaderInfo={() => setShowModalInfo(true)}
              headerInfoHoverMessage="Informações sobre as cores"
              onClickClear={() => clearFilter()}
              totalRecords={totalRecords}
              movimentacoes
              labelVoltar={"Retornar ao DFC"}
              iconVoltar={"no-icon"}
              movimentacoesParam={paramsDfc}
              onClickAdicionar={linkAdicionar}
              visible={visible}
              setVisibleRight={() => setFiltroVisivelLista(true)}
              onClickVoltar={() => redirecionar(tipo, ano, empresaId)}
              returnedPage={!paramsDfc}
              onRedirect={() => redirectMovimentacao()}
              dataInicialFiltro={ `01/${mes < 10 ? '0' + mes : mes}/${ano}`}
              dataFinalFiltro={`${new Date(new Date().getFullYear(), mes, 0).getDate()}/${mes < 10 ? '0' + mes : mes}/${ano}`}
              natureza={naturezaDfc}
            />
          }
          onClickContrato={
            movimentacaoListaLancamento.movimentacaoListaLancamento
          }
          visualizarContrato={(rowData) => selecionarIdContrato(rowData)}
          onClickDetails={(rowData) => selecionarDetalhesPorLinha(rowData)}
          tooltipEdit="Movimentação"
          onClickEdit={(rowData) => editarMovimentacao(rowData)}
          onClickRateioIdemVlrDesd={(rowData) => abrirLinhaComId(rowData)}
          onClickRateioDifVlrDesdIdemRat={(rowData) => abrirLinhaComId(rowData)}
          onClickRateioIdemZero={(rowData) => abrirLinhaComId(rowData)}
          onClickMovimentacao={(rowData) => handleNavigateToFiles(rowData)}
          onClickDelete={(rowData) => functionExcluirMovimentacao(rowData)}
          onClickHeaderInfo={() => setShowModalInfo(true)}
          headerInfoHoverMessage="Mostrar descrição de cores"
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
        />

        <ModalComponents
          title="Movimentação"
          visible={dialogExcluirMovimentacao}
          onHide={() => setDialogExcluirMovimentacao(false)}
          onClick="delete"
          descricao={modalExcluir}
          onClickCancelar={() => setDialogExcluirMovimentacao(false)}
          onClickConfirmar={() => deletarMovimentacao(excluirMovimentacao)}
        />

        <ModalDescricaoDeCores
          datatesteId={"modal__info"}
          visible={showModalInfo}
          setState={setShowModalInfo}
          arrayDescricao={descricaoStatusParcela}
          title="Mostrar descrição de cores"
        />

        <FiltroMovimentacaoPagarReceber
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          setFiltroVisivelLista={setFiltroVisivelLista}
          filtroVisivelLista={filtroVisivelLista}
          setLazyParamsProp={setLazyParams}
        />

        <RateioTelaEditarAdicLista
          setAbrirRateio={setAbrirRateio}
          abrirRateio={abrirRateio}
          movimentacao={movimentacao}
        />
      </LoadingOverlayComponents>
    </>
  );
};
