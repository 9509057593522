export const addNaturezaHierarquica = (naturezaHierarquica) => ({
    type: "ADD_NATUREZA_HIERARQUICA",
    payload: naturezaHierarquica,
});

export const setNaturezaLista = (natureza) => ({
    type: "SET_NATUREZA_LISTA",
    payload: natureza,
});

export const setNaturezaAnalitica = (naturezaAnalitica) => ({
  type: "SET_NATUREZA_ANALITICA",
  payload: naturezaAnalitica,
});


export const addListaNaturezas = (lista) => ({
    type: "ADD_LISTA_NATUREZAS",
    payload: lista,
});

export const esconderNatuSalva = (alertaSalvo) => ({
    type: "ESCONDER_NATU_SALVA",
    payload: alertaSalvo,
});

export const setDescricaoNatu = (descricao) => ({
    type: "SET_DESCRICAO",
    payload: descricao,
});

export const setTipoLancamentoNatu = (tipoLancamento) => ({
    type: "SET_TIPO_LANCAMENTO",
    payload: tipoLancamento,
});

export const setAtivaNatu = (ativa) => ({
    type: "SET_ATIVA",
    payload: ativa,
});

export const setFiltroNaturezaDrop = (natureza) => ({
    type: "SET_FILTRO_NATUREZA_DROP",
    payload: natureza,
});

export const setFiltroDescricaoNaturezaDrop = (natureza) => ({
    type: "SET_DESCRICAO_NATUREZA_DROP",
    payload: natureza,
});

export const setReloadNatureza = (boolean) => ({
    type: "SET_RELOAD",
    payload: boolean,
});
