import React, { useEffect, useState } from 'react';

export const DFC = () => {

  const [urlDfc, setUrlDfc] = useState()
  useEffect(() => {
    const urlDfcEnv = process.env.REACT_APP_DFC_FRONT_URL;
    setUrlDfc(urlDfcEnv)
  }, [])

  return (
    <div>
        <iframe 
            title='fina-dfc'
            style={{ width: '100vw', height: '100vh' }}
            src={urlDfc}
        />
    </div>
  )
}
