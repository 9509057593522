import { InputNumber } from "primereact/inputnumber";
import React from "react";
import { messageRequired } from "../../../default/messages";
import "./styles.css";

export const InputNumberComponent = ({
  label,
  style,
  required,
  disabled,
  setState,
  field,
  value,
  prefix,
  minFractionDigits,
  maxFractionDigits,
  maxLength,
  datatesteid,
  validacao,
  isEmpty,
}) => {
  return (
    <div style={style} key={label} className="input__container--formInputs">
      <div>
        <label>{label}</label>
        {required && (
          <abbr data-testid="abbr-asteristic" className="form__asterisk">
            *
          </abbr>
        )}
      </div>
      <InputNumber
        validacao
        className="input__field"
        onChange={(e) => {
          setState(e.value);
        }}
        field={field}
        value={value}
        prefix={prefix}
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        maxLength={maxLength}
        data-testid={datatesteid}
        disabled={disabled}
      />
      <small className="form__errorMsg" hidden={!isEmpty}>
        {messageRequired}
      </small>
    </div>
  );
};
