import { addLocale } from "primereact/api";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroContratoDrop, setFiltroEmailCobInad } from "../../actions";
import { brazilianCalendarParams } from "../../utils";
import { Filtro } from "../Filtro";

export const FiltroCobrancaInadimplente = ({
  abrirFiltro,
  clear,
  setAbrirFiltro,
  getListaContrato,
  setVisible,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();

  const [idContrato, setIdContrato] = useState(
    useSelector((state) => state.filtroEmailCob.idContrato)
  );
  const [nomeContrato, setNomeContrato] = useState(
    useSelector((state) => state.filtroEmailCob.contrato)
  );
  const [numeroContrato, setNumeroContrato] = useState(
    useSelector((state) => state.filtroEmailCob.numeroContrato)
  );
  const [numeroParcela, setNumeroParcela] = useState(
    useSelector((state) => state.filtroEmailCob.numeroParcela)
  );
  const [dataEnvioEmail, setDataEnvioEmail] = useState(
    useSelector((state) => state.filtroEmailCob.dataEnvioEmail)
  );
  const [automatizarCobranca, setAutomatizarCobranca] = useState(true);
  const [vencimentoPlanejado, setVencimentoPlanejado] = useState(
    useSelector((state) => state.filtroEmailCob.vencimentoPlanejado)
  );
  const [vencimentoOriginal, setVencimentoOriginal] = useState(
    useSelector((state) => state.filtroEmailCob.vencimentoOriginal)
  );

  const optionAutomatizarCobranca = [
    { name: "Ambos", value: "TODOS" },
    { name: "Sim", value: true },
    { name: "Não", value: false },
  ];

  const filterFields = [
    {
      name: "cobrancaAutomatizada",
      label: "Cobrança Automatizada:",
      value: automatizarCobranca,
      setState: setAutomatizarCobranca,
      type: "selectButton",
      options: optionAutomatizarCobranca,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      name: "idContrato",
      label: "Código do Contrato:",
      value: idContrato,
      setState: setIdContrato,
      dataType: "pint",
      datatesteid: "idContratoID",
    },
    {
      name: "contrato",
      label: "Descrição do Contrato:",
      value: nomeContrato,
      setState: setNomeContrato,
      type: "select",
      options: getListaContrato,
      optionLabel: "nomeContrato",
      optionValue: "id",
      editable: true,
      datatesteid: "contratoDropID",
      filter: setFiltroContratoDrop,
      attribute: "nomeContrato",
    },
    {
      name: "numeroContrato",
      label: "Número do Contrato:",
      value: numeroContrato,
      setState: setNumeroContrato,
      dataType: "pint",
    },
    {
      name: "numeroParcela",
      label: "Número da Parcela:",
      value: numeroParcela,
      setState: setNumeroParcela,
      dataType: "pint",
      datatesteid: "numeroParcelaId",
    },
    {
      label: "Vencimento até:",
      value: vencimentoPlanejado,
      setState: setVencimentoPlanejado,
      type: "date",
      datatesteid: "dataVencPrevistoID",
    },
    {
      label: "Prorrogação do Vencimento:",
      value: vencimentoOriginal,
      setState: setVencimentoOriginal,
      type: "date",
      datatesteid: "dataVencProrrogadoID",
    },
    {
      label: "Envio de E-mail de Cobrança:",
      value: dataEnvioEmail,
      setState: setDataEnvioEmail,
      type: "date",
      datatesteid: "dataEnvioID",
    },
  ];

  addLocale("pt-br", brazilianCalendarParams);

  const filtrar = () => {
    dispatch(
      setFiltroEmailCobInad({
        idContrato: idContrato !== "" ? parseInt(idContrato) : null,
        contrato: nomeContrato?.nomeContrato
          ? nomeContrato?.nomeContrato
          : null,
        numeroContrato: numeroContrato !== "" ? numeroContrato : null,
        numeroParcela: numeroParcela !== "" ? numeroParcela : null,
        dataEnvioEmail: dataEnvioEmail,
        automatizarCob: automatizarCobranca,
        vencimentoPlanejado: vencimentoPlanejado,
        vencimentoOriginal:
          vencimentoOriginal !== "" ? vencimentoOriginal : null,
      })
    );
    // setVisible(() => true)
  };

  const limpar = useCallback(() => {
    setIdContrato("");
    setNomeContrato("");
    setNumeroContrato("");
    setNumeroParcela("");
    setVencimentoOriginal(null);
    setVencimentoPlanejado('');
    setDataEnvioEmail('');
    setAutomatizarCobranca("TODOS");
    setVisible(() => false)
    setClearInputs(false)
  }, [setClearInputs, setVisible]);


  useEffect(() => {
    if (clear === true) {
      limpar()
    }
  }, [clear, limpar])
  
  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <Filtro
      filterFields={filterFields}
      setIsFiltroVisible={setAbrirFiltro}
      isFiltroVisible={abrirFiltro}
      clearFilter={limpar}
      handleConfirmFilter={filtrar}
    />
  );
};
