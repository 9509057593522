import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getListaEmailCobrancaInadimplente = (requestParams) =>
  axios
    .post(
      `${url}/emails/cobranca-inadimplente${handlePaginationUri(
        requestParams
      )}`,
      requestParams.params
    )
    .then((response) => response.data);

export const getEmailManualCobranca = (
  idFormatado,
  nomeContrato,
  emailCobranca,
  movimentacaoParcela,
  numeroContrato,
  formato
) =>
  axios
    .get(`${url}/emails/send-manual`, {
      params: {
        idMovimentacao: idFormatado,
        nomeContrato: nomeContrato,
        emailCobranca: emailCobranca,
        movimentacaoParcela: movimentacaoParcela,
        numeroContrato: numeroContrato,
        vencimento: formato,
      },
    })
    .then((response) => response.data);

export const getEmailAutomaticoCobranca = () =>
  axios.get(`${url}/emails/send-automatico`);

export const getListaEmailsEnviadosFiltro = (requestParams) =>
  axios
    .post(
      `${url}/emails/email-resumo${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const getByIdDetalhesEmailsEnviados = (id) =>
  axios
    .get(`${url}/emails/log-detalhe/${id}`)
    .then((response) => response.data);
