export const addListaMovimentacoes = (movimentacoes) => ({
    type: "ADD_LISTA_MOVIMENTACOES",
    payload: movimentacoes,
});

export const filtraMovimentacoesPorContas = (movimentacoes, idsContasHabilitadas) => ({
    type: "ADD_LISTA_MOVIMENTACOES",
    payload: movimentacoes.map((mov) => ({
        ...mov,
        escondida: !idsContasHabilitadas.includes(mov.idContaCorrente),
    })),
});

export const addMovimentacaoListaLancamento = (movimentacaoListaLancamento) => ({
    type: "ADD_MOVIMENTACAO_LISTA_LANCAMENTO",
    payload: movimentacaoListaLancamento,
});

export const addSalvarEditarListaPagarReceber = (salvarEditarLista) => ({
    type: "ADD_SALVAR_EDITAR_LISTA_PAGAR_RECEBER",
    payload: salvarEditarLista,
});

export const setFiltroMov = (filtroMov) => ({
    type: "SET_FILTRO-MOV",
    payload: filtroMov,
});

export const setIdMov = (idMov) => ({
    type: "SET_ID_MOV",
    payload: idMov,
});

export const setCompetenciaFim = (competenciaFim) => ({
    type: "SET_COMPETENCIA_FIM",
    payload: competenciaFim,
});

export const setCompetenciaInicio = (competenciaInicio) => ({
    type: "SET_COMPETENCIA_INICIO",
    payload: competenciaInicio,
});

export const setBaixado = (baixado) => ({
    type: "SET_BAIXADO",
    payload: baixado,
});

export const setNaturezaDescricao = (naturezaDescricao) => ({
  type: "SET_NATUREZA_DESCRICAO",
  payload: naturezaDescricao,
});

export const setCreditoDebitoConciliado = (creditoDebitoConciliado) => ({
    type: "SET_CREDITO_DEBITO_CONCILIADO",
    payload: creditoDebitoConciliado,
});

export const setFaturamentoPrevistoFim = (faturamentoPrevistoFim) => ({
    type: "SET_FATURAMENTO_PREVISTO_FIM",
    payload: faturamentoPrevistoFim,
});

export const setFaturamentoPrevistoInicio = (faturamentoPrevistoInicio) => ({
    type: "SET_FATURAMENTO_PREVISTO_INICIO",
    payload: faturamentoPrevistoInicio,
});

export const setEmissaoFim = (emissaoFim) => ({
    type: "SET_EMISSAO_FIM",
    payload: emissaoFim,
});

export const setEmissaoInicio = (emissaoInicio) => ({
    type: "SET_EMISSAO_INICIO",
    payload: emissaoInicio,
});

export const setBaixaFim = (baixaFim) => ({
    type: "SET_BAIXA_FIM",
    payload: baixaFim,
});

export const setBaixaInicio = (baixaInicio) => ({
    type: "SET_BAIXA_INICIO",
    payload: baixaInicio,
});

export const setVencimentoModificadoFim = (vencimentoModificadoFim) => ({
    type: "SET_VENCIMENTO_MODIFICADO_FIM",
    payload: vencimentoModificadoFim,
});

export const setVencimentoModificadoInicio = (vencimentoModificadoInicio) => ({
    type: "SET_VENCIMENTO_MODIFICADO_INICIO",
    payload: vencimentoModificadoInicio,
});

export const setVencimentoOriginalFim = (vencimentoOriginalFim) => ({
    type: "SET_VENCIMENTO_ORIGINAL_FIM",
    payload: vencimentoOriginalFim,
});

export const setVencimentoOriginalInicio = (vencimentoOriginalInicio) => ({
    type: "SET_VENCIMENTO_ORIGINAL_INICIO",
    payload: vencimentoOriginalInicio,
});
