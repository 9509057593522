const INITIAL_STATE = {
    salvarEditarLista: null,
    naturezaHierarquica: null,
    tipoLancamento: { name: "Receita", value: "RECEITA" },
};

export const salvarEditar = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'ADD_SALVAR_EDITAR_LISTA_PAGAR_RECEBER':
            return { ...state, salvarEditarLista: action.payload };
        case "SET_TIPO_LANCAMENTO":
            return { ...state, tipoLancamento: action.payload };

        default:
            return state
    }
}
