import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import GlobalToast from "../components/GlobalToast";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
          }
        />
        <GlobalToast />
    </>
  );
};

export default PrivateRoute;
