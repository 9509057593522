import { InputText } from 'primereact/inputtext';
import React from 'react';
import "./styles.css";

export const Input = ({
    label,
    value,
    setState,
    type,
    required,
    style,
    maxLength,
    dataType,
    disabled,
    validacao,
    errorMessage,
    datatesteid,
    setAlteracoes = () => { }
}) => {
    return (
        <div style={style} key={label} className="input__container--formInputs">
            <div>
                <label>{label}</label>
                {required &&
                    <abbr data-testid="abbr-asteristic" className="form__asterisk">*</abbr>
                }
            </div>
            <InputText
                validacao
                className='input__field'
                type={type}
                value={value}
                onChange={(e) => {
                    setState(e.target.value)
                    setAlteracoes(true)
                }}
                maxLength={maxLength}
                keyfilter={dataType}
                disabled={disabled}
                data-testid={datatesteid} />
            <small
                className="form__errorMsg"
            >
                {errorMessage}
            </small>

        </div>
    )
}
