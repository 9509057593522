export const addConciliacao = (salvarConciliacao) => ({
    type: "ADD_CONCILIACAO",
    payload: salvarConciliacao,
});

export const addListaConciliacao = (listaConciliacao) => ({
    type: "ADD_LISTA_CONCILIACAO",
    payload: listaConciliacao
});

export const setFiltroConci = (filtroConci) => ({
    type: "SET_FILTRO_CONCI",
    payload: filtroConci,
});

export const setConciliacaoInicio = (conciliacaoInicio) => ({
    type: "SET_CONCILIACAO_INICIO",
    payload: conciliacaoInicio,
});

export const setConciliacaoFim = (conciliacaoFim) => ({
    type: "SET_CONCILIACAO_FIM",
    payload: conciliacaoFim,
});

export const setDesconciliadoInicio = (desconciliadoInicio) => ({
    type: "SET_DESCONCILIADO_INICIO",
    payload: desconciliadoInicio,
});

export const setDesconciliadoFim = (desconciliadoFim) => ({
    type: "SET_DESCONCILIADO_FIM",
    payload: desconciliadoFim,
});

export const setTipoConciliacao = (tipoConciliacao) => ({
    type: "SET_TIPO_CONCILIACAO",
    payload: tipoConciliacao,
});

export const addListaCalculoConci = (listaCalculoConci) => ({
    type: "ADD_LISTA_CALCULO_CONCI",
    payload: listaCalculoConci,
});

export const setTipoLancamentoConc = (tipoLancamento) => ({
    type: "SET_TIPO_LANCAMENTO_CONC",
    payload: tipoLancamento,
});

export const setNumeroUnicoConc = (idMovimentacao) => ({
    type: "SET_NUMERO_UNICO",
    payload: idMovimentacao,
});
