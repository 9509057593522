const INITIAL_STATE = {
  listaContas: [],
  saldoInicial: null,
  proximoSaldoNegativo: { data: null, saldo: null },
};

export const contas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_LISTA_CONTAS":
      return { ...state, listaContas: action.payload };
    case "SET_SALDO_INICIAL":
      return { ...state, saldoInicial: action.payload };
    case "SET_PROXIMO_SALDO_NEGATIVO":
      return { ...state, proximoSaldoNegativo: action.payload };
    default:
      return state;
  }
};
