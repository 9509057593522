const INITIAL_STATE = {
  lista: []
};

export const naturezas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_LISTA_NATUREZAS':
      return { lista: action.payload, loading: false };
    case 'ESCONDER_NATU_SALVA':
      return { ...state, alertaSalvo: false };
    default:
      return state
  }
}
