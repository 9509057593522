export function formatCnpj(cnpj) {
    return cnpj?.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"
    );
}

export function formatCpf(cpf) {
    return cpf?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4",
    );
}