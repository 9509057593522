const INITIAL_STATE = {
  pagina: 0,
  qtdRegistros: 100
};

export const filtroAnexo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_ANEXO":
      return { ...action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_QTD_REGISTRO":
      return { ...state, qtdRegistros: action.payload };

    default:
      return state;
  }
};
