import React from "react";
import { EditarParcela } from "../../components/EditarParcela/";

export const ParcelasAdicionar = (props) => {

  return (
    <>
      <EditarParcela
        propsParcela={props?.location?.state?.parcela}
        idContratoParcela={props?.location?.state?.idContrato}
        page={props?.location?.state?.page}
      />
    </>
  );
};
