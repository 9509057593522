import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;
const urlGcp = process.env.REACT_APP_API_GCP;
const directory = process.env.REACT_APP_DIRECTORY_GOOGLE_CLOUD

export default axios.create({
  baseURL: `${url}`,
});

export const getMovimentacaoListaLancamento = (requestParams) =>
  axios
    .post(
      `${url}/movimentacoes/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const saveMovimentacao = (movimentacao) =>
  axios
    .post(`${url}/movimentacoes`, movimentacao)
    .then((response) => response.data);

export const updateMovimentacao = (id, contas) =>
  axios
    .put(`${url}/movimentacoes/update/${id}`, contas)
    .then((response) => response.data);

export const deleteMovimentacao = async (id) => {
  axios.delete(`${url}/movimentacoes/${id}`).then((response) => response.data);
}

export const deletarArquivosMovimentacao = async (id) =>
  axios.delete(`${urlGcp}/gcp/deleteObjectsWithPrefix?directoryPrefix=${directory}movimentacao/${id}/`)
    .then((response) => response.data);

export const salvarMovimentacao = (movimentacaoForm) =>
  axios
    .post(`${url}/salvar-movimentacao`, movimentacaoForm)
    .then((response) => response.status);

export const getByIdMovimentacao = (id) =>
  axios.get(`${url}/movimentacoes/${id}`).then((response) => response.data);

export const postSalvarConciliacao = (id, body) =>
  axios
    .post(`${url}/movimentacoes/conciliado?idUsuarioConciliado=${id}`, body)
    .then((response) => response.data.id);

export const postSalvarDesconciliacao = (id, body) =>
  axios
    .post(`${url}/movimentacoes/desconciliado?idUsuarioConciliado=${id}`, body)
    .then((response) => response.data.id);

export const getListaConciliacao = (requestParams) =>
  axios
    .post(
      `${url}/movimentacoes/filtro/conciliacao${handlePaginationUri(
        requestParams
      )}`,
      requestParams.params
    )
    .then((response) => response.data);

export const getListarAlertaMovimentacao = () =>
  axios
    .get(`${url}/listar-alerta-inconsistencia-movimentacao`)
    .then((response) => response.data);

export const getSaldoTotal = (contas, data) =>
  axios
    .get(`${url}/saldo-total?contas=${contas.join()}&data=${data}`)
    .then((response) => response.data);

export const getProximoSaldoNegativo = (contas, data) =>
  axios
    .get(`${url}/proximo-saldo-negativo?contas=${contas.join()}&data=${data}`)
    .then((response) => response.data);

export const getTotalizadores = () =>
  axios.get(`${url}/totalizadores`).then((response) => response.data);

export const verificaFinalDeSemanaFeriado = (data) =>
  axios.get(`${url}/movimentacoes/verificaFinalDeSemanaFeriado?data=${data}`).then((response) => response.data)
