import { yupResolver } from "@hookform/resolvers/yup";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  setFiltroEmpresaDrop,
  setFiltroNaturezaDrop,
  setFiltroParceiro,
  setFiltroParceiroDrop,
} from "../../actions";
import {
  getAllProdutoServico,
  getByIdContrato,
  getContaCorrenteByIdEmpresa,
  getEmpresaByNomeFantasia,
  getNaturezaByIdNaturezaHierarquica,
  getNaturezaByNomeNaturezaHierarquica,
  getParceiroByNomeTratamento,
  saveContrato,
  updateContrato,
} from "../../api";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired } from "../../default/messages";
import {
  autoFillDropdown,
  brazilianCalendarParams,
  dateFormattedForInput,
} from "../../utils";
import { AutoCompletarComponent } from "../AutoCompletar";
import { ButtonComponent } from "../ButtonComponent";
import { CurrencyInput } from "../FormInputs/CurrencyInput";
import { MultiSelectComponent } from "../FormInputs/MultiSelectComponent";
import { Header } from "../Header";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import "./styles.css";

export const EditarGestaoDeContrato = ({ idContrato, page }) => {
  const toast = useRef(null);
  let history = useHistory();
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  addLocale("pt-br", brazilianCalendarParams);
  const [editGestaoContrato, setEditGestaoContrato] = useState();
  const [abrirDialogSalvarContrato, setAbrirDialogSalvarContrato] =
    useState(false);
  const [parcelaAlteradaModal, setParcelaAlteradaModal] = useState(false);

  const [status, setStatus] = useState("");
  const [automatizarCobranca, setAutomatizarCobranca] = useState(null);
  const [dataEnvioEmailCobr, setDataEnvioEmailCobr] = useState();
  const [dataInicio, setDataInicio] = useState();
  const [dataFim, setDataFim] = useState();
  const [emailCobranca, setEmailCobranca] = useState();
  const [nomeContrato, setNomeContrato] = useState();
  const [numeroContrato, setNumeroContrato] = useState();
  const [numeroParcelas, setNumeroParcelas] = useState(null);
  const numeroParcelasInicial = editGestaoContrato?.numeroParcelas ?? null;
  const [prazoCobranca, setPrazoCobranca] = useState();
  const [tipoLancamento, setTipoLancamento] = useState();
  const [idParceiro, setIdParceiro] = useState();
  const [valorTotal, setValorTotal] = useState();
  const [valorCalculado, setValorCalculado] = useState(0);
  const [idEmpresa, setIdEmpresa] = useState();
  const [idContaCorrente, setIdContaCorrente] = useState();
  const [idNatureza, setIdNatureza] = useState();
  const [descricaoParcela, setDescricaoParcela] = useState();
  const [dataVencimentoParcela, setDataVencimentoParcela] = useState();
  const [associaMovimentacao, setAssociaMovimentacao] = useState(null);
  const [id, setId] = useState();
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const [historico, setHistorico] = useState();
  const [formDisable, setFormDisable] = useState(false);
  const [
    dataEmissaoPrevistaPrimeiraParcela,
    setDataEmissaoPrevistaPrimeiraParcela,
  ] = useState();

  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [listaParceiros, setListaParceiros] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaProdutosServicos, setListaProdutosServicos] = useState([]);

  const [listaContasCorrentes, setListaContasCorrentes] = useState([]);
  const [listaNatureza, setListaNatureza] = useState([]);
  const [provisao, setProvisao] = useState("N");
  const [tipoVencimento, setTipoVencimento] = useState("POSTERIOR");
  const [typesProducts, setTypesProducts] = useState([]);

  const getEditParams = useCallback(async () => {
    setCarregandoRequisicao(true);
    try {
      await getByIdContrato(idContrato).then((response) => {
        setEditGestaoContrato(response);
        getNaturezaWithId(response.natureza.id);
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`,
      });
    }
  }, [idContrato])

  useEffect(() => {
    idContrato && getEditParams();
  }, [idContrato, getEditParams]);

  const encontrarOpcaoPreSelecionada = useCallback(() => {
    const opcaoPreSelecionada = listaContasCorrentes?.find((conta) =>
      conta?.principal?.includes("S")
    );
    if (opcaoPreSelecionada) {
      setIdContaCorrente(opcaoPreSelecionada);
    }
  }, [listaContasCorrentes]);

  const getContaByEmpresa = useCallback(async () => {
    try {
      if (idEmpresa?.id) {
        const resultado = await getContaCorrenteByIdEmpresa(idEmpresa.id);
        setListaContasCorrentes(resultado, () => {
          encontrarOpcaoPreSelecionada();
          autoFillDropdown({
            list: resultado,
            setState: setIdContaCorrente,
            value: idContaCorrente,
          });
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`,
      });
    }
  }, [encontrarOpcaoPreSelecionada, idContaCorrente, idEmpresa])

  useEffect(() => {
    encontrarOpcaoPreSelecionada();
  }, [listaContasCorrentes, encontrarOpcaoPreSelecionada]);

  const empresaID = idEmpresa?.id;
  const lastEmpresa = useRef(empresaID);

  useEffect(() => {
    if (lastEmpresa.current !== empresaID) {
      getContaByEmpresa(); // Chamada aqui, após a definição de empresaID
      lastEmpresa.current = empresaID;
    }
  }, [empresaID, getContaByEmpresa]);
  

  const selectCreditoDebito = [
    { name: "Crédito", value: "C" },
    { name: "Débito", value: "D" },
  ];

  const selectAutomatizarCobranca = [
    { name: "Sim", value: true },
    { name: "Não", value: false },
  ];

  const selectAssociaMovimentacao = [
    { name: "Sim", value: true },
    { name: "Não", value: false },
  ];

  const selectStatus = [
    { name: "Aberto", value: "ABERTO" },
    { name: "Fechado", value: "FECHADO" },
  ];

  const selectProvisao = [
    { name: "Sim", value: "S" },
    { name: "Não", value: "N" },
  ];

  const selectDiaUtilComportamento = [
    { name: "Dia útil anterior", value: "ANTERIOR" },
    { name: "Dia útil posterior", value: "POSTERIOR" },
  ];

  const emailCobrancaSchema = yup.string().test({
    name: "emailCobranca",
    message: "Digite um ou mais e-mails válidos",
    test: function (value) {
      if (!this.parent.automatizarCobranca) return true;
      if (!value) return false;
      const emails = value.split(";");
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      return emails.every((email) => emailRegex.test(email.trim()));
    },
  });

  const validationPost = yup
    .object()
    .shape({
      numeroContrato: yup.string().required(messageRequired),
      nomeContrato: yup.string().required(messageRequired).trim(),
      descricaoParcela: yup.string().required(messageRequired).trim(),
      idParceiro: yup.string().required(messageRequired),
      idEmpresa: yup.string().required(messageRequired),
      idNatureza: yup.string().required(messageRequired),
      tipoLancamento: yup.string().required(messageRequired),
      automatizarCobranca: yup.boolean().required(messageRequired),
      emailCobranca: emailCobrancaSchema.nullable(),
      prazoCobranca: yup.string().when("automatizarCobranca", {
        is: true,
        then: (schema) => schema.required(messageRequired),
      }),
      provisao: yup.string().required(messageRequired),
      valorTotal: yup.string().required(messageRequired),
      associaMovimentacao: yup.boolean().required(messageRequired),
      numeroParcelas: yup.string().required(messageRequired),
      status: yup.string().required(messageRequired),
      dataVencimentoParcela: yup.string().required(messageRequired),
      dataInicio: yup.string().required(messageRequired),
      dataFim: yup.string().required(messageRequired),
      idContaCorrente: yup.string().required(messageRequired),
      tipoVencimento: yup.string().required(messageRequired),
      historico:
        associaMovimentacao === true && yup.string().required(messageRequired),
      dataEmissaoPrevistaPrimeiraParcela:
        associaMovimentacao === true && yup.string().required(messageRequired),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  useEffect(() => {
    if (editGestaoContrato !== undefined && editGestaoContrato !== null) {
      if (editGestaoContrato) {
        setId(editGestaoContrato.id);
        setStatus(editGestaoContrato.status);
        setAutomatizarCobranca(editGestaoContrato.automatizarCobranca);

        setIdContaCorrente(editGestaoContrato.contaCorrente);

        setDataEnvioEmailCobr(editGestaoContrato.dataEnvioEmailCobr);
        setDataInicio(
          editGestaoContrato.dataInicio
            ? new Date(
              editGestaoContrato.dataInicio.split("/").reverse().join("-") +
              "T00:00:00"
            )
            : null
        );
      }
      setDataFim(
        editGestaoContrato?.dataFim
          ? new Date(
            editGestaoContrato.dataFim.split("/").reverse().join("-") +
            "T00:00:00"
          )
          : null
      );
      setEmailCobranca(editGestaoContrato?.emailCobranca ?? null);
      setDataVencimentoParcela(
        editGestaoContrato?.parcelaVencimento[0]?.dataVencimentoPrevisto
          ? dateFormattedForInput(
            editGestaoContrato?.parcelaVencimento[0]?.dataVencimentoPrevisto
          )
          : null
      );
      setDataEmissaoPrevistaPrimeiraParcela(
        editGestaoContrato?.dataEmissaoPrevistaPrimeiraParcela
          ? new Date(
            editGestaoContrato?.dataEmissaoPrevistaPrimeiraParcela
              .split("/")
              .reverse()
              .join("-") + "T00:00:00"
          )
          : null
      );
      setIdNatureza(editGestaoContrato.netureza);
      setNomeContrato(editGestaoContrato.nomeContrato);
      setNumeroContrato(editGestaoContrato.numeroContrato);
      setNumeroParcelas(editGestaoContrato.numeroParcelas);
      setPrazoCobranca(editGestaoContrato.prazoCobranca);
      setTipoLancamento(editGestaoContrato.tipoLancamento);
      setIdParceiro(editGestaoContrato.parceiro?.nomeTratamento);
      setIdEmpresa(editGestaoContrato.empresa);
      setValorTotal(editGestaoContrato.valorTotal);
      setDescricaoParcela(editGestaoContrato.descricaoParcelas);
      setAssociaMovimentacao(editGestaoContrato.associaMovimentacao);
      setProvisao(editGestaoContrato?.provisao ?? "N");
      setHistorico(editGestaoContrato?.historico);
      setDataEmissaoPrevistaPrimeiraParcela(editGestaoContrato?.dataEmissaoPrevistaPrimeiraParcela);

      setValue(
        "dataVencimentoParcela",
        new Date(
          editGestaoContrato?.parcelaVencimento[0]?.dataVencimentoPrevisto
        )
      );
      setValue("numeroContrato", editGestaoContrato.numeroContrato);
      setValue("tipoVencimento", "notNull");
      setValue("nomeContrato", editGestaoContrato.nomeContrato);
      setValue("tipoLancamento", editGestaoContrato.tipoLancamento);
      setValue("automatizarCobranca", editGestaoContrato.automatizarCobranca);
      setValue("emailCobranca", editGestaoContrato.emailCobranca);
      setValue("prazoCobranca", editGestaoContrato.prazoCobranca);
      setValue("valorTotal", editGestaoContrato.valorTotal);
      setValue(
        "associaMovimentacao",
        editGestaoContrato ? false : associaMovimentacao
      );
      setValue("numeroParcelas", editGestaoContrato.numeroParcelas);
      setValue("status", editGestaoContrato.status);
      setValue("dataInicio", editGestaoContrato.dataInicio);
      setValue("dataFim", editGestaoContrato.dataFim);
      setValue("historico", editGestaoContrato.historico);
    }

    if (editGestaoContrato?.produtoContrato) {
      const nomesProdutos = editGestaoContrato.produtoContrato.map(item => item.id);
      setTypesProducts(nomesProdutos);
    }

  }, [editGestaoContrato, setValue, associaMovimentacao]);

  useEffect(() => {
    setValue("numeroContrato", numeroContrato);
    setValue("nomeContrato", nomeContrato);
    setValue("valorTotal", valorTotal);
    setValue("provisao", provisao);
    setValue("numeroParcelas", numeroParcelas);
    if (!idContrato) {
      setValue("tipoVencimento", tipoVencimento);
    }
    setValue("dataVencimentoParcela", dataVencimentoParcela);
  }, [
    numeroContrato,
    provisao,
    nomeContrato,
    valorTotal,
    numeroParcelas,
    tipoVencimento,
    setValue,
    dataVencimentoParcela,
    idContrato
  ]);

  useEffect(() => {
    if (status !== null) {
      if (status.toLowerCase() === "fechado") {
        setFormDisable(true);
      } else {
        setFormDisable(false);
      }
    }
  }, [status])

  useEffect(() => {
    if (valorTotal && numeroParcelas) {
      setValorCalculado((valorTotal / numeroParcelas).toFixed(2));
    }
  }, [valorTotal, numeroParcelas]);

  function handleSelectChange({ onChangeValue, setState }) {
    onChangeValue !== null && setState(onChangeValue);
  }
  const dateInitial = new Date(dataFim);
  const dateFinish = new Date(dataInicio);
  const datePortions = new Date(dataVencimentoParcela);

  const userId = localStorage.getItem("loggerUser");
  const user = JSON.parse(userId);

  const contrato = {
    userId: user?.userId,
    automatizarCobranca: automatizarCobranca,
    dataEnvioEmailCobranca: dataEnvioEmailCobr,
    dataFim: dateInitial ? dateInitial : null,
    dataInicio: dateFinish ? dateFinish : null,
    emailCobranca: emailCobranca,
    contaCorrenteId: idContaCorrente?.id
      ? idContaCorrente?.id
      : editGestaoContrato?.contaCorrente.id,
    empresaId: idEmpresa?.id ? idEmpresa?.id : null,
    naturezaId: idNatureza?.idNatureza
      ? idNatureza?.idNatureza
      : editGestaoContrato?.natureza.id,
    nomeContrato: nomeContrato,
    numeroContrato: numeroContrato,
    numeroParcelas: parseInt(numeroParcelas),
    parceiroId: idParceiro?.id
      ? idParceiro?.id
      : editGestaoContrato?.parceiro.id,
    prazoCobranca: prazoCobranca,
    status: status,
    provisao: provisao,
    tipoLancamento: tipoLancamento,
    valorTotal: valorTotal,
    descricaoParcela: descricaoParcela?.descricaoParcela
      ? descricaoParcela?.descricaoParcela
      : descricaoParcela,
    dataVencimentoParcela: datePortions ? datePortions : null,
    associaMovimentacao: associaMovimentacao,
    tipoVencimento: tipoVencimento,
    dataEmissaoPrevistaPrimeiraParcela: dataEmissaoPrevistaPrimeiraParcela,
    historico: historico,
    produtoIds: typesProducts
  };

  const contratoUpdate = {
    userId: user?.userId,
    nomeContrato: nomeContrato,
    numeroContrato: numeroContrato,
    tipoLancamento: tipoLancamento,
    automatizarCobranca: automatizarCobranca,
    emailCobranca: emailCobranca,
    prazoCobranca: prazoCobranca,
    dataEnvioEmailCobranca: dataEnvioEmailCobr,
    dataInicio: dataInicio,
    dataFim: dataFim,
    valorTotal: valorTotal,
    numeroParcelas: numeroParcelas,
    status: status,
    parceiro: {
      id: idParceiro?.id ? idParceiro?.id : editGestaoContrato?.parceiro.id,
    },
    empresa: {
      id: idEmpresa?.id ? idEmpresa?.id : editGestaoContrato?.empresa.id,
    },
    contaCorrente: {
      id: idContaCorrente?.id
        ? idContaCorrente?.id
        : editGestaoContrato?.contaCorrente.id,
    },
    natureza: {
      id: idNatureza?.idNatureza
        ? idNatureza?.idNatureza
        : editGestaoContrato?.natureza.id,
    },
    dataEmissaoPrevistaPrimeiraParcela: dataEmissaoPrevistaPrimeiraParcela,
    produtoIds: typesProducts
  };

  const closeModal = () => {
    validationPost.isValid(contrato).then((response) => {
      if (!response) {
        setAbrirDialogSalvarContrato(false);
      }
    });
  };

  async function salvarEditarContrato(e) {
    try {
      setCarregandoRequisicao(true);
      if (!editGestaoContrato) {
        await saveContrato(contrato).then((resultado) => {
          toast.current.show({
            severity: "success",
            summary: "Sucesso!",
            detail: "Contrato salvo com sucesso!",
          });
          dispatch(setFiltroParceiro(reduxStateInicial.filtroContrato));
          setTimeout(() => {
            history.push("/contratos");
          }, 1500);
        });
      } else {
        contrato.id = editGestaoContrato.id;
        await updateContrato(id, contratoUpdate).then(() => {
          if (numeroParcelasInicial !== numeroParcelas) {
            setTimeout(() => {
              history.push(
                `/contrato/${editGestaoContrato.id}/parcelas`,
                editGestaoContrato.id
              );
            }, 1500);
          } else {
            dispatch(setFiltroParceiro(reduxStateInicial.filtroContrato));
            setTimeout(() => {
              history.push("/contratos");
            }, 1500);
          }
        });
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Contrato alterado com sucesso!",
        });
      }

      setAbrirDialogSalvarContrato(false);
    } catch (e) {
      const mensagem = e?.response?.data?.userMessage;
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${mensagem}`,
        life: 8000,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  useEffect(() => {
    if (idParceiro?.nomeTratamento !== undefined) {
      setValue("idParceiro", idParceiro?.nomeTratamento);
    } else if (idParceiro === editGestaoContrato?.parceiro.nomeTratamento) {
      setValue("idParceiro", idParceiro);
    } else if (idParceiro !== editGestaoContrato?.parceiro.nomeTratamento) {
      setValue("idParceiro", undefined);
    }
  }, [idParceiro, setValue, editGestaoContrato]);

  useEffect(() => {
    if (idEmpresa?.nomeFantasia !== undefined) {
      setValue("idEmpresa", idEmpresa?.nomeFantasia);
    } else if (idEmpresa === editGestaoContrato?.empresa.nomeFantasia) {
      setValue("idEmpresa", idEmpresa);
    } else if (idEmpresa !== editGestaoContrato?.empresa.nomeFantasia) {
      setValue("idEmpresa", undefined);
    }
  }, [idEmpresa, setValue, editGestaoContrato]);

  useEffect(() => {
    setValue("idContaCorrente", idContaCorrente?.id);
    setValue("idEmpresa", idEmpresa?.id);
    setValue("descricaoParcela", editGestaoContrato ? 1 : descricaoParcela);
    setValue("idNatureza", idNatureza?.idNatureza);
  }, [idContaCorrente, idEmpresa, descricaoParcela, idNatureza, editGestaoContrato, setValue]);

  function getNaturezaWithId(id) {
    try {
      setCarregandoRequisicao(true);
      getNaturezaByIdNaturezaHierarquica(id).then((naturezaHierarquica) => {
        setIdNatureza(naturezaHierarquica[0]);
        setCarregandoRequisicao(false);
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`,
      });
    } finally {
      setTimeout(() => {
        setCarregandoRequisicao(false);
      }, 1500);
    }
  }

  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param !== null && endpoint !== undefined) {
      await endpoint(param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não encontrado. Tente novamente.`,
            });
          } else {
            setState(resultado);
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Erro",
            life: 5000,
            detail: `${error.response?.data?.userMessage}`,
          });
        });
    }
  }

  useEffect(() => {
    let timeoutId = null;

    const handleParcelaChange = () => {
      if (numeroParcelasInicial !== null) {
        if (numeroParcelasInicial !== numeroParcelas) {
          setParcelaAlteradaModal(true);
        } else {
          setParcelaAlteradaModal(false);
        }
      }
    };

    const delayedCheck = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleParcelaChange, 700);
    };

    if (numeroParcelasInicial !== null) {
      if (numeroParcelasInicial !== parseInt(numeroParcelas)) {
        delayedCheck();
      } else {
        setParcelaAlteradaModal(false);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [numeroParcelas, numeroParcelasInicial]);

  function handleCancelarParcelaAlteradaModal() {
    setNumeroParcelas(numeroParcelasInicial);
    setParcelaAlteradaModal(false);
  }

  function titleModal() {
    let parametro = 0;
    let tamanho = editGestaoContrato?.parcelaVencimento.length;

    for (let a = 0; a < tamanho; a++) {
      if (
        editGestaoContrato?.parcelaVencimento[a]?.statusParcela ===
        "CONCILIADO" ||
        editGestaoContrato?.parcelaVencimento[a]?.statusParcela ===
        "PROVISIONADO"
      ) {
        parametro++;
      }
    }
    if (parametro !== 0) {
      return "Contrato e de Movimentação Atrelada";
    } else {
      return "Contrato";
    }
  }

  function getParceirosList(buscarPor) {
    callFilterEndpoints(
      setListaParceiros,
      getParceiroByNomeTratamento,
      buscarPor,
      "Parceiro"
    );
  }

  function getEmpresasList(buscarPor) {
    callFilterEndpoints(
      setListaEmpresas,
      getEmpresaByNomeFantasia,
      buscarPor,
      "Empresa"
    );
  }

  function getNaturezasList(buscarPor) {
    callFilterEndpoints(
      setListaNatureza,
      getNaturezaByNomeNaturezaHierarquica,
      buscarPor,
      "Natureza"
    );
  }

  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true);
    } else {
      history.push("/contratos", { page: page });
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  const inputNatureza = (
    <div className="contratoForm__field">
      <label htmlFor="banco">
        Natureza do Contrato
        <abbr className="form__asterisk">*</abbr>
      </label>
      <AutoCompletarComponent
        value={idNatureza}
        setValue={setIdNatureza}
        genericFunction={() => setAlteracoes(true)}
        options={listaNatureza}
        filter={setFiltroNaturezaDrop}
        attribute="nomeNaturezaHierarquica"
        optionLabel="naturezaHierarquica"
        completeMethod={getNaturezasList}
        datatesteid="naturezaID"
        disabled={formDisable}
      />
      <small className="form__errorMsg">{errors.idNatureza?.message}</small>
    </div>
  );

  async function getAllProdutos() {
    setCarregandoRequisicao(true);
    await getAllProdutoServico().then((resultado) => {
      setListaProdutosServicos(resultado);
      setCarregandoRequisicao(false);
    });
  }

  useEffect(() => {
    getAllProdutos();
  }, []);

  useEffect(() => {
    if (!associaMovimentacao) {
      setHistorico('');
    }
  }, [associaMovimentacao]);

  return (
    <>
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <div className="div-dialog-rateio">
          <ModalComponents
            title={titleModal()}
            visible={abrirDialogSalvarContrato}
            onHide={() => setAbrirDialogSalvarContrato(false)}
            onClick="confirm"
            onClickCancelar={() => setAbrirDialogSalvarContrato(false)}
            editOrRegister={editGestaoContrato}
            handleSubmit={handleSubmit}
            onClickConfirmarForm={(e) => salvarEditarContrato(e)}
            validationForm={closeModal}
            datatesteidconfirm="buttonConfirmModal"
          />
        </div>
        <div className="div-dialog-rateio">
          <ModalComponents
            title="Atenção, o número de parcelas foi alterado"
            visible={parcelaAlteradaModal}
            onHide={handleCancelarParcelaAlteradaModal}
            onClick="custom"
            descricao={[
              {
                label:
                  "As parcelas deverão ser alteradas de forma manual na tela de parcelas",
              },
            ]}
            onClickCancelar={handleCancelarParcelaAlteradaModal}
            onClickConfirmar={() => setParcelaAlteradaModal(false)}
            datatesteidconfirm="buttonParcelasAlteradasModal"
          />
        </div>
        <div className="contrato__wrapper">
          <Header
            title={"Contrato"}
            onClick={editGestaoContrato ? "edit" : "add"}
          />
          <div className="contrato__container">
            <form
              className="contratoForm"
              onSubmit={() => salvarEditarContrato}
            >
              <div className="contratoForm__section">
                <div className="contratoForm__field">
                  <MultiSelectComponent
                    value={typesProducts}
                    setState={(newValue) => {
                      setTypesProducts(newValue);
                      setAlteracoes(true);
                    }}
                    options={listaProdutosServicos}
                    optionLabel="nome"
                    optionValue="id"
                    noClass
                    filter
                    filterBy
                    disabled={formDisable}
                    label="Produtos e Serviços"
                  // style={{ width: '10%' }}
                  />
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Número do Contrato <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputText
                    {...register("numeroContrato")}
                    field="banco"
                    value={numeroContrato}
                    onChange={(e) => {
                      setAlteracoes(true);
                      setNumeroContrato(e.target.value);
                    }}
                    maxlength="100"
                    keyfilter="pint"
                    data-testid="numeroContratoTest"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.numeroContrato?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Nome do Contrato <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputText
                    {...register("nomeContrato")}
                    field="banco"
                    value={nomeContrato}
                    onChange={(e) => {
                      setAlteracoes(true);
                      setNomeContrato(e.target.value);
                    }}
                    maxlength="300"
                    data-testid="nomeContratoTest"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.nomeContrato?.message}
                  </small>
                </div>

                {!editGestaoContrato && (
                  <div className="contratoForm__field">
                    <label htmlFor="banco">
                      Descrição para Parcela{" "}
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <InputText
                      field="descricao"
                      value={descricaoParcela}
                      onChange={(e) => {
                        setAlteracoes(true);
                        setDescricaoParcela(e.target.value);
                      }}
                      data-testid="parcelaID"
                    />
                    <small className="form__errorMsg">
                      {errors.descricaoParcela?.message}
                    </small>
                  </div>
                )}
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Parceiro <abbr className="form__asterisk">*</abbr>
                  </label>
                  <AutoCompletarComponent
                    value={idParceiro}
                    setValue={setIdParceiro}
                    genericFunction={() => setAlteracoes(true)}
                    options={listaParceiros}
                    filter={setFiltroParceiroDrop}
                    attribute="nomeTratamento"
                    optionLabel="nomeTratamento"
                    completeMethod={getParceirosList}
                    datatesteid="parceiroID"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.idParceiro?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Data de Início do Contrato
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <Calendar
                    {...register("dataInicio")}
                    mask="99/99/9999"
                    value={dataInicio}
                    name="dataInicio"
                    dateFormat={"dd/mm/yy"}
                    showIcon
                    onChange={(e) => {
                      setDataInicio(e.value);
                      setValue("dataInicio", e.value);
                      setAlteracoes(true);
                    }}
                    field="dataInicio"
                    locale="pt-br"
                    data-testid="dataInicioTest"
                    disabled={idContrato || formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.dataInicio?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="empresa">
                    Data de Encerramento do Contrato{" "}
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <Calendar
                    {...register("dataFim")}
                    mask="99/99/9999"
                    value={dataFim}
                    name="dataFim"
                    dateFormat={"dd/mm/yy"}
                    showIcon
                    onChange={(e) => {
                      setDataFim(e.value);
                      setValue("dataFim", e.value);
                      setAlteracoes(true);
                    }}
                    field="dataFim"
                    width={50}
                    locale="pt-br"
                    data-testid="dataFimTest"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.dataFim?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Status do Contrato<abbr className="form__asterisk">*</abbr>
                  </label>
                  <SelectButton
                    {...register("status")}
                    className="filter-select"
                    options={selectStatus}
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setValue("status", e.value);
                      setAlteracoes(true);
                    }}
                    optionLabel="name"
                    optionValue="value"
                    data-testid="statusSelectButton"
                  />
                  <small className="form__errorMsg">
                    {errors.status?.message}
                  </small>
                </div>
              </div>

              <div className="contratoForm__section">
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Empresa <abbr className="form__asterisk">*</abbr>
                  </label>
                  <AutoCompletarComponent
                    value={idEmpresa}
                    setValue={setIdEmpresa}
                    options={listaEmpresas}
                    genericFunction={() => setAlteracoes(true)}
                    filter={setFiltroEmpresaDrop}
                    attribute="nomeFantasia"
                    optionLabel="nomeFantasia"
                    completeMethod={getEmpresasList}
                    datatesteid="empresaID"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.idEmpresa?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Conta Corrente <abbr className="form__asterisk">*</abbr>
                  </label>
                  <Dropdown
                    {...register("formaPagamento")}
                    className="registrarEditarLista__inputs"
                    optionLabel="descricao"
                    value={idContaCorrente}
                    options={listaContasCorrentes}
                    disabled={!idEmpresa?.id || formDisable}
                    onChange={(e) => {
                      setIdContaCorrente(e.value);
                      setAlteracoes(true);
                    }}
                    field="descricao"
                    filter
                    showClear
                    filterBy="descricao"
                    panelStyle={{ width: "0px" }}
                    data-testid="contaID"
                    defaultValue={idContaCorrente}
                  />
                  <small className="form__errorMsg">
                    {errors.idContaCorrente?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Automatizar Cobrança{" "}
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <SelectButton
                    {...register("automatizarCobranca")}
                    className="filter-select"
                    options={selectAutomatizarCobranca}
                    value={automatizarCobranca}
                    onChange={(e) => {
                      setAutomatizarCobranca(e.target.value);
                      setValue("automatizarCobranca", e.value);
                      setAlteracoes(true);
                    }}
                    optionLabel="name"
                    optionValue="value"
                    data-testid="automatizarCobrancaSelectButton"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.automatizarCobranca?.message}
                  </small>
                </div>
                <div
                  className="contratoForm__field"
                  title="Digite um ou mais e-mails válidos separados por ' ; ' "
                >
                  <div className="contratoForm__field--hint">
                    <label htmlFor="banco">
                      E-mail para Cobrança{" "}
                      {automatizarCobranca && (
                        <abbr className="form__asterisk">*</abbr>
                      )}
                    </label>
                    <InfoIcon tooltipText="Para cadastrar mais de um e-mail, usar ';' para separá-los." />
                  </div>
                  <InputText
                    {...register("emailCobranca")}
                    field="banco"
                    value={emailCobranca}
                    onChange={(e) => {
                      setEmailCobranca(e.target.value);
                      setAlteracoes(true);
                    }}
                    maxlength="500"
                    data-testid="emailCobrancaTest"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.emailCobranca?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Prazo para Cobrança (dias){" "}
                    <abbr className="form__asterisk">
                      {automatizarCobranca ? "*" : ""}
                    </abbr>
                  </label>

                  <InputText
                    {...register("prazoCobranca")}
                    field="banco"
                    type="number"
                    value={prazoCobranca}
                    onChange={(e) => {
                      setPrazoCobranca(e.target.value);
                      setAlteracoes(true);
                    }}
                    maxLength={4}
                    data-testid="prazoCobrancaTest"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.prazoCobranca?.message}
                  </small>
                </div>
                {editGestaoContrato?.dataEnvioEmailCobr && (
                  <div className="contratoForm__field">
                    <label htmlFor="banco">
                      Data Envio Email Cobrança
                      <abbr className="form__asterisk"></abbr>
                    </label>
                    <InputText
                      value={dataEnvioEmailCobr}
                      name="dataEnvioEmailCobr"
                      dateFormat={"dd/mm/yy"}
                      onChange={(e) => {
                        setDataEnvioEmailCobr(e.value);
                        setAlteracoes(true);
                      }}
                      field="dataEnvioEmailCobr"
                      disabled={true}
                    />
                  </div>
                )}
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Tipo de Lançamento <abbr className="form__asterisk">*</abbr>
                  </label>
                  <SelectButton
                    {...register("tipoLancamento")}
                    className="filter-select"
                    options={selectCreditoDebito}
                    value={tipoLancamento}
                    onChange={(e) => {
                      setTipoLancamento(e.target.value);
                      setValue("tipoLancamento", e.value);
                      setAlteracoes(true);
                    }}
                    optionLabel="name"
                    optionValue="value"
                    data-testid="tipoLancamentoSelectButton"
                    disabled={formDisable}
                  />
                  <small className="form__errorMsg">
                    {errors.tipoLancamento?.message}
                  </small>
                </div>
                {!editGestaoContrato && inputNatureza}
              </div>
              <div className="contratoForm__section">
                {editGestaoContrato && inputNatureza}
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Valor Total do Contrato
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <CurrencyInput
                    value={valorTotal}
                    setValue={setValorTotal}
                    genericFunction={() => setAlteracoes(true)}
                    disabled={idContrato}
                  />
                  <small className="form__errorMsg">
                    {errors.valorTotal?.message}
                  </small>
                </div>
                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Número de Parcelas <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputText
                    {...register("numeroParcelas")}
                    value={numeroParcelas}
                    onChange={(e) => {
                      setNumeroParcelas(e.target.value);
                      setAlteracoes(true);
                    }}
                    maxlength="4"
                    disabled={editGestaoContrato}
                    data-testid="numeroParcelasTest"
                    keyfilter="pint"
                  />
                  <small className="form__errorMsg">
                    {errors.numeroParcelas?.message}
                  </small>
                </div>
                {!editGestaoContrato ? (
                  <div className="contratoForm__field">
                    <label htmlFor="banco">Valor de cada parcela</label>
                    <CurrencyInput value={valorCalculado} disabled={true} />
                    <small className="form__errorMsg">
                      {errors.valorCalculadoTest?.message}
                    </small>
                  </div>
                ) : null}

                <div className="contratoForm__field">
                  <label htmlFor="banco">
                    Data Vencimento da Primeira Parcela{" "}
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <Calendar
                    {...register("dataVencimentoParcela")}
                    mask="99/99/9999"
                    value={dataVencimentoParcela}
                    onChange={(e) => {
                      setDataVencimentoParcela(e.value);
                      setValue("dataVencimentoParcela", e.value);
                      setAlteracoes(true);
                    }}
                    maxlength="50"
                    name="dataVencimentoParcela"
                    dateFormat={"dd/mm/yy"}
                    locale="pt-br"
                    showIcon
                    field="dataVencimentoParcela"
                    disabled={editGestaoContrato === undefined ? false : true}
                    data-testid="dataVencimentoParcelaTest"
                  />
                  <small className="form__errorMsg">
                    {errors.dataVencimentoParcela?.message}
                  </small>
                </div>
                {!editGestaoContrato && (
                  <div className="contratoForm__field">
                    <label htmlFor="banco">
                      Quando o vencimento coincidir com dia não útil, lançar no:{" "}
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <SelectButton
                      {...register("tipoVencimento")}
                      className="filter-select"
                      options={selectDiaUtilComportamento}
                      value={tipoVencimento}
                      onChange={(e) => setTipoVencimento(e.target.value)}
                      optionLabel="name"
                      optionValue="value"
                      // disabled={numeroParcelas === null}
                      data-testid="selectDiaUtilTest"
                    />
                    <small className="form__errorMsg">
                      {errors.tipoVencimento?.message}
                    </small>
                  </div>
                )}
                {!idContrato && (
                  <div className="contratoForm__field">
                    <div className="contratoForm__field--hint">
                      <label htmlFor="banco">
                        Data de Emissão Prevista da Primeira Parcela
                        {associaMovimentacao === true && (
                          <abbr className="form__asterisk">*</abbr>
                        )}
                      </label>
                      {editGestaoContrato && (
                        <InfoIcon
                          left
                          tooltipText="Essa informação deve ser alterada através da parcela"
                        />
                      )}
                    </div>
                    <Calendar
                      {...register("dataEmissaoPrevistaPrimeiraParcela")}
                      mask="99/99/9999"
                      value={dataEmissaoPrevistaPrimeiraParcela}
                      onChange={(e) => {
                        setDataEmissaoPrevistaPrimeiraParcela(e.value);
                        setValue("dataEmissaoPrevistaPrimeiraParcela", e.value);
                        setAlteracoes(true);
                      }}
                      maxlength="50"
                      name="dataVencimentoParcela"
                      dateFormat={"dd/mm/yy"}
                      locale="pt-br"
                      showIcon
                      field="dataVencimentoParcela"
                      disabled={editGestaoContrato === undefined ? false : true}
                      data-testid="dataVencimentoParcelaTest"
                    />
                    <small className="form__errorMsg">
                      {errors.dataEmissaoPrevistaPrimeiraParcela?.message}
                    </small>
                  </div>
                )}

                {!editGestaoContrato ? (
                  <>
                    <div className="contratoForm__field">
                      <label htmlFor="banco">
                        Associa Movimentação
                        <abbr className="form__asterisk">*</abbr>
                      </label>
                      <SelectButton
                        {...register("associaMovimentacao")}
                        className="filter-select"
                        options={selectAssociaMovimentacao}
                        value={associaMovimentacao}
                        onChange={(e) => {
                          setAssociaMovimentacao(e.target.value);
                          setValue("associaMovimentacao", e.value);
                          setAlteracoes(true);
                        }}
                        optionLabel="name"
                        optionValue="value"
                        data-testid="associaMovimentacaoSelectButton"
                      />
                      <small className="form__errorMsg">
                        {errors.associaMovimentacao?.message}
                      </small>
                    </div>
                    <div className="contratoForm__field">
                      <label>
                        Provisão
                        {!editGestaoContrato && (
                          <abbr className="form__asterisk">*</abbr>
                        )}
                      </label>
                      <SelectButton
                        className="filter-select"
                        options={selectProvisao}
                        value={provisao}
                        onChange={(e) => {
                          handleSelectChange({
                            onChangeValue: e.value,
                            setState: setProvisao,
                          });
                          setAlteracoes(true);
                        }}
                        disabled={editGestaoContrato || !associaMovimentacao}
                        optionLabel="name"
                        optionValue="value"
                        data-testid="selectProvisao"
                      />
                    </div>
                  </>
                ) : null}
                {!idContrato && (
                  <div className="contratoForm__field">
                    <div className="contratoForm__field--hint">
                      <label>
                        Histórico{" "}
                        {associaMovimentacao === true && (
                          <abbr className="form__asterisk">*</abbr>
                        )}
                      </label>
                      {editGestaoContrato && (
                        <InfoIcon
                          left
                          tooltipText="Essa informação deve ser alterada a partir da Movimentação"
                        />
                      )}
                    </div>
                    <InputTextarea
                      {...register("historico")}
                      rows={4}
                      cols={102}
                      value={historico}
                      onChange={(e) => {
                        setHistorico(e.target.value);
                        setAlteracoes(true);
                      }}
                      field="historico"
                      id="historico"
                      maxLength="2000"
                      disabled={associaMovimentacao !== true}
                    />
                    <small className="form__errorMsg">
                      {errors.historico?.message}
                    </small>
                  </div>
                )}
              </div>
            </form>

            <ModalComponents
              visible={alteracoesFeitas}
              onHide={() => setAlteracoesFeitas(false)}
              onClickCancelar={() => setAlteracoesFeitas(false)}
              onClickConfirmar={() => {
                history.push("/contratos", { page: page });
              }}
              onClick="warning"
              descricao={modalWarning}
            />

            <div className="contrato__containerButtons">
              <ButtonComponent type="cancel" onClick={() => validateGoBack()} />

              <ButtonComponent
                datatesteid="buttonConfirmScreen"
                type="confirm"
                onClick={() => setAbrirDialogSalvarContrato(true)}
              />
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};
