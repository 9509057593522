import React from "react";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";

LoadingOverlay.propTypes = undefined
export const LoadingOverlayComponents = ({ children, active }) => {
    return (
        <>
            <LoadingOverlay
                active={active}
                spinner
                text='Carregando...'
            >
                {children}
            </LoadingOverlay>
        </>
    )
}
