export async function encodePassword(rawPassword) {
  const FORCE_SECURITY = "#*&Br!_/";
  const ALGORITHM = "SHA-256";
  const CHARSET = "UTF-8";

  try {
    if (rawPassword && rawPassword !== "") {
      const passwordToHash = rawPassword.trim() + FORCE_SECURITY;

      const textEncoder = new TextEncoder(CHARSET);

      const passwordData = textEncoder.encode(passwordToHash);

      const passwordBuffer = await crypto.subtle.digest(
        ALGORITHM,
        passwordData
      );

      const passwordEncrypted = Array.from(new Uint8Array(passwordBuffer))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

      return passwordEncrypted.toUpperCase();
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
