export * from "./Anexo";
export * from "./CentroDeCusto";
export * from "./CobrancaInadimplente";
export * from "./Conciliacao";
export * from "./ContaCorrente";
export * from "./EmailsEnviados";
export * from "./Empresa";
export * from "./FluxoCaixa";
export * from "./GestaoDeContrato";
export * from "./GrupoEconomico";
export * from "./Loading";
export * from "./Movimentacao";
export * from "./Natureza";
export * from "./ParametroSistema";
export * from "./Parceiro";
export * from "./Parcela";
export * from "./ProdutoServico";
export * from "./Projeto";
export * from "./Rateio";
export * from "./Usuario";

export const setHistorico = (historico) => ({
  type: "SET_HISTORICO",
  payload: historico,
});

export const setCadastroInicio = (cadastroInicio) => ({
  type: "SET_CADASTRO_INICIO",
  payload: cadastroInicio,
});

export const setCadastroFim = (cadastroFim) => ({
  type: "SET_CADASTRO_FIM",
  payload: cadastroFim,
});

export const setUltimaAlteracaoInicio = (ultimaAlteracaoInicio) => ({
  type: "SET_ULTIMA_ALTERACAO_INICIO",
  payload: ultimaAlteracaoInicio,
});

export const setUltimaAlteracaoFim = (ultimaAlteracaoFim) => ({
  type: "SET_ULTIMA_ALTERACAO_FIM",
  payload: ultimaAlteracaoFim,
});

export const setDescricao = (descricao) => ({
  type: "SET_DESCRICAO",
  payload: descricao,
});

export const setPage = (page) => ({
  type: "SET_PAGE",
  payload: page,
});
