export const messageRequired = "Campo obrigatório !"

export function messageRequiredMinimumCharacters(type, min){
    return `${type} deve possuir mais de ${min} caracteres !`
}

export function messageRequiredMaximumCharacters(type, min){
    return `${type} deve possuir menos de ${min} caracteres !`
}

export const messageValidEmail = "Digite um e-mail válido !"

