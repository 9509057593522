
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import "./styles.css";

export const InputTextAreaForm = ({
    label,
    value,
    setState,
    type,
    isEmpty,
    required,
    style,
    maxLength,
    rows,
    validacao,
    field,
    errorMessage,
    datatesteid,
    setAlteracoes = () => { }
}) => {
    return (
        <div style={style} key={label} className="inputTextArea__form">
            <div>
                <label>{label}</label>
                {required &&
                    <abbr className='form__asterisk'>*</abbr>}
            </div>
            <InputTextarea validacao className="inputTextArea__field" rows={rows} type={type} value={value} onChange={(e) => {
                setState(e.target.value)
                setAlteracoes(true)
            }}
                maxLength={maxLength}
                data-testid={datatesteid}
                field={field}
            />
            <small
                className='form__errorMsg'
            >
                {errorMessage}
            </small>

        </div>
    )
}
