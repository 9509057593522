import _ from 'lodash';
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addListaContrato,
  esconderLoading,
  exibirLoading,
  setFiltroContrato,
} from "../../actions";
import {
  deleteContrato,
  getAllFilterContrato,
  deletarArquivosMovimentacao,
  deletarArquivosParcela,
  getByIdContrato
} from "../../api";
import { FiltroGestaoDeContrato } from "../../components/FiltroGestaoDeContrato";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ModalDescricaoDeCores } from "../../components/ModalDescricaoDeCores";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { formatTypeConciliacao } from '../../default/types';
import { convertToISO8601, formatISO8601ToUser } from "../../utils";
import { formatValueDecimal } from "../../utils/value";
import "./styles.css";
import { Navbar } from '../../components/Navbar';

export const GestaoDeContrato = (props) => {
  console.log('propsprops', props)
  const page = props?.location?.state?.page
  const pageMov = props?.location?.state?.pageMov
  const idProps = props?.location?.state?.idContrato;
  const [showModalInfo, setShowModalInfo] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [abrirFiltroGestaoContrato, setAbrirFiltroGestaoContrato] =
    useState(false);
  const pageAtual = useSelector(state => state.filterPage?.page);
  console.log('asasasasasaddsasss', pageAtual)
  const reduxStateAtual = useSelector(state => state.filtroContrato);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState(false);
  const [clearInputs, setClearInputs] = useState(false);
  const [contrato, setContrato] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function clearFilter() {
    const reduxObj = reduxStateAtual
    const reduxObj2 = reduxStateInicial.filtroContrato
    const obj1 = JSON.stringify(reduxObj)
    const obj2 = JSON.stringify(reduxObj2)
    const filterActive = obj1 === obj2
    setVisible(!filterActive)
    setClear(true)
    const filtroContrato = { ...reduxStateInicial.filtroContrato };
    filtroContrato.status = "TODOS"
    dispatch(setFiltroContrato(filtroContrato));
  }

  const listaSemFiltroContrato = useSelector(
    (state) => state.grupoEconomico.listaContrato
  );
  const id = useSelector((state) => state.filtroContrato.id);

  const nomeContrato = useSelector(
    (state) => state.filtroContrato.nomeContrato
  );
  const numeroContrato = useSelector(
    (state) => state.filtroContrato.numeroContrato
  );
  const tipoLancamento = useSelector(
    (state) => state.filtroContrato.tipoLancamento
  );
  const valorTotal = useSelector((state) => state.filtroContrato.valorTotal);
  const numeroParcelas = useSelector(
    (state) => state.filtroContrato.numeroParcelas
  );
  const status = useSelector((state) => state.filtroContrato.status);
  const nomeParceiro = useSelector(
    (state) => state.filtroContrato.nomeParceiro
  );

  const empresaNomeFantasia = useSelector(
    (state) => state.filtroContrato.empresaNomeFantasia
  );
  const dataInicioPeriodo = useSelector(
    (state) => state.filtroContrato.dataInicioPeriodo
  );
  const dataFimPeriodo = useSelector(
    (state) => state.filtroContrato.dataFimPeriodo
  );
  const history = useHistory();
  const [dialogExcluirContrato, setDialogExcluirContrato] = useState(false);
  const [idTabelaParaModal, setIdTabelaParaModal] = useState(null);
  const [descricaoModalExcluir, setDescricaoModalExcluir] = useState(false);
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [optionSelected, setOptionSelected] = useState();

  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || pageAtual || paginationDefault);

  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }
  }, [props, page])

  async function functionExcluirEmpresa(rowData) {
    setIsLoading(true)
    const contratoComMovimentacoes = await getByIdContrato(rowData.id)
    setOptionSelected(contratoComMovimentacoes);
    setDialogExcluirContrato(true);
    setIdTabelaParaModal(rowData.id);
    setContrato(contratoComMovimentacoes);
    setIsLoading(false)
    setDescricaoModalExcluir(rowData.nomeContrato);
  }

  function linhaEditarMovContrato(rowData) {
    history.push(`/contrato/${rowData.id}`, { page: lazyParams, idContrato: rowData.id });
  }

  const montarFiltro = useCallback(() => {
    let filtroContrato = {
      // idUsuario: null,
      id: idProps ?? id,
      nomeContrato,
      numeroContrato,
      tipoLancamento,
      valorTotal,
      numeroParcelas,
      status: status === null ? 'ABERTO' : status === "TODOS" ? null : status,
      nomeParceiro,
      empresaNomeFantasia,
      dataInicioPeriodo: convertToISO8601(dataInicioPeriodo),
      dataFimPeriodo: convertToISO8601(dataFimPeriodo),
    };
    return filtroContrato;
  }, [
    id,
    nomeContrato,
    numeroContrato,
    tipoLancamento,
    valorTotal,
    numeroParcelas,
    status,
    nomeParceiro,
    empresaNomeFantasia,
    dataInicioPeriodo,
    dataFimPeriodo,
    idProps
  ]);

  const filtroListaContrato = useCallback(async() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };
    await getAllFilterContrato(requestParams)
      .then((resultado) => {
        if (resultado.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "O filtro não obteve resultado",
          });
        }
        const novaArray = resultado.content.map((item) => {
          return {
            ...item,
            tipoLancamento: item.tipoLancamento === "C" ? "Crédito" : "Débito",
            dataInicio: formatISO8601ToUser(item.dataInicio),
            dataFim: formatISO8601ToUser(item.dataFim),
            valorTotal: `R$ ${item.valorTotal.toFixed(2)}`,
          };
        });
        dispatch(addListaContrato(novaArray));
        setTotalRecords(resultado.totalElements);
      })
      .catch((error) => {
        if (idProps === undefined) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "O filtro não obteve resultado",
          });
        }
        dispatch(addListaContrato([]));
      });
    dispatch(esconderLoading());
    setAbrirFiltroGestaoContrato(false);
  }, [dispatch, idProps, lazyParams, montarFiltro])

  useEffect(() => {
    const reduxObj = montarFiltro()
    const reduxObj2 = reduxStateInicial?.filtroContrato

    const copy1 = { ...reduxObj }
    // delete copy1.status
    const copy2 = { ...reduxObj2 }
    // delete copy2.status
    const areEqual = _.isEqual(copy1, copy2)

    console.log('aksdhksjadhsa', copy1)
    console.log('aksdhksjadhsa', copy2)
    console.log('aksdhksjadhsa', areEqual)

    setVisible(!areEqual)
    filtroListaContrato();
  }, [montarFiltro, lazyParams, filtroListaContrato, reduxStateInicial]);

  const deletarContrato = async (id, rowData) => {
    setCarregandoRequisicao(true);
    try {
      await deleteContrato(id).then(() => {
        dispatch(setFiltroContrato(reduxStateInicial.filtroContrato))
        setClearInputs(true)
      });
      await deleteArquivosVinculados(rowData);
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: 'Contrato excluído com sucesso!',
        life: 5000,
      });
      setDialogExcluirContrato(false);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Info",
        life: 8000,
        detail:
          "Não foi possível excluir o contrato, uma parcela está vinculada a uma movimentação conciliada. Se deseja deletar o contrato, por favor desconcilie a movimentação primeiro",
      });
    } finally {
      setCarregandoRequisicao(false);
      setDialogExcluirContrato(false);
    }
  };

  const deleteArquivosVinculados = async (rowData) => {
    const parcelasMovimentacoes = rowData.parcelaVencimento.map(parcela => parcela);

    if (parcelasMovimentacoes !== null && parcelasMovimentacoes !== undefined) {

      const movimentacoesIds = parcelasMovimentacoes.map(parcelaMov => parcelaMov.movimentacaoId)
      const parcelasIds = parcelasMovimentacoes.map(parcelaMov => parcelaMov.id)
      
      if (movimentacoesIds !== null && movimentacoesIds !== undefined) {
        movimentacoesIds.forEach(async mov =>  await deletarArquivosMovimentacao(mov))
      }

      if (parcelasIds !== null && parcelasIds !== undefined) {
        parcelasIds.forEach(async parcelaID =>  await deletarArquivosParcela(parcelaID))
      }
    }
  }

  function verificarStatus(rowData) {
    //mostra icone se estiver analitica
    if (rowData.status === "ABERTO") {
      return (
        <Button
          className="formattedStyles formattedStyles--check"
          icon="pi pi-check-circle"
          tooltip="Aberto"
        />
      );
    } else if (rowData.status === "FECHADO") {
      return (
        <Button
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times-circle"
          tooltip="Inativo"
        />
      );
    }
  }

  const columns = [
    {
      field: "",
      header: "",
      style: { maxWidth: "2vw", padding: 0 },
      type: 'custom',
      customFunc: verificarStatusContrato
    },
    {
      field: "tipoLancamento",
      header: "",
      style: { minWidth: 50, maxWidth: "3vw", fontSize: 24 },
      type: "custom",
      customFunc: (e) => formatTypeConciliacao(e?.tipoLancamento),
    },
    {
      field: "id",
      header: "Código",
      style: { maxWidth: "5vw" }
    },
    {
      field: "nomeContrato",
      header: "Nome do Contrato",
      style: { maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "numeroContrato",
      header: "Número do Contrato",
      style: { maxWidth: "12.5vw" },
      type: "customEllipsis",
    },
    {
      field: "parceiro.nomeTratamento",
      header: "Parceiro",
      style: { maxWidth: "13vw" },
      type: "customEllipsis",
    },
    {
      field: "empresa.nomeFantasia",
      header: "Empresa",
      style: { maxWidth: "13vw" },
      type: "customEllipsis",
    },
    {
      field: "dataInicio",
      header: "Início",
      style: { maxWidth: "6vw" }
    },
    {
      field: "dataFim",
      header: "Fim",
      style: { maxWidth: "6vw" }
    },
    {
      field: "valorTotal",
      header: "Valor Total",
      style: { maxWidth: "8vw" },
      type: "customRight",
      customFunc: (e) => formatValueDecimal(e?.valorTotal ? e?.valorTotal : 0),
    },
    {
      field: "numeroParcelas",
      header: "Parcelas",
      style: { maxWidth: "6.5vw" },
    },
    {
      field: "status",
      header: "Status",
      style: { minWidth: 60, maxWidth: "5vw" },
      type: "custom",
      customFunc: verificarStatus,
    },
  ];

  const modalExcluir = [
    { label: "Id:", value: idTabelaParaModal },
    { label: "Nome Contrato:", value: descricaoModalExcluir },
  ];

  const descricaoStatusParcela = [
    {
      nome: "EM ABERTO",
      descricao: "Nenhuma parcela enviada para movimentação",
      cor: "#29BEE4",
    },
    {
      nome: "PARCIALMENTE PROVISIONADO",
      descricao: "Alguma parcela enviada para movimentação",
      cor: "#D9534F",
    },
    {
      nome: "PROVISIONADO",
      descricao: "Todas as parcelas enviadas para movimentação",
      cor: "#FFD700",
    },
    {
      nome: "CONCILIADO",
      descricao: "Todas as parcelas conciliadas em movimentação",
      cor: "#5CB85C",
    },
  ];

  function verificarStatusContrato(contrato) {
    console.log(contrato)
    const parcelas = contrato?.parcelaVencimento;
    const statusParcelas = parcelas?.map(
      (parcela) => parcela?.statusParcela ?? ""
    );

    const styles = {
      width: '90%',
      height: '90%',
    }
    let rowClassName;

    switch (true) {
      case statusParcelas?.every((status) => status === "ABERTO") ||
        statusParcelas?.every((status) => status === "EM ABERTO"):
        // rowClassName = "status__aberto";
        rowClassName = <span style={{ background: '#00ccff39', ...styles }}></span>;
        break;
      case statusParcelas?.every((status) => status === "CONCILIADO"):
        // rowClassName = "status__conciliado";
        rowClassName = <span style={{ background: '#09e4092f', ...styles }}></span>;
        break;
      case statusParcelas?.every((status) => status === "PROVISIONADO"):
        // rowClassName = "status__provisionado";
        rowClassName = <span style={{ background: '#ffd90041', ...styles }}></span>;
        break;
      case statusParcelas?.includes("CONCILIADO") &&
        statusParcelas?.includes("ABERTO"):
        // rowClassName = "status__parcialmente";
        rowClassName = <span style={{ background: '#fb080023', ...styles }}></span>;
        break;
      case statusParcelas?.includes("EM ABERTO") &&
        statusParcelas?.includes("CONCILIADO"):
        rowClassName = <span style={{ background: '#fb080023', ...styles }}></span>;
        break;
      case statusParcelas?.includes("CONCILIADO") &&
        statusParcelas?.includes("PROVISIONADO"):
        // rowClassName = "status__provisionado";
        rowClassName = <span style={{ backgroundColor: '#ffd90041', ...styles }}></span>;
        break;
      case statusParcelas?.includes("CONCILIADO") &&
        statusParcelas?.some((status) => status !== "ABERTO" || "EM ABERTO"):
        // rowClassName = "status__parcialmente";
        rowClassName = <span style={{ background: '#fb080023', ...styles }}></span>;
        break;
      case statusParcelas?.some((status) => status !== "PROVISIONADO") &&
        statusParcelas?.some((status) => status === "PROVISIONADO"):
        // rowClassName = "status__parcialmente";
        rowClassName = <span style={{ background: '#fb080023', ...styles }}></span>;
        break;
      default:
    }

    return rowClassName;
  }

  function titleModal() {
    let parametro = 0;
    let tamanho = optionSelected?.parcelaVencimento.length;

    for (let a = 0; a < tamanho; a++) {
      if (
        optionSelected?.parcelaVencimento[a]?.statusParcela === "CONCILIADO" ||
        optionSelected?.parcelaVencimento[a]?.statusParcela === "PROVISIONADO"
      ) {
        parametro++;
      }
    }
    if (parametro !== 0) {
      return "Caso o contrato seja excluído, todas as parcelas e movimentações vinculadas a ele também serão excluídas.  Deseja confirmar a exclusão?";
    } else {
      return "Caso o contrato seja excluído, todas as parcelas vinculadas a ele também serão excluídas. Deseja confirmar a exclusão?";
    }
  }

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <ModalComponents
          title={titleModal()}
          onClick="custom"
          visible={dialogExcluirContrato}
          descricao={modalExcluir}
          onHide={() => setDialogExcluirContrato(false)}
          onClickConfirmar={() => deletarContrato(idTabelaParaModal, contrato)}
          onClickCancelar={() => setDialogExcluirContrato(false)}
        />{" "}
        <Table
          rowClassName={(rowData) => verificarStatusContrato(rowData)}
          arrayData={listaSemFiltroContrato}
          loading={recarregando}
          headerTitle={
            idProps === undefined ? "Gestão de Contrato" : "Contrato"
          }
          columns={columns}
          onClickFiltrar={
            idProps === undefined
              ? () => setAbrirFiltroGestaoContrato(true)
              : null
          }
          onClickAdicionar={
            idProps === undefined
              ? () => history.push("/contrato/cadastro", { page: lazyParams })
              : null
          }
          onClickEdit={(rowData) => linhaEditarMovContrato(rowData)}
          onClickVoltar={idProps === undefined ? null : true}
          data={{ type: "movimentacao" }}
          page={pageMov}
          tooltipEdit="Contrato"
          onClickPortions2={(rowData) => history.push(`/contrato/${rowData.id}/parcelas`, { pageContract: lazyParams, idContrato: rowData.id })}
          disabledDelete={isLoading}
          onClickDelete={
            idProps === undefined
              ? (rowData) => functionExcluirEmpresa(rowData)
              : null
          }
          onClickHeaderInfo={() => setShowModalInfo(true)}
          headerInfoHoverMessage="Mostrar descrição de cores"
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
        <ModalDescricaoDeCores
          datatesteId={"modal__info"}
          visible={showModalInfo}
          setState={setShowModalInfo}
          arrayDescricao={descricaoStatusParcela}
          title="Descrição dos status das parcelas que o contrato possui"
        />
        <FiltroGestaoDeContrato
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          abrirFiltroGestaoContrato={abrirFiltroGestaoContrato}
          setAbrirFiltroGestaoContrato={setAbrirFiltroGestaoContrato}
          setLazyParamsProp={setLazyParams}
        />
      </LoadingOverlayComponents>
    </>
  );
};
