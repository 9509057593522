import axios from "axios";

const url = process.env.REACT_APP_API_URL;
const urlGcp = process.env.REACT_APP_API_GCP;
const bucket = process.env.REACT_APP_BUCKET_NAME_GOOGLE_CLOUD;

export default axios.create({
  baseURL: `${url}`,
});

export const getListaAnexoFiltro = (type, id) =>
  axios
    .get(`${urlGcp}/gcp/listFiles?directory=financeiro-api/${type}/${id}/`)
    .then((response) => response.data);

export const getUrlSignedForDownload = (directory) =>
  axios
    .get(`${urlGcp}/gcp/signedUrlForDownload?bucketName=${bucket}&objectName=${directory}`)
    .then((response) => response.data);

export const getUrlSignedForDelete = (directory) =>
  axios
    .get(`${urlGcp}/gcp/signedUrlForDelete?bucketName=${bucket}&objectName=${directory}`)
    .then((response) => response.data);

export const saveFileParcelaOrMovimentacao = (type, body) =>
  axios
    .post(`${url}/arquivo/${type}`, body)
    .then((response) => response.data);

export const saveFileToTheCloud = (id) =>
  axios
    .put(`${url}/arquivo/finalizar/${id}`)
    .then((response) => response.data);

export const salvarAnexoMovimentacao = (anexoDTO) =>
  axios
    .post(`${url}/save-financeiro-arquivo-movimentacao`, anexoDTO)
    .then((response) => response.data);

export const getAllFileParcelaOrMovimentacao = (type, id) => 
  axios
    .get(`${url}/arquivo/${type}/${id}`)
    .then((response) => response.data);

export const deleteFile = (id) =>
  axios
    .delete(`${url}/arquivo/${id}`)
    .then((response) => response.status);

export const atualizarAnexo = (id, descricao) =>
  axios
    .put(`${url}/update-descricao-arquivo/${id}?descricao=${descricao}`)
    .then((response) => response.status);

export const downloadAnexo = (objectName) =>
  axios
    .get(`${url}/download-financeiro-arquivo`, {
      responseType: "blob",

      params: {
        objectName: objectName,
      },
    })
    .then((response) =>
      URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      )
    );