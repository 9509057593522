import React from "react";
import { EditarGestaoDeContrato } from "../../components/EditarGestaoDeContrato";

export const AlterarGestaoDeContrato = (props) => {
  return (
    <>
      <EditarGestaoDeContrato
        idContrato={props?.location?.state?.idContrato}
        page={props?.location?.state?.page}
      />
    </>
  );
};
