import { combineReducers } from "redux";
import { filtroAnexo } from "./Anexo/filtroAnexo";
import { filtroCentroDeCusto, filtroCentroDeCustoDrop } from "./CentroDeCusto/";
import { conciliacao, filtroConci } from "./Conciliacao/";
import {
  bancoContaCorrenteFiltroDrop,
  contas,
  contasCorrentes,
  descricaoContaCorrenteFiltroDrop,
  descricaoContaFiltroDrop,
  filtroCC,
  filtroContaCorrenteDrop,
  numeroBancoFiltroDrop,
  numeroContaCorrenteFiltroDrop
} from "./Conta/";
import { filtroEmailCob } from "./EmailCobrancaInad/";
import { filtroEmailsEnviados } from "./EmailsEnviados/";
import { empresaFiltroDrop, filtroEmpresa, listaEmpresa } from "./Empresa/";
import { alertas, filtro, totalizadores } from "./FluxoCaixa/";
import { filtroContrato, filtroContratoDrop } from "./GestaoDeContrato/";
import {
  filtroGE,
  grupoEconomico,
  grupoEconomicoFiltroDrop,
} from "./GrupoEconomico/";
import {
  filterPage,
  filtroMov,
  movimentacaoListaLancamento,
  movimentacoes,
  salvarEditar
} from "./Movimentacao/";
import {
  descricaoNaturezaDrop,
  filtroNatu,
  filtroNaturezaDrop,
  naturezaHierarquica,
  naturezas,
} from "./Natureza/";
import { filtroParceiro, filtroParceiroDrop } from "./Parceiro/";
import {
  descricaoParcelaContratoDrop,
  descricaoParcelaFiltroDrop,
  filtroParcela,
} from "./Parcela/";
import { descricaoProdFiltroDrop, filtroProd, nomeMultipleProdServico } from "./ProdutoServico/";
import { filtroProjetoDrop } from "./Projeto/";
import { usuarioFiltroDrop } from "./Usuario/";

export default combineReducers({
  movimentacoes,
  filtro,
  filtroConci,
  contas,
  contasCorrentes,
  totalizadores,
  alertas,
  movimentacaoListaLancamento,
  filtroCC,
  filtroMov,
  filtroNatu,
  salvarEditar,
  conciliacao,
  listaEmpresa,
  naturezas,
  naturezaHierarquica,
  filtroGE,
  grupoEconomico,
  filtroEmpresa,
  filtroProd,
  descricaoProdFiltroDrop,
  filtroParcela,
  filtroAnexo,
  filtroEmailCob,
  filtroEmailsEnviados,
  filtroContrato,
  filtroParceiro,
  filtroParceiroDrop,
  usuarioFiltroDrop,
  empresaFiltroDrop,
  grupoEconomicoFiltroDrop,
  numeroBancoFiltroDrop,
  numeroContaCorrenteFiltroDrop,
  filtroNaturezaDrop,
  filtroContaCorrenteDrop,
  filtroProjetoDrop,
  descricaoContaFiltroDrop,
  descricaoParcelaFiltroDrop,
  filtroContratoDrop,
  descricaoContaCorrenteFiltroDrop,
  filtroCentroDeCustoDrop,
  descricaoNaturezaDrop,
  descricaoParcelaContratoDrop,
  filterPage,
  filtroCentroDeCusto,
  bancoContaCorrenteFiltroDrop,
  nomeMultipleProdServico
});
