const INITIAL_STATE = {
    descricao: null,
};

export const descricaoContaFiltroDrop = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case "SET_FILTRO_DESCRICAO_CONTA_DROP":
            return { ...action.payload };
        default:
            return state;
    }
};