import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroContrato } from "../../actions";
import {
  getEmpresaByNomeFantasia,
  getParceiroByNomeTratamento,
} from "../../api";
import { paginationDefault } from "../../default/pagination";
import { brazilianCalendarParams } from "../../utils";
import { Filtro } from "../Filtro";

export const FiltroGestaoDeContrato = ({
  setAbrirFiltroGestaoContrato,
  abrirFiltroGestaoContrato,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const toast = useRef(null);

  const selectCreditoDebido = [
    { name: "Ambos", value: null },
    { name: "Crédito", value: "C" },
    { name: "Débito", value: "D" },
  ];

  const selectStatus = [
    { name: "Todos", value: "TODOS" },
    { name: "Aberto", value: "ABERTO" },
    { name: "Fechado", value: "FECHADO" },
  ];

  addLocale("pt-br", brazilianCalendarParams);
  const dispatch = useDispatch();
  const [id, setId] = useState(useSelector((state) => state.filtroContrato.id));

  const [nomeContrato, setNomeContrato] = useState(
    useSelector((state) => state.filtroContrato.nomeContrato)
  );
  const [numeroContrato, setNumeroContrato] = useState(
    useSelector((state) => state.filtroContrato.numeroContrato)
  );
  const [tipoLancamento, setTipoLancamento] = useState(
    useSelector((state) => state.filtroContrato.tipoLancamento)
  );
  const [nomeParceiro, setNomeParceiro] = useState();
  const [listaParceiro, setListaParceiro] = useState([]);

  const [empresaNomeFantasia, setEmpresaNomeFantasia] = useState();
  const [listaEmpresa, setListaEmpresa] = useState([]);

  const [dataCriacaoInicio, setDataCriacaoInicio] = useState(
    useSelector((state) => state.filtroContrato.dataInicioPeriodo)
  );
  const [dataCriacaoFim, setDataCriacaoFim] = useState(
    useSelector((state) => state.filtroContrato.dataFimPeriodo)
  );
  const [valorTotal, setValorTotal] = useState(
    useSelector((state) => state.filtroContrato.valorTotal)
  );
  const [numeroParcelas, setNumeroParcelas] = useState(
    useSelector((state) => state.filtroContrato.numeroParcelas)
  );
  const [status, setStatus] = useState("ABERTO");

  console.log("status", status);

  function getListaEmpresa(nomeFantasia) {
    getListWith({
      endpoint: getEmpresaByNomeFantasia,
      searchBy: nomeFantasia,
      setState: setListaEmpresa,
      notFoundMessage: "Empresa não encontrada",
      errorMessageFrom: "empresa",
    });
  }

  function getListaParceiros(nomeTratamento) {
    getListWith({
      endpoint: getParceiroByNomeTratamento,
      searchBy: nomeTratamento,
      setState: setListaParceiro,
      notFoundMessage: "Parceiro não encontrado",
      errorMessageFrom: "parceiro",
    });
  }

  function getListWith({
    endpoint,
    searchBy,
    setState,
    notFoundMessage,
    errorMessageFrom,
  }) {
    endpoint(searchBy)
      .then((response) => {
        setState(response);
        if (response.length === 0) {
          searchNotFound(notFoundMessage);
        }
      })
      .catch(() => searchHandlerError(errorMessageFrom));
  }

  function searchNotFound(detail) {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: detail,
    });
  }

  function searchHandlerError(name) {
    toast.current.show({
      severity: "error",
      summary: `Erro ao buscar por ${name}`,
      detail: `Houve um problema inesperado e a busca falhou, tente novamente mais tarde`,
    });
  }

  const filterFields = [
    {
      name: "id",
      label: "Código:",
      value: id,
      setState: setId,
      dataType: "pint",
      datatesteid: "idTest",
    },
    {
      name: "nomeContrato",
      label: "Nome do Contrato:",
      value: nomeContrato,
      setState: setNomeContrato,
      datatesteid: "nomeContratoTest",
    },
    {
      name: "numeroContrato",
      label: "Número do Contrato:",
      value: numeroContrato,
      setState: setNumeroContrato,
      dataType: "pint",
      datatesteid: "numeroContratoTest",
    },
    {
      name: "creditoOuDebito",
      label: "Tipo:",
      value: tipoLancamento,
      setState: setTipoLancamento,
      type: "selectButton",
      options: selectCreditoDebido,
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "tipoLancamentoTest",
    },
    {
      label: "Parceiro:",
      value: nomeParceiro,
      setValue: setNomeParceiro,
      type: "autoCompletar",
      options: listaParceiro,
      optionLabel: "nomeTratamento",
      datatesteid: "parceiroTest",
      completeMethod: getListaParceiros,
    },
    {
      label: "Empresa:",
      value: empresaNomeFantasia,
      setValue: setEmpresaNomeFantasia,
      type: "autoCompletar",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      datatesteid: "empresaTest",
      completeMethod: getListaEmpresa,
    },
    {
      label: "Intervalo de Datas:",
      rangeOfDate: true,
      value: dataCriacaoInicio,
      setState: setDataCriacaoInicio,
      endValue: dataCriacaoFim,
      setEndState: setDataCriacaoFim,
      type: "date",
      directionCol: true,
      datatesteid: "dataInicialId",
      datatesteidrange: "dataFinalId",
    },
    {
      name: "valorTotal",
      label: "Valor Total:",
      value: valorTotal,
      setState: setValorTotal,
      datatesteid: "valorTotalTest",
      dataType: "pint",
    },
    {
      name: "numParcelas",
      label: "Número de Parcelas:",
      value: numeroParcelas,
      setState: setNumeroParcelas,
      dataType: "pint",
      datatesteid: "numParcelasTest",
    },
    {
      name: "status",
      label: "Status:",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: selectStatus,
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "statusTest",
    },
  ];

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroContrato({
        id: id !== '' ? id : null,
        nomeContrato: nomeContrato !== '' ? nomeContrato : null,
        numeroContrato: numeroContrato !== '' ? numeroContrato : null,
        tipoLancamento: tipoLancamento,
        dataInicioPeriodo: dataCriacaoInicio,
        dataFimPeriodo: dataCriacaoFim,
        valorTotal: valorTotal !== '' ? valorTotal : null,
        numeroParcelas: numeroParcelas !== '' ? numeroParcelas : null,
        status: status,
        empresaNomeFantasia: empresaNomeFantasia?.nomeFantasia
          ? empresaNomeFantasia?.nomeFantasia
          : null,
        nomeParceiro: nomeParceiro?.nomeTratamento
          ? nomeParceiro.nomeTratamento
          : null,
      })
    );
  };

  const limpar = useCallback(() => {
    setId('');
    setNomeContrato('');
    setNumeroContrato('');
    setTipoLancamento(null);
    setNomeParceiro(null);
    setEmpresaNomeFantasia(null);
    setDataCriacaoInicio(null);
    setDataCriacaoFim(null);
    setValorTotal('');
    setNumeroParcelas('');
    setStatus("TODOS");
    setVisible(() => false)
    setClearInputs(false)
  }, [setVisible, setClearInputs]);

  useEffect(() => {
    if (clear === true) {
      limpar();
    }
  }, [clear, limpar]);

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <>
      <Toast ref={toast} />
      <Filtro
        filterFields={filterFields}
        setIsFiltroVisible={setAbrirFiltroGestaoContrato}
        isFiltroVisible={abrirFiltroGestaoContrato}
        clearFilter={limpar}
        handleConfirmFilter={filtrar}
        toastRef={toast}
      />
    </>
  );
};
