import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getByIdRateio = (idMovimentacao) =>
  axios
    .get(`${url}/movimentacoes/${idMovimentacao}/rateios`)
    .then((response) => response.data);

export const deletarRateio = (id) =>
  axios.delete(`${url}/rateios/${id}`).then((response) => response.status);

export const postRateio = (rateio) =>
  axios.post(`${url}/rateios`, rateio).then((response) => response.data);
