import React from "react";
import { EditarGrupoEconomico } from "../../components/EditarGrupoEconomico";


export const AlterarGrupoEconomico = (props) => {
    return (
        <>
            <EditarGrupoEconomico
                editGrupoEconomico={props?.location?.state?.grupoEconomico}
                page={props?.location?.state?.page}
            />
        </>
    )
}
