export function formatValue(value) {
    
    if (value !== null) {
        const valorFormatado = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);

        return valorFormatado;
    }
    return "";
};

export function formatValueDecimal(value) {
    const valorSemSimbolo = value?.replace(/(R\s*\$\s*)/g, '');
    const valorNumerico = parseFloat(valorSemSimbolo?.replace(',', '.'));
    if (isNaN(valorNumerico)) {
        return 'Valor inválido';
    }
    const valorFormatado = valorNumerico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    return valorFormatado;
};