import "bootstrap/dist/css/bootstrap.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/rhea/theme.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "./global.css";
import { AlterarContaCorrente } from "./pages/AlterarContaCorrente";
import { AlterarEmpresa } from "./pages/AlterarEmpresa";
import { AlterarGestaoDeContrato } from "./pages/AlterarGestaoDeContrato";
import { AlterarGrupoEconomico } from "./pages/AlterarGrupoEconomico";
import { AlterarParametroSistema } from "./pages/AlterarParametroSistema";
import { Anexos } from "./pages/Anexos";
import { CentroDeCusto } from "./pages/CentroDeCusto";
import { ConciliacaoBancaria } from "./pages/ConciliacaoBancaria";
import { ContasCorrentes } from "./pages/ContasCorrentes/";
import { DetalhesEmailsEnviados } from "./pages/DetalhesEmailsEnviados";
import { EmailCobrancaInad } from "./pages/EmailCobrancaInad";
import { EmailsEnviados } from "./pages/EmailsEnviados/";
import { Empresa } from "./pages/Empresa/";
import { GestaoDeContrato } from "./pages/GestaoDeContrato";
import { GrupoEconomico } from "./pages/GrupoEconomico/index";
import { Login } from "./pages/Login";

import { HomeRoutes } from "./pages/HomeRoutes";
import { Movimentacao } from "./pages/Movimentacao";
import { NaoEncontrada } from "./pages/NaoEncontrada";
import { Natureza } from "./pages/Natureza";
import { ParametrosSistema } from "./pages/ParametrosSistema/";
import { Parceiros } from "./pages/Parceiros";
import { ParceirosAdicionar } from "./pages/ParceirosAdicionar";
import { Parcelas } from "./pages/Parcelas";
import { ParcelasAdicionar } from "./pages/ParcelasAdicionar";
import { ProdutoServico } from "./pages/ProdutoServico";
import { SalvarEditar } from "./pages/SalvarEditar";
import "./styles.primereact.css";

import AuthContext from "./context/AuthContext";
import { DFC } from "./pages/DFC";
import PrivateRoute from "./routes/PrivateRoute";
function App() {
  
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  )

  useEffect(() => {
    const savedIsAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    if (savedIsAuthenticated !== undefined) {
      setIsAuthenticated(savedIsAuthenticated)
    }
  }, [])

  return (
    <AuthContext>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={() => (isAuthenticated ? <HomeRoutes /> : <Login />)}
          />
          {/* <Route path="/usuarios" exact component={CadastroUsuario} /> */}
          <PrivateRoute path="/dfc" exact component={DFC} />
          <PrivateRoute path="/home" exact component={HomeRoutes} />
          <PrivateRoute path="/movimentacoes" exact component={Movimentacao} />
          <PrivateRoute
            path="/movimentacao/cadastro"
            exact
            component={SalvarEditar}
          />
          <PrivateRoute
            path="/movimentacao/:idMovimentacao"
            exact
            component={SalvarEditar}
          />
          <PrivateRoute
            path="/contrato/:idContrato/parcela/:idParcela/movimentacao/cadastro"
            exact
            component={SalvarEditar}
          />
          <PrivateRoute
            path="/movimentacoes/filter/natureza/:naturezaId/ano/:ano/mes/:mes/empresaId/:empresaId/tipo/:tipo"
            exact
            component={Movimentacao}
          />
          <PrivateRoute
            path="/contrato/:idContrato/parcela/:idParcela/movimentacao/:idMovimentacao"
            exact
            component={SalvarEditar}
          />
          <PrivateRoute
            path="/contas-correntes"
            exact
            component={ContasCorrentes}
          />
          <PrivateRoute
            path="/conta-corrente/cadastro"
            exact
            component={AlterarContaCorrente}
          />
          <PrivateRoute
            path="/conta-corrente/:idContaCorrente"
            exact
            component={AlterarContaCorrente}
          />
          <PrivateRoute path="/empresas" exact component={Empresa} />
          <PrivateRoute
            path="/empresa/cadastro"
            exact
            component={AlterarEmpresa}
          />
          <PrivateRoute
            path="/empresa/:idEmpresa"
            exact
            component={AlterarEmpresa}
          />
          <PrivateRoute
            path="/grupos-economicos"
            exact
            component={GrupoEconomico}
          />
          <PrivateRoute
            path="/grupo-economico/cadastro"
            exact
            component={AlterarGrupoEconomico}
          />
          <PrivateRoute
            path="/grupo-economico/:idGrupoEconomico"
            exact
            component={AlterarGrupoEconomico}
          />
          <PrivateRoute
            path="/centros-de-custos"
            exact
            component={CentroDeCusto}
          />
          <PrivateRoute
            path="/conciliacoes-bancarias"
            exact
            component={ConciliacaoBancaria}
          />
          <PrivateRoute
            path="/parametro-sistema/:idParametroSistema"
            exact
            component={AlterarParametroSistema}
          />
          <PrivateRoute
            path="/parametros-do-sistema"
            exact
            component={ParametrosSistema}
          />
          <PrivateRoute path="/parceiros" exact component={Parceiros} />
          <PrivateRoute
            path="/parceiro/cadastro"
            exact
            component={ParceirosAdicionar}
          />
          <PrivateRoute
            path="/parceiro/:idParceiro"
            exact
            component={ParceirosAdicionar}
          />
          <PrivateRoute
            path="/contrato/:idContrato/parcelas"
            exact
            component={Parcelas}
          />
          <PrivateRoute
            path="/contrato/:idContrato/parcela/:idParcela"
            exact
            component={ParcelasAdicionar}
          />
          <PrivateRoute
            path="/contrato/:idContrato/parcela/cadastro"
            exact
            component={ParcelasAdicionar}
          />
          <PrivateRoute
            path="/produtos-servicos"
            exact
            component={ProdutoServico}
          />
          <PrivateRoute path="/contratos" exact component={GestaoDeContrato} />
          <PrivateRoute
            path="/contrato/:idContrato"
            exact
            component={AlterarGestaoDeContrato}
          />
          <PrivateRoute
            path="/contrato/cadastro"
            exact
            component={AlterarGestaoDeContrato}
          />
          <PrivateRoute
            path="/movimentacao/contrato/:idContrato"
            exact
            component={GestaoDeContrato}
          />
          <PrivateRoute
            path="/cobranca-inadimplente"
            exact
            component={EmailCobrancaInad}
          />
          <PrivateRoute path="/naturezas" exact component={Natureza} />
          <PrivateRoute path="/natureza/:idNatureza" component={Natureza} />
          <PrivateRoute path="/:type/:id/anexos" exact component={Anexos} />
          <PrivateRoute
            path="/emails-enviados"
            exact
            component={EmailsEnviados}
          />
          <PrivateRoute
            path="/emails-enviados/:id/detalhes"
            exact
            component={DetalhesEmailsEnviados}
          />
          <Route path="*" component={NaoEncontrada} />
        </Switch>
      </BrowserRouter>
    </AuthContext>
  )
};

export default App;
