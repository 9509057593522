export const setEmpresa = (empresa) => ({
    type: "SET_EMPRESA",
    payload: empresa,
});

export const setFiltroEmpresaDrop = (empresa) => ({
    type: "SET_FILTRO_EMPRESA_DROP",
    payload: empresa,
});

export const addListaEmpresa = (listaEmpresa) => ({
    type: "ADD_LISTA_EMPRESA",
    payload: listaEmpresa,
});

export const setIdEmpresa = (idEmpresa) => ({
    type: "SET_EMPRESA",
    payload: idEmpresa,
});

export const setFiltroEmpresa = (filtroEmpresa) => ({
    type: "SET_FILTRO_EMPRESA",
    payload: filtroEmpresa,
});

export const addMovEmpresa = (empresa) => ({
    type: "ADD_MOV_EMPRESA",
    payload: empresa,
});
