import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const deleteParceiro = (id) =>
  axios.delete(`${url}/parceiros/${id}`).then((response) => response.status);

export const getAllFilterParceiros = (requestParams) =>
  axios
    .post(
      `${url}/parceiros/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const saveParceiro = (body) =>
  axios.post(`${url}/parceiros`, body).then((response) => response.status);

export const putParceiro = (id, body) =>
  axios.put(`${url}/parceiros/${id}`, body).then((response) => response.status);

export const getParceiroByNomeTratamento = (nomeTratamento) =>
  axios
    .get(`${url}/parceiros/nome-tratamento/${nomeTratamento}`)
    .then((response) => response.data);

export const getParceiroByNomeTratamentoEAtivo = (nomeTratamento) =>
  axios
    .get(`${url}/parceiros/nome-tratamento-ativo/${nomeTratamento}`)
    .then((response) => response.data);

export const getByIdParceiro = (id) =>
  axios
    .get(`${url}/parceiros/${id}`)
    .then((response) => response.data);

export const getAllTiposParceiro = () =>
  axios
    .get(`${url}/parceitoTipo/findAll`)
    .then((response) => response.data);
