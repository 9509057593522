export const setFiltroContrato = (filtroContrato) => ({
  type: "SET_FILTRO_CONTRATO",
  payload: filtroContrato,
});

export const addListaContrato = (listaContrato) => ({
  type: "ADD_LISTA_CONTRATO",
  payload: listaContrato,
});

export const setFiltroContratoDrop = (contrato) => ({
  type: "SET_FILTRO_CONTRATO_DROP",
  payload: contrato,
});
