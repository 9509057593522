import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import * as yup from 'yup';
import { addListaAnexo, esconderLoading, exibirLoading } from "../../actions";
import {
  apiGCP,
  deleteFile,
  getAllFileParcelaOrMovimentacao,
  getListaAnexoFiltro,
  getUrlSignedForDelete,
  getUrlSignedForDownload,
  saveFileParcelaOrMovimentacao
} from "../../api";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalAdicao } from "../../components/ModalAdicao";
import { ModalComponents } from "../../components/ModalComponents";
import { Navbar } from "../../components/Navbar";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import { messageRequired } from "../../default/messages";
import { paginationDefault } from "../../default/pagination";

export const Anexos = (props) => {
  const dispatch = useDispatch();
  const {type, id} = useParams()
  const directory = process.env.REACT_APP_DIRECTORY_GOOGLE_CLOUD;
  const bucket = process.env.REACT_APP_BUCKET_NAME_GOOGLE_CLOUD;
  const toast = useRef(null);
  const movimentacaoAnexos = useSelector(
    (state) => state.grupoEconomico.listaAnexos
  );

  const [dialogCadastroAnexo, setDialogCadastroAnexo] = useState(false);
  const [dialogExcluirAnexo, setDialogExcluirAnexo] = useState(false);
  const [dialogOpenAnexo, setDialogOpenAnexo] = useState(false);
  const [fileObject, setFileObject] = useState(null);
  const [file, setFile] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [abrirModalSalvarAnexo, setAbrirModalSalvarAnexo] = useState(false);
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [lazyParams, setLazyParams] = useState(paginationDefault);
  
  const allowedExtensions = ['pdf', 'jpg', 'png', 'jpeg', 'doc', 'xml', 'xls', 'xlsx', 'mp4', 'avi', 'txt'];
  const MAX_FILE_SIZE_MB = 200; // Tamanho máximo do arquivo em MB
  
  const fileValidation = yup.object({
    fileYup: yup
      .mixed()
      .required('É necessário escolher um arquivo.')
      .test(
        'fileType',
        'Apenas arquivos com as seguintes extensões são permitidos: txt, pdf, jpg, png, jpeg, doc, xml, xls, xlsx, mp4, avi.',
        (value) => {
          const fileExtension = (value && value.name.split('.').pop().toLowerCase()) || value?.split('.').pop().toLowerCase();
          return fileExtension && allowedExtensions.includes(fileExtension);
        },
      )
      .test('fileSize', `O tamanho máximo do arquivo é ${MAX_FILE_SIZE_MB} MB.`, (value) => {
        const fileSizeInMB = value && value.size / (1024 * 1024);
        return fileSizeInMB && fileSizeInMB <= MAX_FILE_SIZE_MB;
      }),
    fileDesc: yup.string().required(messageRequired),
  });
  
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(fileValidation),
    });

  function functionExcluirAnexo(rowData) {
    setDialogExcluirAnexo(true);
    setFileObject(rowData);
  }

  const handleOpenAnexo = async (rowData) => {
    const response = await getUrlSignedForDownload(rowData.urlCloud)
    const url = response

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', rowData);
    link.click();
  };

  const anexoSchema = Yup.object().shape({
    file: Yup.mixed().required('É necessário escolher um arquivo.'),
    descricao: Yup.string().required('A descrição é obrigatória.'),
  });

  const verificarInput = async () => {
    try {
      await anexoSchema.validate({ file, descricao }, { abortEarly: false });
      return true;
    } catch (error) {
      error.inner.forEach((e) => {
        if (e.path === 'file' || e.path === 'descricao') {
          return false;
        }
      });
      return false;
    }
  };

  const deleteAnexo = async () => {
    setCarregandoRequisicao(true);
    const { idFile, urlCloud } = fileObject

    try {
      await deleteFile(idFile)
      const response = await getUrlSignedForDelete(urlCloud)
      await axios.delete(response)
      await filtroListaAnexo()
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Anexo excluído com sucesso!",
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao excluir anexo",
      });
    } finally {
      setCarregandoRequisicao(false);
    }

    setDialogExcluirAnexo(false);
  };

  async function handlePostAnexo() {
    if (!verificarInput()) {
      return null;
    }

    setCarregandoRequisicao(true);

      const formData = new FormData();
      formData.append("file", file);
      try {
        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
        const dia = String(dataAtual.getDate()).padStart(2, '0');
        const hora = String(dataAtual.getHours()).padStart(2, '0');
        const minuto = String(dataAtual.getMinutes()).padStart(2, '0');
        const segundo = String(dataAtual.getSeconds()).padStart(2, '0');
        const nomeArquivo = file?.name;
        const objectName = `${type === 'parcela' ? `${directory}parcela` : `${directory}movimentacao` }/${id}/${ano + mes + dia + hora + minuto + segundo + nomeArquivo}`;
        const userId = JSON.parse(localStorage.getItem('loggerUser')).userId
        const objectFileCloud = {
          nomeArquivo: ano + mes + dia + hora + minuto + segundo + nomeArquivo,
          descricao: descricao,
          userID: userId,
        };
        if (type === 'parcela') {
          objectFileCloud.parcelaID = id
        } 
        if (type === 'movimentacao') {
          objectFileCloud.movimentacaoID = id
        }
        const result = await apiGCP.get(`/gcp/signedUrlForUpload?bucketName=${bucket}&objectName=${objectName}`, formData);
        await axios.put(result.data, file);
        await saveFileParcelaOrMovimentacao(type === 'parcela' ? 'parcela' : 'movimentacao', objectFileCloud).catch(async() =>{
          const response = await getUrlSignedForDelete(objectName)
          await axios.delete(response)
        })
        setFile('')
        setDescricao('')
        setDialogCadastroAnexo(false)
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Arquivo salvo com sucesso!",
        });
        await filtroListaAnexo()
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao salvar arquivo",
        });
      } finally {
        setCarregandoRequisicao(false);
      }
  };

  const filtroListaAnexo = useCallback(async () => {
    dispatch(exibirLoading());
    try {
      const resultado = await getListaAnexoFiltro(type, id);

      if (resultado.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: `Nenhum arquivo foi encontrado para a ${type} código ${id}`,
        });
        dispatch(addListaAnexo([]));
        dispatch(esconderLoading());
      } else {

        const linhas = resultado.trim().split('\n');
      
      const response = await getAllFileParcelaOrMovimentacao(type, id);
      const arrayDeObjetos = linhas.map((linha) => {
        const partes = linha.split('/');
        const objetoDesejado = response.filter(
          (obj) => obj.nomeArquivo === partes[3]
        );
        return {
          api: partes[0],
          type: partes[1],
          id: partes[2],
          fileName: partes[3],
          urlCloud: linha,
          descricao: objetoDesejado ? objetoDesejado[0]?.descricao : null,
          idFile: objetoDesejado ? objetoDesejado[0]?.id : null,
        };
      });
      dispatch(addListaAnexo(arrayDeObjetos));
      dispatch(esconderLoading());
    }
  } catch (error) {
    console.error("Erro na requisição:", error);
    // Lide com o erro conforme necessário
    dispatch(esconderLoading());
  }
}, [type, id, dispatch]);

  useEffect(() => {
    filtroListaAnexo();
  }, [filtroListaAnexo]);

  const columns = [
    {
      field: "idFile",
      header: "Código",
      style: { maxWidth: "30vw" },
    },
    {
      field: "fileName",
      header: "Nome Arquivo",
      style: { maxWidth: "30vw" },
      type: "customEllipsis",
    },
    {
      field: "descricao",
      header: "Descrição",
      style: { maxWidth: "30vw" },
      type: "customEllipsis",
    },
  ];

  const object = {
    fileYup: file,
    fileDesc: descricao,
  }

  useEffect(() => {
    setValue("fileYup", file)
    setValue("fileDesc", descricao)
  }, [file, descricao, setValue])

  const closeModal = () => {
    fileValidation.isValid(object).then(() => {
      setAbrirModalSalvarAnexo(false)
    });
  };

  const addEditfields = [
    {
      label: file?.name || "Escolha um arquivo",
      value: file,
      setState: setFile,
      type: 'file',
      validacao: {...register("fileYup")},
      errorMessage: errors.fileYup?.message,
    },
    {
      label: 'Descrição',
      value: descricao,
      setState: setDescricao,
      type: 'textArea',
      required: true,
      validacao: {...register("fileDesc")},
      errorMessage: errors.fileDesc?.message,
    },

  ]

  const modalExcluir = [
    { label: "Código:", value: fileObject?.idFile },
    { label: "Descrição:", value: fileObject?.descricao },
  ];
  
  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <Toast ref={toast} />
        <ModalAdicao
          title={'Adicionar arquivo'}
          visible={dialogCadastroAnexo}
          setVisible={setDialogCadastroAnexo}
          cancel={() => setDialogCadastroAnexo(false)}
          confirm={() => setAbrirModalSalvarAnexo(true)}
          fields={addEditfields}
          onHide={() => setDialogCadastroAnexo(false)}
        />

        <ModalComponents
          title="Anexo"
          visible={dialogExcluirAnexo}
          onHide={() => setDialogExcluirAnexo(false)}
          descricao={modalExcluir}
          onClickConfirmar={() => deleteAnexo()}
          onClickCancelar={() => setDialogExcluirAnexo(false)}
          onClick="delete"
        />

        <ModalComponents
          title="Anexo"
          visible={abrirModalSalvarAnexo}
          onHide={() => setAbrirModalSalvarAnexo(false)}
          onClick="confirm"
          onClickCancelar={() => setAbrirModalSalvarAnexo(false)}
          handleSubmit={handleSubmit}
          onClickConfirmarForm={() => handlePostAnexo()}
          validationForm={closeModal}
          datatesteidconfirm="confirmarTestID"
          datatesteid="cancelarTestID"
        />

        <Dialog
          header="Visualizar arquivo"
          visible={dialogOpenAnexo}
          onHide={() => setDialogOpenAnexo(false)}
          style={{ width: "70%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
          </div>
        </Dialog>

        <Table
          arrayData={movimentacaoAnexos}
          headerTitle="Anexos"
          columns={columns}
          loading={recarregando}
          onClickDownload={(rowData) => handleOpenAnexo(rowData)}
          onClickDelete={(rowData) => functionExcluirAnexo(rowData)}
          onClickVoltar={true}
          onClickAdicionar={() => setDialogCadastroAnexo(true)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          data={props.location.state}
        />
      </LoadingOverlayComponents>
    </>
  );
};
