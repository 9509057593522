const INITIAL_STATE = {
    ativa: null,
    tipoLancamento: null,
    descricao: "",
    codigoExterno: null,
    pagina: 0,
    qtdRegistros: 100

};

//verificar se a parte de baixo esta faltando algum objeto.
export const filtroNatu = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_DESCRICAO":
            return { ...state, descricao: action.payload };
        case "SET_TIPO_LANCAMENTO":
            return { ...state, tipoLancamento: action.payload };
        case "SET_ATIVA":
            return { ...state, ativa: action.payload };
        case "SET_PAGINA":
            return { ...state, pagina: action.payload };
        case "SET_QTD_REGISTRO":
            return { ...state, qtdRegistros: action.payload };
        default:
            return state;
    }
};
