import { yupResolver } from "@hookform/resolvers/yup";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { setFiltroContratoDrop, setFiltroParcela } from "../../actions";
import { getByIdContrato, getContratoByName, saveParcela, updateParcela } from "../../api";
import { CurrencyInput } from "../../components/FormInputs/CurrencyInput/index";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired } from "../../default/messages";
import { brazilianCalendarParams, convertToISO8601 } from "../../utils";
import { AutoCompleteComponent } from "../AutoComplete";
import { ButtonComponent } from "../ButtonComponent";
import { Header } from "../Header";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import "./styles.css";

export const EditarParcela = ({ propsParcela, idContratoParcela, page }) => {
  const editParcela = propsParcela?.editParcela;
  const idContratoProps = propsParcela?.idContrato;
  const toast = useRef(null);
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  const history = useHistory();
  const [dialogSalvar, setDialogSalvar] = useState(false);
  const [id, setId] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [descContrato, setDescContrato] = useState(null);
  const [numParcela, setNumParcela] = useState(null);
  const [valorBaixa, setValorBaixa] = useState(null);
  const [status, setStatus] = useState("EM ABERTO");
  const [dataBaixa, setDataBaixa] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [dataVencPrevisto, setDataVencPrevisto] = useState(null);
  const [valorParcela, setValorParcela] = useState(null);
  const [idContrato, setIdContrato] = useState(null);
  const [contratoInput, setContratoInput] = useState(null);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const [tipoVencimento, setTipoVencimento] = useState("POSTERIOR");

  const valueDropContrato = useSelector(
    (state) => state.filtroContratoDrop.nomeContrato
  );

  const [listaContratoDrop, setListaContratoDrop] = useState([]);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);

  const optionsStatus = [
    { name: "Aberta", value: "EM ABERTO" },
    { name: "Provisionada", value: "PROVISIONADO" },
    { name: "Conciliada", value: "CONCILIADO" },
  ];

  const selectDiaUtilComportamento = [
    { name: "Dia útil anterior", value: "ANTERIOR" },
    { name: "Dia útil posterior", value: "POSTERIOR" },
  ];

  addLocale("pt-br", brazilianCalendarParams);
  const validationPost = yup
    .object({
      // nomeContrato: yup.string().required(messageRequired),
      descricaoParcela: yup.string().trim().required(messageRequired),
      numeroParcela: yup.string().required(messageRequired),
      valorParcela: yup.string().required(messageRequired),
      // statusParcela: yup.string().required(messageRequired),
      dataEmissao: yup.string().required(messageRequired),
      dataVencimentoPrevisto: yup.string().required(messageRequired),
      valorBaixa: (dataBaixa !== undefined && dataBaixa !== null) && (valorBaixa === undefined || valorBaixa === null) ? yup.number().required(messageRequired) : null,
      dataBaixa: (valorBaixa !== undefined && valorBaixa !== null) && (dataBaixa === undefined || dataBaixa === null) ? yup.number().required(messageRequired) : null,
      tipoVencimento: yup.string().required(messageRequired),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  const  getByIdContract = useCallback(async() => {
    if (idContratoParcela) {
      await getByIdContrato(idContratoParcela).then((resultado) => {
        setContratoInput(resultado?.nomeContrato)
        setIdContrato(resultado?.id)
      })
    }
  },[idContratoParcela])

  useEffect(() => {
    setValue("descricaoParcela", descricao);
    setValue("nomeContrato",  descContrato);
    setValue("numeroParcela", numParcela);
    setValue("valorParcela", valorParcela);
    setValue("statusParcela", status);
    setValue("dataEmissao", dataEmissao);
    setValue("dataVencimentoPrevisto", dataVencPrevisto);
    setValue('tipoVencimento', tipoVencimento)
  }, [
    descricao,
    descContrato,
    numParcela,
    valorParcela,
    status,
    dataEmissao,
    dataVencPrevisto,
    tipoVencimento,
    getByIdContract,
    setValue
  ]);

  useEffect(() => {
    callFilterEndpoints(
      setListaContratoDrop,
      getContratoByName,
      valueDropContrato,
      "Contrato"
    );
  }, [valueDropContrato]);

  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param !== null && endpoint !== undefined) {
      await endpoint(param.nomeFantasia ? param.nomeFantasia : param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não encontrado. Tente novamente.`,
            });
          } else {
            setState(resultado);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getByIdContract();
  }, [getByIdContract])

  useEffect(() => {
    if (
      editParcela !== undefined &&
      editParcela !== null &&
      editParcela !== ""
    ) {
      setId(editParcela.id);
      setDescricao(editParcela.descricaoParcela);
      setDescContrato(editParcela.nomeContrato);
      setNumParcela(editParcela.numeroParcela);
      setValorParcela(editParcela.valorParcela);
      setDataEmissao(
        editParcela.dataEmissao ? handleYearIsNAN(editParcela.dataEmissao) : null
      );
      setDataVencPrevisto(
        editParcela.dataVencimentoPrevisto
          ? handleYearIsNAN(editParcela.dataVencimentoPrevisto)
          : null
      );
      setDataBaixa(
        editParcela.dataBaixa ? handleYearIsNAN(editParcela.dataBaixa) : null
      );
      setValorBaixa(editParcela.valorBaixa);
      setStatus(editParcela.statusParcela === "ABERTO" ? "EM ABERTO" : editParcela.statusParcela);
      setIdContrato(editParcela.idContrato);
      setContratoInput(editParcela.contrato);

      setValue("descricaoParcela", editParcela.descricaoParcela);
      setValue("nomeContrato", editParcela.contrato?.nomeContrato);
      setValue("numeroParcela", editParcela.numeroParcela);
      setValue("valorParcela", editParcela.valorParcela);
      setValue("statusParcela", editParcela.statusParcela);
      setValue("dataEmissao", editParcela.dataEmissao);
      setValue("dataVencimentoPrevisto", editParcela.dataVencimentoPrevisto);
    }
  }, [editParcela, setValue]);

  function handleYearIsNAN(date) {
    let dateFormat = date + "T03:00:00Z"
    return date && new Date(dateFormat);
  }

  const userId = localStorage.getItem("loggerUser");
  const user = JSON.parse(userId);

  const parcela = {
    userId: user?.userId,
    dataBaixa: convertToISO8601(dataBaixa),
    dataEmissao: dataEmissao,
    dataVencimentoPrevisto: dataVencPrevisto,
    descricaoParcela: descricao,
    id: id,
    idContrato: contratoInput?.id ? contratoInput?.id : idContrato,
    numeroParcela: numParcela,
    statusParcela: status,
    valorBaixa: valorBaixa,
    valorParcela: valorParcela,
    tipoVencimento: tipoVencimento
  };

  const closeModal = () => {
    validationPost.isValid(parcela).then((response) => {
      setDialogSalvar(false);
    });
  };

  async function saveParcelaForm() {
    setCarregandoRequisicao(true);
    try {
      if (!editParcela) {
        await saveParcela(parcela);
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Parcela salva com sucesso!",
        });
      } else {
        parcela.id = editParcela.id;
        await updateParcela(parcela, editParcela.id);
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Parcela alterada com sucesso!",
        });
      }
      setDialogSalvar(false);
      dispatch(setFiltroParcela(reduxStateInicial.filtroParcela));
      setTimeout(() => {
        history.push(`/contrato/${parcela.idContrato}/parcelas`, { idContrato: parcela.idContrato });
      }, 1500);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: error.response?.data?.detail,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  const titleItem = (
    <div>
      {editParcela?.statusParcela === "CONCILIADO" && (
        <span className="reconciled">
          {" "}
          [CONCILIADO]
          <ButtonComponent
            datatesteid={"headerInfo"}
            type={"info"}
            tooltip="Essa parcela não pode ser alterada, pois está vinculada a uma movimentação conciliada. Caso queira fazer alguma modificação, é necessário desconciliar o lançamento."
          />
        </span>
      )}
    </div>
  );

  function titleModal() {
    if (
      propsParcela?.editParcela?.statusParcela === "CONCILIADO" ||
      propsParcela?.editParcela?.statusParcela === "PROVISIONADO"
    ) {
      return "Contrato e de Movimentação Atrelada";
    } else {
      return "Parcela";
    }
  }
  useEffect(() => {
    setValue("nomeContrato", contratoInput?.id);
  }, [contratoInput, setValue]);


  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true)
    } else {
      // history.push(`/contrato/${idContratoProps}/parcelas`, idContratoProps)
      history.push(`/contrato/${idContratoParcela || idContratoProps}/parcelas`, { page: page, idContrato: idContratoParcela || idContratoProps })
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  const handleMessage = () => {
    if (valorBaixa !== valorParcela) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Valor da Baixa está divergente do Total Líquido",
      });
    }
  };

  return (
    <>
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />

        <Header
          title={"Parcelas"}
          onClick={editParcela ? "edit" : "add"}
          titleItem={editParcela && titleItem}
        />
        <ModalComponents
          editOrRegister={editParcela}
          visible={dialogSalvar}
          onHide={() => setDialogSalvar(false)}
          title={titleModal()}
          onClick="confirm"
          onClickCancelar={() => setDialogSalvar(false)}
          handleSubmit={handleSubmit}
          onClickConfirmarForm={() => saveParcelaForm()}
          validationForm={closeModal}
          datatesteid={"cancelarModalID"}
          datatesteidconfirm={"confirmarModal"}
        />

        <div className="parcela__wrapper">
          <div className="parcela__container">
            <form className="parcelaForm">
              <div className="parcelaForm__section">
                <div className="parcelaForm__field">
                  <label>
                    Descrição da Parcela{" "}
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputText
                    {...register("descricaoParcela")}
                    field="descricao"
                    value={descricao}
                    onChange={(e) => {
                      setAlteracoes(true)
                      setDescricao(e.target.value)
                    }}
                    maxlength="200"
                    data-testid={"descricaoID"}
                  />
                  <small className="form__errorMsg">
                    {errors.descricaoParcela?.message}
                  </small>
                </div>
                <div className="parcelaForm__field">
                  <label>
                    Descrição do Contrato{" "}
                    {/* <abbr className="form__asterisk">*</abbr> */}
                  </label>
                  <AutoCompleteComponent
                    setValue={setContratoInput}
                    value={contratoInput}
                    filter={setFiltroContratoDrop}
                    genericFunction={() => setAlteracoes(true)}
                    options={listaContratoDrop}
                    optionLabel="nomeContrato"
                    attribute="nomeContrato"
                    // errorMessage={errors.nomeContrato?.message}
                    disabled
                  />
                </div>
                <div className="parcelaForm__field">
                  <label>
                    Número da Parcela<abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputText
                    {...register("numeroParcela")}
                    field="numParcela"
                    value={numParcela}
                    onChange={(e) => {
                      setAlteracoes(true)
                      setNumParcela(e.target.value)
                    }}
                    maxlength="10"
                    keyfilter="pint"
                    data-testid={"numParcelaID"}
                    disabled={editParcela}
                  />
                  <small className="form__errorMsg">
                    {errors.numeroParcela?.message}
                  </small>
                </div>
                <div className="parcelaForm__field">
                  <label>
                    Valor da Parcela<abbr className="form__asterisk">*</abbr>
                  </label>
                  <CurrencyInput
                    datatestid="valorParcelaID"
                    genericFunction={() => setAlteracoes(true)}
                    setValue={setValorParcela}
                    value={valorParcela}
                  />
                  <small className="form__errorMsg">
                    {errors.valorParcela?.message}
                  </small>
                </div>
              </div>
              <div className="parcelaForm__section">
                <div className="parcelaForm__field">
                  <label>
                    Data Prevista de Vencimento
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <Calendar
                    {...register("dataVencimentoPrevisto")}
                    mask="99/99/9999"
                    name="dataVencPrevisto"
                    dateFormat={"dd/mm/yy"}
                    showIcon
                    value={dataVencPrevisto}
                    onChange={(e) => {
                      setDataVencPrevisto(e.value);
                      setValue("dataVencimentoPrevisto", e.value);
                      setAlteracoes(true);
                    }}
                    field="dataVencPrevisto"
                    locale="pt-br"
                    data-testid={"dataVencimentoPrevistoID"}
                  />
                  <small className="form__errorMsg">
                    {errors.dataVencimentoPrevisto?.message}
                  </small>
                </div>
                <div className="parcelaForm__field parcelaForm__selectButton">
                  <label htmlFor="banco">
                    Quando o vencimento coincidir com dia não útil, lançar no:{" "}
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <SelectButton
                    {...register("tipoVencimento")}
                    className="filter-select"
                    options={selectDiaUtilComportamento}
                    value={tipoVencimento}
                    onChange={(e) => {
                      setTipoVencimento(e.target.value)
                      setValue('tipoVencimento', e.target.value)
                    }}
                    optionLabel="name"
                    optionValue="value"
                    // disabled={numeroParcelas === null}
                    data-testid="selectDiaUtilTest"
                  />
                  <small className="form__errorMsg">
                    {errors.tipoVencimento?.message}
                  </small>
                </div>
                <div className="parcelaForm__field">
                  <label>
                    Data de Emissão Prevista <abbr className="form__asterisk">*</abbr>{" "}
                  </label>
                  <Calendar
                    {...register("dataEmissao")}
                    mask="99/99/9999"
                    name="dataEmissao"
                    dateFormat={"dd/mm/yy"}
                    showIcon
                    value={dataEmissao}
                    onChange={(e) => {
                      setDataEmissao(e.value);
                      setValue("dataEmissao", e.value);
                      setAlteracoes(true);
                    }}
                    field="dataEmissao"
                    locale="pt-br"
                    data-testid={"dataEmissaoID"}
                  />
                  <small className="form__errorMsg">
                    {errors.dataEmissao?.message}
                  </small>
                </div>
              </div>
              <div className="parcelaForm__section">
                <div className="parcelaForm__field parcelaForm__selectButton">
                  <label>
                    Status
                    {/* <abbr className="form__asterisk">*</abbr> */}
                  </label>
                  <SelectButton
                    // {...register("statusParcela")}
                    className="filter-select"
                    options={optionsStatus}
                    value={status}
                    onChange={(e) => {
                        !editParcela && setStatus(e.value);
                        setValue("statusParcela", e.value);
                        setAlteracoes(true);
                    }}
                    optionLabel="name"
                    optionValue="value"
                    data-testid={"statusID"}
                    disabled
                  />
                  {/* <small className="form__errorMsg">
                    {errors.statusParcela?.message}
                  </small> */}
                </div>
                <div className="parcelaForm__field">
                  <label>Valor da Baixa {dataBaixa && <small className="form__asterisk">*</small>}</label>
                  <CurrencyInput
                    {...register("valorBaixa")}
                    genericFunction={() => setAlteracoes(true)}
                    datatestid="valorBaixaID"
                    setValue={setValorBaixa}
                    value={valorBaixa}
                    onBlur={handleMessage}
                    disabled={editParcela?.statusParcela === "EM ABERTO"}
                  />
                  <small className="form__errorMsg">
                    {errors.valorBaixa?.message}
                  </small>
                </div>
                <div className="parcelaForm__field">
                  <label>Data de Baixa {valorBaixa && <small className="form__asterisk">*</small>}</label>
                  <Calendar
                    {...register("dataBaixa")}
                    mask="99/99/9999"
                    name="dataBaixa"
                    dateFormat={"dd/mm/yy"}
                    showIcon
                    value={dataBaixa}
                    onChange={(e) => {
                      setDataBaixa(e.value)
                      setAlteracoes(true)
                    }}
                    disabledDays={[0, 6]}
                    field="dataBaixa"
                    locale="pt-br"
                    data-testid={"dataBaixaID"}
                    disabled={editParcela?.statusParcela === "EM ABERTO"}
                  />
                  <small className="form__errorMsg">
                    {errors.dataBaixa?.message}
                  </small>
                </div>
                {/* <div className="parcelaForm__field">
                  <label>
                    Atualizar Movimentação{" "}
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <Dropdown
                    optionLabel="name"
                    value={atualizaMov}
                    onChange={(e) => setAtualizaMov(e.value)}
                    options={optionsAtualizarMov}
                    // optionValue="value"
                    // editable
                    data-testid={"atualizaMovID"}
                  />
                </div> */}
              </div>
            </form>
            <ModalComponents
              visible={alteracoesFeitas}
              onHide={() => setAlteracoesFeitas(false)}
              onClickCancelar={() => setAlteracoesFeitas(false)}
              onClickConfirmar={() => {
                // history.push(`/contrato/${idContratoProps}/parcelas`, idContratoProps)
                history.push(`/contrato/${idContratoParcela || idContratoProps}/parcelas`, { page: page, idContrato: idContratoParcela || idContratoProps })
              }}
              onClick="warning"
              descricao={modalWarning}
            />
            <div className="parcela__containerButtons">
              <ButtonComponent
                data-testid={"cancelarBottonID"}
                type="cancel"
                onClick={() => validateGoBack()}
              />
              <ButtonComponent
                data-testid="confirmarBottonID"
                type="confirm"
                onClick={() => setDialogSalvar(true)}
                disabled={
                  editParcela?.statusParcela === "CONCILIADO"
                    ? editParcela.statusParcela
                    : null
                }
              />
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};
