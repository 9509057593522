import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroProdServico } from "../../actions";
import { paginationDefault } from "../../default/pagination";
import { Filtro } from "../Filtro";

export const FiltroProdutoServico = ({
  filtroProdServ,
  setFiltroProdServ,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();
  const [nome, setNome] = useState(
    useSelector((state) => state.filtroProd.nome)
  );
  const [descricao, setDescricao] = useState(
    useSelector((state) => state.filtroProd.descricao)
  );
  const [status, setStatus] = useState(
    useSelector((state) => state.filtroProd.status)
  );
  const [id, setId] = useState(useSelector((state) => state.filtroProd.id));
  const [descricaoPai, setDescricaoPai] = useState(
    useSelector((state) => state.filtroProd.produtoPai.descricao)
  );

  const optionsStatus = [
    { name: "Ambos", value: null },
    { name: "Ativo", value: "ATIVA" },
    { name: "Inativo", value: "INATIVA" },
  ];

  const filterFields = [
    {
      name: "status",
      label: "Status:",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: optionsStatus,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      name: "id",
      label: "Código:",
      value: id,
      setState: setId,
      dataType: "pint",
      datatesteid: "idTest",
    },
    {
      name: "produtoPai.descricao",
      label: "Descrição Pai:",
      value: descricaoPai,
      setState: setDescricaoPai,
      datatesteid: "descricaoPaiId",
    },
    {
      name: "nome",
      label: "Nome:",
      value: nome,
      setState: setNome,
      datatesteid: "nomeInput",
    },
    {
      name: "descricao",
      label: "Descrição:",
      value: descricao,
      setState: setDescricao,
      datatesteid: "descricaoInput",
    },
  ];

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    setFiltroProdServ(false);
    dispatch(
      setFiltroProdServico({
        id: id !== '' ? id : null,
        nome: nome !== '' ? nome : null,
        descricao: descricao !== '' ? descricao : null,
        produtoPai: {
          descricao: descricaoPai !== '' ? descricaoPai : null,
        },
        status: status !== '' ? status : null,
      })
    );
    setVisible(() => true)
  };

  const limpar = useCallback(() => {
    setId("");
    setDescricaoPai("");
    setNome("");
    setDescricao("");
    setStatus(null);
    setVisible(() => false)
    setClearInputs(false)
  }, [setClearInputs, setVisible]);

  useEffect(() => {
    if (clear === true) {
      limpar()
    }
  }, [clear, limpar])

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <Filtro
      filterFields={filterFields}
      setIsFiltroVisible={setFiltroProdServ}
      isFiltroVisible={filtroProdServ}
      clearFilter={limpar}
      handleConfirmFilter={filtrar}
    />
  );
};
