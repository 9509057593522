import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./styles.css";

export const TelaLogin = () => {
  const toast = useRef(null);
  const { signIn } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  // const usuarios = [
  //   {
  //     email: "marcelo.carius@neki-it.com.br",
  //     senha: "neki123@marcelo",
  //   },
  //   {
  //     email: "marcelocruz@tann.com.br",
  //     senha: "#m4rc3l0cr1z@",
  //   },
  //   {
  //     email: "geovan.oliveira@neki-it.com.br",
  //     senha: "neki123@geovan",
  //   },
  //   {
  //     email: "marcelo.pinto@neki-it.com.br",
  //     senha: "neki123@pinto",
  //   },
  //   {
  //     email: "ricardo.alves@neki-it.com.br",
  //     senha: "neki123@ricardo",
  //   },
  //   {
  //     email: "marcelo.assis@neki-it.com.br",
  //     senha: "neki123@assis",
  //   },
  //   {
  //     email: "raiane.peixoto@neki-it.com.br",
  //     senha: "neki123@raiane",
  //   },
  //   {
  //     email: "fabianopereira@tann.com.br",
  //     senha: "tann123@fabiano",
  //   },
  //   {
  //     email: "veramedeiros@tann.com.br",
  //     senha: "tann123@vera",
  //   },
  //   {
  //     email: "cristianiruivo@tann.com.br",
  //     senha: "tann123@cristian",
  //   },
  //   {
  //     email: "admin",
  //     senha: "admin",
  //   },
  // ];
  const history = useHistory();

  const handleSignIn = () => {
    try {
      signIn(email, senha);
      history.push("/home");
    } catch (error) {
      console.error(error);
    }
  };

  // function realizarLogin() {
  //   const usuario = usuarios.filter((usuario) => usuario.email === email)[0];

  //   if (!usuarios.some((usuario) => usuario.email === email)) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Erro",
  //       detail: "Usuario ou senha invalidas",
  //     });
  //     return;
  //   }

  //   if (usuario.senha !== senha) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Erro",
  //       detail: "Usuario ou senha invalidas",
  //     });
  //     return;
  //   }
  //   history.push("/FluxoDeCaixa");
  // }

  return (
    <div className="login__background">
      {/* <MenuBurguer/> */}
      <div className="login__centralize">
        <Toast ref={toast} />
        <img className="login__logo" src="logo.png" alt="logo" />
        <h1 className="login__text">Login</h1>
        <form onSubmit={handleSignIn}>
          <div className="login__inputs">
            <InputText
              className="login__inputs--text"
              name="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Digite seu e-mail..."
              data-testid="emailID"
            />
            <Password
              inputId="password"
              className="login__inputs--password"
              onChange={(e) => setSenha(e.target.value)}
              value={senha}
              feedback={false}
              placeholder="Digite sua senha..."
              data-testid="senhaID"
            />
          </div>
          <div>
            <Button
              className="login__input--enviar"
              label="Entrar"
              type="submit"
              data-testid="entrarID"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
