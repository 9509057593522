export const setContaCorrente = (contaCorrente) => ({
    type: "SET_CONTA_CORRENTE",
    payload: contaCorrente,
});

export const setConta = (conta) => ({
    type: "SET_CONTA",
    payload: conta,
});

export const setFiltroCC = (filtroCC) => ({
    type: "SET_FILTRO_CONTA_CORRENTE",
    payload: filtroCC,
});

export const addListaContasCorrentes = (lista) => ({
    type: "ADD_LISTA_CONTAS_CORRENTES",
    payload: lista,
});

export const exibirLoadingCC = (loading) => ({
    type: "EXIBIR_LOADING_CC",
    payload: loading,
});

export const esconderLoadingCC = (loading) => ({
    type: "ESCONDER_LOADING_CC",
    payload: loading,
});

export const setFiltroNumeroBancoDrop = (numeroBanco) => ({
    type: "SET_FILTRO_NUMERO_BANCO_DROP",
    payload: numeroBanco,
});

export const setFiltroNumeroContaCorrenteDrop = (numeroContaCorrente) => ({
    type: "SET_FILTRO_NUMERO_CONTA_CORRENTE_DROP",
    payload: numeroContaCorrente,
});

export const setFiltroDescricaoContaDrop = (descricao) => ({
    type: "SET_FILTRO_DESCRICAO_CONTA_DROP",
    payload: descricao,
});

export const setFiltroDescricaoContaCorrenteDrop = (descricaoContaCorrente) => ({
    type: "SET_FILTRO_DESCRICAO_CONTA_CORRENTE_DROP",
    payload: descricaoContaCorrente,
});

export const exibirCCSalva = (alertaSalvo) => ({
    type: "EXIBIR_CC_SALVA",
    payload: alertaSalvo,
});

export const esconderCCSalva = (alertaSalvo) => ({
    type: "ESCONDER_CC_SALVA",
    payload: alertaSalvo,
});

export const addListaContas = (listaContas) => ({
    type: "ADD_LISTA_CONTAS",
    payload: listaContas,
});

export const setSaldoInicial = (saldo) => ({
    type: "SET_SALDO_INICIAL",
    payload: saldo,
});

export const setProximoSaldoNegativo = (proximoSaldoNegativo) => ({
    type: "SET_PROXIMO_SALDO_NEGATIVO",
    payload: proximoSaldoNegativo,
});

export const setNumeroBanco = (numeroBanco) => ({
    type: "SET_NUMERO_BANCO",
    payload: numeroBanco,
});

export const setFiltroContaCorrenteDrop = (contaCorrente) => ({
    type: "SET_FILTRO_CONTA_CORRENTE_DROP",
    payload: contaCorrente,
});

export const setFiltroContaCorrenteBancoDrop = (banco) => ({
    type: "SET_FILTRO_CONTA_CORRENTE_BANCO_DROP",
    payload: banco,
});