export const setFiltroParceiroDrop = (parceiro) => ({
    type: "SET_FILTRO_PARCEIRO_DROP",
    payload: parceiro,
});

export const setParceiro = (parceiro) => ({
    type: "SET_PARCEIRO",
    payload: parceiro,
});

export const setParceiroLista = (parceiro) => ({
    type: "SET_PARCEIRO_LISTA",
    payload: parceiro,
});

export const setIdParceiro = (idParceiro) => ({
    type: "SET_PARCEIRO",
    payload: idParceiro,
});

export const setFiltroParceiro = (filtroParceiro) => ({
    type: "SET_FILTRO_PARCEIRO",
    payload: filtroParceiro,
});

export const addListaParceiro = (parceiro) => ({
    type: "ADD_LISTA_PARCEIROS",
    payload: parceiro,
});
