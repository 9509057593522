const INITIAL_STATE = {
    idBanco: null,
};

export const bancoContaCorrenteFiltroDrop = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case "SET_FILTRO_CONTA_CORRENTE_BANCO_DROP":
            return { ...action.payload };
        default:
            return state;
    }
};