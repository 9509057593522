import { InputText } from "primereact/inputtext";
import React from "react";
import "./styles.css";

export const Input = ({
  value,
  required,
  label,
  error,
  errorMessage,
  name,
  maxLength,
  disabled,
  type,
  max,
  placeholder,
  prefix,
  min,
  onChange,
  register,
  datatesteid,
  keyfilter
}) => {
  return (
    <div className="input__container">
      <label>
        {label} {required && <abbr className="form__asterisk">*</abbr>}
      </label>
      <InputText
        {...register}
        max={max}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        // style={{ maxWidth: w || 250 }}
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        prefix={prefix}
        min={min}
        style={{ width: "100%" }}
        data-testid={datatesteid}
        keyfilter={keyfilter}
      />
      <small className="form__errorMsg">{errorMessage}</small>
    </div>
  );
};
