import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import "./style.css";

function Select({
  label,
  options,
  value,
  setState,
  placeholder,
  optionLabel,
  optionValue,
  required,
  errorMessage,
  datatesteid,
  filter,
  attribute,
  onClickDelete,
  onClickFunction,
  setAlteracoes = () => {}
}) {
  const dispatch = useDispatch();

  function filterBy(prop) {
    dispatch(
      filter({
        [attribute]: prop?.originalEvent?.target?.value,
      })
    );
  }

  return (
    <div key={label} className="p-fluid select__form">
      <div>
        <label>{label}</label>
        {required && (
          <abbr data-testid="abbr-asteristic" className="form__asterisk">
            *
          </abbr>
        )}
      </div>
      <div className="select">
        <AutoComplete
          validacao
          value={value}
          suggestions={options}
          completeMethod={filterBy}
          field={optionLabel}
          onChange={(e) => {
            setState(e?.value[optionValue] || e?.value)
            setAlteracoes(true)
          }}
          className="select__dropdown"
          aria-label={label}
          minLength="3"
          tooltip="Digite pelo menos 3 (três) caracteres."
          data-testid={datatesteid}
          placeholder={placeholder}
          tooltipOptions={{ event: "hover", position: "left" }}
          delay={800}
        />
        {onClickDelete && (
          <Button
            className="select__buttonDelete"
            icon="pi pi-times"
            tooltip="Excluir"
            tooltipOptions={{ position: "left" }}
            data-testid="deleteTest"
            onClick={() => onClickFunction()}
          />
        )}
      </div>
      <small className="form__errorMsg">{errorMessage}</small>
    </div>
  );
}

export default memo(Select);
