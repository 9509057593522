const INITIAL_STATE = {
    listaEmpresa: null
};

export const listaEmpresa = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_LISTA_EMPRESA':
            return { listaEmpresa: action.payload };
        default:
            return state
    }
}
