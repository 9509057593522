import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import "./styles.css";

export const ModalDescricaoDeCores = ({
  datatesteId,
  visible,
  setState,
  arrayDescricao,
  title
}) => {
  return (
    <>
      <Dialog
        header={title}
        className="modalDescription"
        visible={visible}
        onHide={() => setState(false)}
        data-testid={datatesteId}
      >
        <div className={"modal__wrapper"}>
          {arrayDescricao.map((item) => (
            <div key={item.cor} className="status">
              <div className="status__wrapper">
                {item?.icon &&
                  <Button icon={item?.icon} className={item?.className} />
                }
                {item?.description &&
                  <h6 className={item?.descriptionClass}>{item?.description}</h6>
                }
                <div
                  style={{ background: item.cor }}
                  className="status__square"
                />
                <h6>{item.nome} :</h6>
              </div>
              <p>{item.descricao}</p>
            </div>
          ))}
        </div>
      </Dialog>
    </>
  );
};
