import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroEmpresa } from "../../actions";
import { getGrupoEconomicoByNomeFantasia } from "../../api";
import { paginationDefault } from "../../default/pagination";
import { Filtro } from "../Filtro";

export const FiltroEmpresa = ({
  setAbrirFiltroEmpresa,
  abrirFiltroEmpresa,
  clear,
  setVisible,
  setLazyParamsProp,
  reduxStateAtual,
  reduxStateInicial,
  clearInputs,
  setClearInputs
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [razaoSocial, setRazaoSocial] = useState(
    useSelector((state) => state.filtroEmpresa.razaoSocial)
  );
  const [nomeFantasia, setNomeFantasia] = useState(
    useSelector((state) => state.filtroEmpresa.nomeFantasia)
  );
  const [id, setId] = useState(useSelector((state) => state.filtroEmpresa.id));
  const [cnpj, setCnpj] = useState(
    useSelector((state) => state.filtroEmpresa.cnpj)
  );
  const [inscEstadual, setInscEstadual] = useState(
    useSelector((state) => state.filtroEmpresa.inscEstadual)
  );
  const [inscMunicipal, setInscMunicipal] = useState(
    useSelector((state) => state.filtroEmpresa.inscMunicipal)
  );
  const [emailNotaFiscal, setEmailNotaFiscal] = useState(
    useSelector((state) => state.filtroEmpresa.emailNotaFiscal)
  );
  const [status, setStatus] = useState(
    useSelector((state) => state.filtroEmpresa.status)
  );

  const [nomeFantasiaGrupoEconomico, setNomeFantasiaGrupoEconomico] = useState(
    useSelector((state) => state.filtroEmpresa.nomeFantasiaGrupoEconomico)
  );

  const [listaGrupoEconomico, setListaGrupoEconomico] = useState(null);

  function getGrupoEconomico(searchBy) {
    getGrupoEconomicoByNomeFantasia(searchBy)
      .then((response) => {
        setListaGrupoEconomico(response);
        if (response.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: `Grupo Econômico não encontrado.`,
          });
        }
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Erro ao buscar Grupo Econômico",
          detail: `Houve um problema inesperado e a busca falhou, tente novamente mais tarde`,
        });
      });
  }

  const selectStatus = [
    { name: "Ambos", value: "TODOS" },
    { name: "Ativa", value: "ATIVO" },
    { name: "Inativa", value: "INATIVO" },
  ];

  const filterFields = [
    {
      name: "status",
      label: "Status:",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: selectStatus,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      name: "id",
      label: "Código:",
      value: id,
      setState: setId,
      dataType: "pint",
      datatesteid: "campoId",
    },
    {
      name: "nomeFantasia",
      label: "Nome Fantasia:",
      value: nomeFantasia,
      setState: setNomeFantasia,
      datatesteid: "nomeFantasiaID",
    },
    {
      name: "razaoSocial",
      label: "Razão Social:",
      value: razaoSocial,
      setState: setRazaoSocial,
      datatesteid: "razaoSocialID",
    },
    {
      name: "grupoEconomico",
      label: "Grupo Econômico:",
      value: nomeFantasiaGrupoEconomico,
      setValue: setNomeFantasiaGrupoEconomico,
      type: "autoCompletar",
      options: listaGrupoEconomico,
      optionLabel: "nomeFantasia",
      datatesteid: "grupoEconomicoID",
      completeMethod: getGrupoEconomico,
    },
    {
      name: "emailNotaFiscal",
      label: "E-mail para envio da Nota Fiscal:",
      value: emailNotaFiscal,
      setState: setEmailNotaFiscal,
      datatesteid: "emailNotaFiscalID",
    },
    {
      name: "cnpj",
      label: "CNPJ:",
      value: cnpj,
      setState: setCnpj,
      type: "inputMaskCpfCnpj",
      state: "J",
      datatesteid: "cnpjID",
    },
    {
      name: "inscricaoEstadual",
      label: "Inscrição Estadual:",
      value: inscEstadual,
      setState: setInscEstadual,
      datatesteid: "inscEstadualID",
    },
    {
      name: "inscricaoMunicipal",
      label: "Inscrição Municipal:",
      value: inscMunicipal,
      setState: setInscMunicipal,
      datatesteid: "inscMunicipalID",
    },
  ];

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroEmpresa({
        id: id !== "" ? id : null,
        nomeFantasia: nomeFantasia !== "" ? nomeFantasia : null,
        razaoSocial: razaoSocial !== "" ? razaoSocial : null,
        cnpj: cnpj !== "" ? cnpj : null,
        inscEstadual: inscEstadual !== "" ? inscEstadual : null,
        inscMunicipal: inscMunicipal !== "" ? inscMunicipal : null,
        emailNotaFiscal: emailNotaFiscal !== "" ? emailNotaFiscal : null,
        status,
        grupoEconomicoNomeFantasia: nomeFantasiaGrupoEconomico?.nomeFantasia
          ? nomeFantasiaGrupoEconomico?.nomeFantasia
          : nomeFantasiaGrupoEconomico,
      })
    );
  };

  const limpar = useCallback(() => {
    setRazaoSocial("");
    setNomeFantasia("");
    setId("");
    setCnpj(null);
    setInscEstadual("");
    setInscMunicipal("");
    setEmailNotaFiscal("");
    setStatus("TODOS");
    setNomeFantasiaGrupoEconomico(null);
    setVisible(() => false)
    setClearInputs(false)
  },[setVisible, setClearInputs]);

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  useEffect(() => {
    if (clear === true) {
      limpar()
    }
  }, [clear, limpar])

  return (
    <>
      <Toast ref={toast} />
      <Filtro
        filterFields={filterFields}
        setIsFiltroVisible={setAbrirFiltroEmpresa}
        isFiltroVisible={abrirFiltroEmpresa}
        clearFilter={limpar}
        handleConfirmFilter={filtrar}
      />
    </>
  );
};
