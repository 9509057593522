import { Fieldset } from "primereact/fieldset";
import React from "react";
import { DateInput } from "../DateInput";
import "./styles.css";

export const SetOfDates = ({ label, dates, datatesteid, datatesteidrange }) => {
  return (
    <div key={label} className="setOfDates__form">
      <Fieldset
        legend={label}
        className="setOfDates__fieldset"
        toggleable={true}
        collapsed={true}
        data-testid={datatesteid}
      >
        {dates?.map((item) => {
          return (
            <DateInput
              key={item.label}
              label={item.label}
              value={item.value}
              setState={item.setState}
              endValue={item.endValue}
              setEndState={item.setEndState}
              type={item.type}
              isEmpty={item.isEmpty}
              required={item.required}
              style={item.style}
              rangeOfDate={item.rangeOfDate}
              directionCol={item.directionCol}
              datatesteid={item.datatesteid}
              datatesteidrange={item.datatesteidrange}
            />
          );
        })}
      </Fieldset>
    </div>
  );
};
