import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroCC } from "../../actions";
import {
  getEmpresaByNomeFantasia,
  getParceiroByNomeTratamento,
} from "../../api";
import { paginationDefault } from "../../default/pagination";
import { Filtro } from "../Filtro";

export const FiltroContaCorrente = ({
  isFiltroVisible,
  setIsFiltroVisible,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [descricao, setDescricao] = useState(
    useSelector((state) => state.filtroCC.descricao)
  );
  const [agencia, setAgencia] = useState(
    useSelector((state) => state.filtroCC.agencia)
  );
  const [contaCorrente, setContaCorrente] = useState(
    useSelector((state) => state.filtroCC.contaCorrente)
  );
  const [nomeBanco, setNomeBanco] = useState(
    useSelector((state) => state.filtroCC.nomeBanco)
  );
  const [idBanco, setIdBanco] = useState(
    useSelector((state) => state.filtroCC.idBanco)
  );
  const [ativa, setAtiva] = useState(
    useSelector((state) => state.filtroCC.ativa)
  );
  const [nomeFantasiaEmpresa, setNomeFantasiaEmpresa] = useState(null);
  const [nomeTratamentoParceiro, setNomeTratamentoParceiro] = useState(null);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [listaParceiro, setListaParceiro] = useState([]);

  function getListaEmpresa(nomeFantasia) {
    getListWith({
      endpoint: getEmpresaByNomeFantasia,
      searchBy: nomeFantasia,
      setState: setListaEmpresa,
      notFoundMessage: "Empresa não encontrada",
      errorMessageFrom: "empresa",
    });
  }

  function getListaParceiros(nomeTratamento) {
    getListWith({
      endpoint: getParceiroByNomeTratamento,
      searchBy: nomeTratamento,
      setState: setListaParceiro,
      notFoundMessage: "Parceiro não encontrado",
      errorMessageFrom: "parceiro",
    });
  }

  function getListWith({
    endpoint,
    searchBy,
    setState,
    notFoundMessage,
    errorMessageFrom,
  }) {
    endpoint(searchBy)
      .then((response) => {
        setState(response);
        if (response.length === 0) {
          searchNotFound(notFoundMessage);
        }
      })
      .catch(() => searchHandlerError(errorMessageFrom));
  }

  function searchNotFound(detail) {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: detail,
    });
  }

  function searchHandlerError(name) {
    toast.current.show({
      severity: "error",
      summary: `Erro ao buscar por ${name}`,
      detail: `Houve um problema inesperado e a busca falhou, tente novamente mais tarde`,
    });
  }

  const filterFields = [
    {
      name: "ativa",
      label: "Status:",
      value: ativa,
      setState: setAtiva,
      type: "selectButton",
      options: [
        { name: "Ambos", value: null },
        { name: "Ativa", value: "S" },
        { name: "Inativa", value: "N" },
      ],
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "statusTest",
    },
    {
      name: "descricao",
      label: "Descrição:",
      value: descricao,
      setState: setDescricao,
      datatesteid: "descricaoTest",
    },
    {
      label: "Empresa:",
      value: nomeFantasiaEmpresa,
      setValue: setNomeFantasiaEmpresa,
      type: "autoCompletar",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      datatesteid: "nomeFantasiaEmpresaTest",
      completeMethod: getListaEmpresa,
      disabled: nomeTratamentoParceiro !== null,
    },
    {
      name: "nomeTratamentoParceiro",
      label: "Parceiro:",
      value: nomeTratamentoParceiro,
      setValue: setNomeTratamentoParceiro,
      type: "autoCompletar",
      options: listaParceiro,
      optionLabel: "nomeTratamento",
      datatesteid: "nomeTratamentoParceiroTest",
      completeMethod: getListaParceiros,
      disabled: nomeFantasiaEmpresa !== null,
    },
    {
      name: "nomeBanco",
      label: "Nome do Banco:",
      value: nomeBanco,
      setState: setNomeBanco,
      datatesteid: "nomeBancoTest",
    },
    {
      name: "idBanco",
      label: "Código do Banco:",
      value: idBanco,
      setState: setIdBanco,
      dataType: "pint",
      datatesteid: "numeroBancoTest",
    },
    {
      name: "agencia",
      label: "Agência:",
      value: agencia,
      setState: setAgencia,
      datatesteid: "agenciaTest",
    },
    {
      name: "contaCorrente",
      label: "Nº Conta Corrente:",
      value: contaCorrente,
      setState: setContaCorrente,
      datatesteid: "contaCorrenteNumero",
      maxLength: 40,
    },
  ];

  useEffect(() => {
    if (nomeFantasiaEmpresa?.length === 0) {
      setNomeFantasiaEmpresa(null);
    }
  }, [nomeFantasiaEmpresa]);

  useEffect(() => {
    if (nomeTratamentoParceiro?.length === 0) {
      setNomeTratamentoParceiro(null);
    }
  }, [nomeTratamentoParceiro]);

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault);
    dispatch(
      setFiltroCC({
        agencia: agencia !== "" ? agencia : null,
        ativa,
        contaCorrente: contaCorrente !== "" ? contaCorrente : null,
        descricao: descricao ? descricao : null,
        nomeBanco,
        nomeFantasiaEmpresa: nomeFantasiaEmpresa?.nomeFantasia
          ? nomeFantasiaEmpresa?.nomeFantasia
          : nomeFantasiaEmpresa,
        nomeTratamentoParceiro: nomeTratamentoParceiro?.nomeTratamento
          ? nomeTratamentoParceiro?.nomeTratamento
          : nomeTratamentoParceiro,
        idBanco: idBanco !== "" ? idBanco : null,
      })
    );
    setVisible(() => true);
  };

  const limpar = useCallback(() => {
    setDescricao("");
    setAgencia("");
    setContaCorrente("");
    setNomeBanco("");
    setIdBanco("");
    setNomeFantasiaEmpresa(null);
    setNomeTratamentoParceiro(null);
    setAtiva(null);
    setVisible(() => false);
    setClearInputs(false);
  }, [setClearInputs, setVisible]);

  useEffect(() => {
    if (clearInputs === true) {
      limpar();
    }
  }, [clearInputs, limpar]);

  useEffect(() => {
    if (clear === true) {
      limpar();
    }
  }, [clear, limpar]);

  return (
    <>
      <Toast ref={toast} />
      <Filtro
        filterFields={filterFields}
        setIsFiltroVisible={setIsFiltroVisible}
        isFiltroVisible={isFiltroVisible}
        clearFilter={limpar}
        handleConfirmFilter={filtrar}
      />
    </>
  );
};
