import { Button } from "primereact/button";
import React from 'react';
import "./styles.css";

export function formatTypeConciliacao(type) {
    console.log('type', type)
    if (type === 1 || type === 'Crédito') {
        // alert('1')
        return (
            <Button
                className="type__conciliacao"
                tooltip="Receita"
            >
                +
            </Button>
        )
    } else if (type === -1 || type === 'Débito'){
        // alert('2')
        return (
            <Button
                className="type__conciliacao"
                tooltip="Despesa"
            >
                -
            </Button>
        )
    } else {
        // alert('3')
        return ''
    }
}