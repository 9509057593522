import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroGrupoEconomico } from "../../actions";
import { paginationDefault } from "../../default/pagination";
import { Filtro } from "../Filtro";

export const FiltroGrupoEconomico = ({
  abrirFiltroGe,
  setAbrirFiltroGe,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();

  const [razaoSocial, setRazaoSocial] = useState(
    useSelector((state) => state.filtroGE.razaoSocial)
  );
  const [nomeFantasia, setNomeFantasia] = useState(
    useSelector((state) => state.filtroGE.nomeFantasia)
  );
  const [id, setId] = useState(useSelector((state) => state.filtroGE.id));
  const [cnpj, setCnpj] = useState(useSelector((state) => state.filtroGE.cnpj));
  const [inscEstadual, setInscEstadual] = useState(
    useSelector((state) => state.filtroGE.inscEstadual)
  );
  const [inscMunicipal, setInscMunicipal] = useState(
    useSelector((state) => state.filtroGE.inscMunicipal)
  );
  const [emailNotaFiscal, setEmailNotaFiscal] = useState(
    useSelector((state) => state.filtroGE.emailNotaFiscal)
  );
  const [status, setStatus] = useState(
    useSelector((state) => state.filtroGE.status)
  );
  const [filial, setFilial] = useState(
    useSelector((state) => state.filtroGE.filial)
  );

  const optionsStatus = [
    { name: "Ambos", value: "TODOS" },
    { name: "Ativo", value: "ATIVO" },
    { name: "Inativo", value: "INATIVO" },
  ];

  const optionsFilial = [
    { name: "Ambos", value: "TODOS" },
    { name: "Sim", value: "SIM" },
    { name: "Não", value: "NAO" },
  ];
  const filterFields = [
    {
      name: "filial",
      label: "Tem filial:",
      value: filial,
      setState: setFilial,
      type: "selectButton",
      options: optionsFilial,
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "filialTest",
    },
    {
      name: "status",
      label: "Status:",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: optionsStatus,
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "statusTest",
    },
    {
      name: "id",
      label: "Código:",
      value: id,
      setState: setId,
      dataType: "pint",
      datatesteid: "idTest",
    },
    {
      name: "nomeFantasia",
      label: "Nome Fantasia:",
      value: nomeFantasia,
      setState: setNomeFantasia,
      datatesteid: "nomeFantasiaTest",
    },
    {
      name: "razaoSocial",
      label: "Razão Social:",
      value: razaoSocial,
      setState: setRazaoSocial,
      datatesteid: "razaoSocialTest",
    },
    {
      name: "emailNotaFiscal",
      label: "E-mail para envio da Nota Fiscal:",
      value: emailNotaFiscal,
      setState: setEmailNotaFiscal,
      datatesteid: "emailNotaFiscalTest",
    },
    {
      name: "cnpj",
      label: "CNPJ:",
      value: cnpj,
      setState: setCnpj,
      type: "inputMaskCpfCnpj",
      state: "J",
      datatesteid: "cnpjGETest",
    },
    {
      name: "inscricaoEstadual",
      label: "Inscrição Estadual:",
      value: inscEstadual,
      setState: setInscEstadual,
      datatesteid: "inscricaoEstadualTest",
    },
    {
      name: "inscricaoMunicipal",
      label: "Inscrição Municipal:",
      value: inscMunicipal,
      setState: setInscMunicipal,
      datatesteid: "inscricaoMunicipalTest",
    },
  ];

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroGrupoEconomico({
        nomeFantasia: nomeFantasia !== "" ? nomeFantasia : null, //nome fantasia
        razaoSocial: razaoSocial !== "" ? razaoSocial : null,
        id: id !== "" ? id : null,
        cnpj,
        inscEstadual: inscEstadual !== "" ? inscEstadual : null,
        inscMunicipal: inscMunicipal !== "" ? inscMunicipal : null,
        emailNotaFiscal: emailNotaFiscal !== "" ? emailNotaFiscal : null,
        status,
        filial,
      })
    );
  };

  const limpar = useCallback(() => {
    setNomeFantasia("");
    setRazaoSocial("");
    setId("");
    setCnpj("");
    setInscEstadual("");
    setInscMunicipal("");
    setEmailNotaFiscal("");
    setStatus("TODOS");
    setFilial("TODOS");
    setVisible(() => false)
    setClearInputs(false)
  }, [setClearInputs, setVisible]);

  useEffect(() => {
    if (clear === true) {
      limpar()
    }
  }, [clear, limpar])

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <Filtro
      filterFields={filterFields}
      setIsFiltroVisible={setAbrirFiltroGe}
      isFiltroVisible={abrirFiltroGe}
      clearFilter={limpar}
      handleConfirmFilter={filtrar}
    />
  );
};
