export const addMovimentacaoGrupoEconomico = (listaGP) => ({
    type: "ADD_MOVIMENTACAO_GRUPO_ECONOMICO",
    payload: listaGP,
});

export const setFiltroGrupoEconomico = (filtroGE) => ({
    type: "SET_FILTRO_GRUPO_ECONOMICO",
    payload: filtroGE,
});

export const setFiltroGrupoEconomicoDrop = (grupoEconomico) => ({
    type: "SET_FILTRO_GRUPO_ECONOMICO_DROP",
    payload: grupoEconomico,
});
