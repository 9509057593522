import { Button } from "primereact/button";
import React from "react";
import { ButtonComponent } from "../ButtonComponent";
import "../Table/style.css";
import "./styles.css";

export const HeaderComCalculos = ({
    totalCredito,
    totalDebito,
    totalLiquido,
    totalConciliado,
    setVisibleRight,
    onOpen,
    onClickHeaderInfo,
    headerInfoHoverMessage,
    onClickClear,
    visible,
    totalRecords,
    title,
    movimentacoes,
    onClickAdicionar,
    onClickVoltar,
    movimentacoesParam,
    returnedPage,
    labelVoltar,
    iconVoltar,
    onRedirect,
    natureza,
    dataInicialFiltro,
    dataFinalFiltro
}) => {
    return (
        <div className="container__header__calculos">
            <label className="headerConci__title">{title}
                <Button
                    className="table__header--totalRecords"
                    tooltip="Total de registros que satisfazem ao filtro"
                    style={{ fontSize: '24px' }}
                >
                    {totalRecords && `(${totalRecords})`}
                    {/* {totalRecords && totalRecords + ' registros'} */}
                </Button>
                {natureza && dataInicialFiltro && dataFinalFiltro && movimentacoesParam &&
                    <p className="text__info__mov__dfc">Filtro aplicado: {natureza}, de {dataInicialFiltro} até {dataFinalFiltro}</p>
                }
            </label>
           
            <div className="headerConci__container">
                <div className="headerConci__itemsWrapper">
                    <div className="headerConci__item">
                        <label className="headerConci__label">
                            Crédito:
                        </label>
                        <strong>{Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                            totalCredito
                        )}</strong>
                    </div>
                    <div className="headerConci__item">
                        <label className="headerConci__label">
                            Débito:
                        </label>
                        <strong>{Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                            totalDebito
                        )}</strong>
                    </div>
                    <div className="headerConci__item">
                        <label className="headerConci__label">
                            Líquido:
                        </label>
                        <strong>{Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                            totalLiquido
                        )}</strong>
                    </div>
                    <div className="headerConci__item">
                        <label className="headerConci__label">
                            Conciliado:
                        </label>
                        <strong >{Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                            totalConciliado
                        )}</strong>
                    </div>
                    <div className="headerConci__item">
                        {/* <label className="headerConci__label">Saldo Real:</label> */}
                    </div>
                    <div className="headerConci__item">
                        {/* <label className="headerConci__label">Saldo Banco:</label> */}
                    </div>
                </div>
                <div className="headerConci__buttonsWrapper">
                    <ButtonComponent
                        datatesteid={"headerInfo"}
                        onClick={() => onClickHeaderInfo()}
                        type={"info"}
                        tooltip={headerInfoHoverMessage}
                        tooltipOptions={{ position: "left" }}
                    />
                    {onClickVoltar && !returnedPage  && (
                        <Button
                            className="table__header--return table__header--border"
                            label={labelVoltar === null ? "Adicionar" : labelVoltar}
                            icon={iconVoltar === "no-icon" ? null : "pi pi-arrow-left"}
                            id="btn__return__mov"
                            tooltipOptions={{
                                className: "tooltip--width",
                                position: "bottom",
                            }}
                            onClick={onClickVoltar}
                            data-testid="voltarTest"
                        />
                    )}
                    {!movimentacoesParam &&
                        <Button
                            className="headerConci__filterButton table__header--filter table__header--border"
                            data-testid="filtrarID"
                            type="button"
                            label="Filtrar"
                            onClick={() => setVisibleRight(true)}
                            icon="pi pi-filter"
                        />
                    }
                    {movimentacoesParam &&
                        <>
                            <Button
                                className="headerConci__buttonConciliar"
                                data-testid="conciliarID"
                                label="Movimetações"
                                onClick={() => onRedirect()}
                            />
                            </>
                    }
                    {onClickClear && visible && !movimentacoesParam && (
                        <>
                            <Button
                                icon="pi pi-filter-slash"
                                onClick={() => onClickClear()}
                                className="headerConci--clearFilter table__header--border"

                            >
                                Limpar
                            </Button>
                        </>
                    )
                    }
                    {!movimentacoes &&
                        <>
                            <Button
                                className="headerConci__buttonConciliar"
                                data-testid="conciliarID"
                                label="Conciliar"
                                icon="pi pi-check"
                                onClick={() => onOpen("displayBasic")}
                            />
                            <Button
                                className="headerConci__buttonDesconciliar"
                                data-testid="desconciliarID"
                                label="Desconciliar"
                                icon="pi pi-times"
                                onClick={() => onOpen("displayDesconci")}
                            />
                        </>
                    }

                    {onClickAdicionar &&   !movimentacoesParam && (
                        <Button
                            className="table__header--add table__header--border "
                            label="Adicionar"
                            icon="pi pi-plus"
                            onClick={() => onClickAdicionar()}
                            data-testid="adicionarTest"
                        />
                    )}
                </div>
            </div>
            {onClickClear && visible === true && <br></br>}
        </div>
    )
};
