import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const deleteContrato = (id) =>
  axios.delete(`${url}/contratos/${id}`).then((response) => response.status);

export const getAllFilterContrato = (requestParams) =>
  axios
    .post(
      `${url}/contratos/lista-filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const saveContrato = (contratoEntradaDTO) =>
  axios
    .post(`${url}/contratos`, contratoEntradaDTO)
    .then((response) => response.status);

export const updateContrato = (id, contratoEntradaDTO) =>
  axios
    .put(`${url}/contratos/${id}`, contratoEntradaDTO)
    .then((response) => response.status);

export const getByIdContrato = (id) =>
  axios.get(`${url}/contratos/${id}`).then((response) => response.data);

export const getAllContrato = () =>
  axios.get(`${url}/contratos`).then((response) => response.data);

export const getContratoByName = (contrato) =>
  axios
    .get(`${url}/contratos/nome-contrato/${contrato}`)
    .then((response) => response.data);
