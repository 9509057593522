import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import React from "react";
import "./styles.css";

export const InputMaskCpfCnpj = ({
  label,
  value,
  setState,
  required,
  state,
  validacao,
  errorMessage,
  datatesteid,
  maxLength,
  setAlteracoes = () => { }
}) => {
  return (
    <div key={label} className="inputMaskCpfCnpj__form">
      <div>
        <label>{label}</label>
        {required && <abbr className="form__asterisk">*</abbr>}
      </div>

      {state === "F" ? (
        <InputMask
          className="inputMaskCpfCnpj__field"
          maxLength={maxLength}
          mask={"999.999.999-99"}
          value={value}
          onChange={(e) => {
            setState(e.target.value)
            setAlteracoes(true)
          }}
          data-testid={datatesteid}
        />
      ) : state === "J" ? (
        <InputMask
          maxLength={maxLength}
          validacao
          className="inputMaskCpfCnpj__field"
          mask={"99.999.999/9999-99"}
          value={value}
          onChange={(e) => {
            setState(e.target.value)
            setAlteracoes(true)
          }}
          data-testid={datatesteid}
        />
      ) : (
        <InputText
          validacao
          className="inputMaskCpfCnpj__field"
          maxLength={maxLength}
          keyfilter="pint"
          value={value}
          onChange={(e) => {
            setState(e.target.value)
            setAlteracoes(true)
          }}
          data-testid={datatesteid}
        />
      )}

      <small className="form__errorMsg">{errorMessage}</small>
    </div>
  );
};
