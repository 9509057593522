import _ from 'lodash';
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addListaParceiro,
  esconderLoading,
  exibirLoading,
  setFiltroParceiro,
} from "../../actions";
import { deleteParceiro, getAllFilterParceiros } from "../../api";
import { DialogDetails } from "../../components/DialogDetails";
import { FiltroParceiro } from "../../components/FiltroParceiro";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { formatCnpj, formatCpf } from "../../utils/cpfCnpj";
import { Navbar } from '../../components/Navbar';

export const Parceiros = (props) => {
  const page = props?.location?.state?.page
  const parceiros = useSelector((state) => state.grupoEconomico.listaParceiros);
  const [visibleDetalhes, setVisibleDetalhes] = useState(false);
  const [detalhes, setDetalhes] = useState({});
  const [visibleDelete, setVisibleDelete] = useState(false);
  const loading = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [filtrar, setFiltrar] = useState(false);
  const [nameDelete, setNameDelete] = useState("");
  const [idDelete, setIdDelete] = useState();
  const history = useHistory();

  const id = useSelector((state) => state.filtroParceiro.id);
  const parceiroTipos = useSelector((state) => state.filtroParceiro.parceiroTipos);
  const nomeTratamento = useSelector(
    (state) => state.filtroParceiro.nomeTratamento
  );
  const nomeCidadePrincipal = useSelector(
    (state) => state.filtroParceiro.nomeCidadePrincipal
  );
  const nomeCompleto = useSelector(
    (state) => state.filtroParceiro.nomeCompleto
  );
  const historico = useSelector((state) => state.filtroParceiro.historico);
  const dataNasc = useSelector((state) => state.filtroParceiro.dataNasc);
  const cpfCnpj = useSelector((state) => state.filtroParceiro.cpfCnpj);
  const rgInscEstadual = useSelector(
    (state) => state.filtroParceiro.rgInscEstadual
  );
  const ufInscricao = useSelector((state) => state.filtroParceiro.ufInscricao);
  const insMunicipal = useSelector(
    (state) => state.filtroParceiro.insMunicipal
  );
  const ativo = useSelector((state) => state.filtroParceiro.ativo);
  const tipoPessoa = useSelector((state) => state.filtroParceiro.tipoPessoa);
  // const cliente = useSelector((state) => state.filtroParceiro.cliente);
  // const fornecedor = useSelector((state) => state.filtroParceiro.fornecedor);
  // const colaborador = useSelector((state) => state.filtroParceiro.colaborador);
  const sexo = useSelector((state) => state.filtroParceiro.sexo);
  const dataPrimeiroFaturamento = useSelector(
    (state) => state.filtroParceiro.dataPrimeiroFaturamento
  );
  const ufPrincipal = useSelector((state) => state.filtroParceiro.ufPrincipal);
  const diasMedios = useSelector((state) => state.filtroParceiro.diasMedios);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);
  const [clearInputs, setClearInputs] = useState(false);

  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }
  }, [props, page])

  const reduxStateAtual = useSelector(state => state.filtroParceiro);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState('');
  
  async function clearFilter() {
    const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial.filtroParceiro)
    setVisible(!filterActive)
    if (filterActive === false) {
      setClear(true)
      dispatch(setFiltroParceiro(reduxStateInicial.filtroParceiro));
      // setVisible(false)
    } else {
      setClear(false)
    }
  }

  const toast = useRef(null);
  const dispatch = useDispatch();

  // const listaTipo = [
  //   { value: "C", label: "Cliente" },
  //   { value: "M", label: "Fornecedor de Materias e Insumos" },
  //   { value: "F", label: "Funcionário" },
  //   { value: "G", label: "Governo" },
  //   { value: "P", label: "Prestador de Serviços PJ" },
  //   { value: "O", label: "Outros" },
  // ];

  const onClickDetalhes = (data) => {
    setVisibleDetalhes(true);
    setDetalhes(data);
  };

  const removeParceiro = async () => {
    setCarregandoRequisicao(true);
    try {
      await deleteParceiro(idDelete).then(() => {
        dispatch(setFiltroParceiro(reduxStateInicial.filtroParceiro))
        setClearInputs(true)
      });
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: 'Parceiro excluído com sucesso!',
        life: 5000,
      });
      setVisibleDelete(false);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `Não é possível deletar um parceiro que está vinculado a um contrato`,
        life: 5000,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  };

  const onClickRemove = (data) => {
    setVisibleDelete(true);
    setNameDelete(data.nomeTratamento);
    setIdDelete(data.id);
  };

  const detailsColumns = [
    [
      { field: "nomeCompleto", label: "Nome Completo" },
      { field: "historico", label: "Histórico" },
      { field: "dataNasc", label: "Data Nascimento", type: "date" },
      { field: "rgInscEstadual", label: "RG/Inscrição Estadual" },
      { field: "ufInscricao", label: "UF de Inscricao" },
      { field: "insMunicipal", label: "Inscrição Municipal" },
    ],
    [
      { field: "colaborador", label: "Colaborador" },
      { field: "sexo", label: "Sexo" },
      { field: "diasMedios", label: "Dias Médios" },
      {
        field: "dataPrimeiroFaturamento",
        label: "Data Primeiro Faturamento",
        type: "date",
      },
      { field: "dataCadastro", label: "Data Cadastro", type: "date" },
      { field: "dataAlteracao", label: "Ultima Data Alteração", type: "date" },
    ],
  ];

  const formattedTipo = (rowData) => {
    const concatenatedString = rowData?.map(objeto => objeto.nome).join(', ');

    return (
      <>{concatenatedString}</>
    );
  };

  const formattedTipoPessoa = (rowData, field) => {
    if (rowData[field] === "J") {
      return <>Jurídica</>;
    } else if (rowData[field] === "F") {
      return <>Física</>;
    }
  };

  const columns = [
    { field: "id", header: "Código", style: { minWidth: 50, maxWidth: "5vw" } },
    {
      field: "tipo",
      header: "Tipo",
      type: "custom",
      customFunc: (e) => formattedTipo(e.parceiroTipos),
      style: { minWidth: 110, maxWidth: "15vw" },
    },
    {
      field: "nomeTratamento",
      header: "Nome de Tratamento",
      type: "customEllipsis",
      style: { minWidth: 105, maxWidth: "15vw" },
    },
    {
      field: "cpfCnpj",
      header: "CPF/CNPJ",
      type: "custom",
      style: { minWidth: 105, maxWidth: "10vw" },
      customFunc: (e) => e.cpfCnpj?.length === 11 ? formatCpf(e.cpfCnpj) : formatCnpj(e.cpfCnpj),
    },
    {
      field: "ativo",
      header: "Status",
      type: "check",
      style: { minWidth: 65, maxWidth: "5vw" },
    },
    {
      field: "tipoPessoa",
      header: "Pessoa",
      type: "custom",
      style: { minWidth: 65, maxWidth: "5vw" },
      customFunc: formattedTipoPessoa,
    },
    // {
    //   field: "cliente",
    //   header: "Cliente",
    //   type: "check",
    //   style: { minWidth: 50, maxWidth: "5vw" },
    // },
    // {
    //   field: "fornecedor",
    //   header: "Fornecedor",
    //   type: "check",
    //   style: { minWidth: 65, maxWidth: "5vw" },
    // },
    {
      field: "ufPrincipal",
      header: "UF principal",
      style: { minWidth: 65, maxWidth: "5vw" },
      type: "customEllipsis",
    },
    {
      field: "nomeUfPrincipal",
      header: "Nome UF Principal",
      style: { minWidth: 110, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "nomeCidadePrincipal",
      header: "Cidade Principal",
      style: { minWidth: 110, maxWidth: "10vw" },
      type: "customEllipsis",
    },
  ];

  const  dataNascHandler = useCallback(() => {
    if (dataNasc !== null) {
      return new Date(dataNasc);
    } else {
      return null;
    }
  }, [dataNasc])

  const dataPrimeiroFaturamentoHandler= useCallback(() => {
    if (dataPrimeiroFaturamento !== null) {
      return new Date(dataPrimeiroFaturamento);
    } else {
      return null;
    }
  }, [dataPrimeiroFaturamento])

  const montarFiltro = useCallback(() => {
    const filtroParceiro = {
      id: id,
      //cpfCnpj: cpfCnpj,
      parceiroTipos: parceiroTipos,
      nomeTratamento: nomeTratamento,
      nomeCidadePrincipal: nomeCidadePrincipal,
      nomeCompleto: nomeCompleto,
      historico: historico,
      dataNasc: dataNascHandler(),
      cpfCnpj: cpfCnpj
        ?.replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "")
        .replaceAll("_", ""),
      rgInscEstadual: rgInscEstadual,
      ufInscricao: ufInscricao,
      insMunicipal: insMunicipal,
      ativo: ativo,
      tipoPessoa: tipoPessoa,
      // cliente: cliente,
      // fornecedor: fornecedor,
      // colaborador: colaborador,
      sexo: sexo,
      dataPrimeiroFaturamento: dataPrimeiroFaturamentoHandler(),
      ufPrincipal: ufPrincipal,
      diasMedios: diasMedios,
    };
    return filtroParceiro;
  }, [
    id,
    parceiroTipos,
    nomeTratamento,
    nomeCidadePrincipal,
    nomeCompleto,
    historico,
    cpfCnpj,
    rgInscEstadual,
    ufInscricao,
    insMunicipal,
    ativo,
    tipoPessoa,
    // cliente,
    // fornecedor,
    // colaborador,
    sexo,
    ufPrincipal,
    diasMedios,
    dataNascHandler,
    dataPrimeiroFaturamentoHandler,
  ]);

  const carregarParceiro = useCallback(() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };

    getAllFilterParceiros(requestParams).then((resultado) => {
      if (resultado.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "O filtro não obteve resultado",
        });
      }
      dispatch(addListaParceiro(resultado.content));
      setTotalRecords(resultado.totalElements);
    }).catch(

    ).finally(
      () => dispatch(esconderLoading())
    );
    setFiltrar(false);
  }, [dispatch, lazyParams, montarFiltro])

  useEffect(() => {
    const buttonClearFilter = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroParceiro)
    setVisible(!buttonClearFilter)
    carregarParceiro();
  }, [montarFiltro, lazyParams, carregarParceiro, reduxStateAtual, reduxStateInicial]);

  const modalExcluir = [
    { label: "Código:", value: idDelete },
    { label: "Nome Tratamento:", value: nameDelete },
  ];

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <FiltroParceiro
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          abrirFiltro={filtrar}
          setAbrirFiltro={setFiltrar}
          setLazyParamsProp={setLazyParams}
        />
        <DialogDetails
          details={detalhes}
          detailsColumns={detailsColumns}
          isVisible={visibleDetalhes}
          onHide={() => {
            setVisibleDetalhes(false);
          }}
          data-testid="detalhesID"
        />
        <Table
          arrayData={parceiros}
          loading={loading}
          columns={columns}
          headerTitle="Parceiros"
          onClickAdicionar={() => {
            history.push("/parceiro/cadastro", { page: lazyParams });
          }}
          onClickFiltrar={() => {
            setFiltrar(true);
          }}
          onClickDetails={onClickDetalhes}
          onClickEdit={(rowData) => {
            history.push(`/parceiro/${rowData.id}`, { page: lazyParams, parceiro: rowData })
          }}
          tooltipEdit="Parceiro"
          onClickDelete={(rowData) => onClickRemove(rowData)}
          data-testid="adicionarEditarID"
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
        <ModalComponents
          title="Parceiro"
          onClick="delete"
          visible={visibleDelete}
          descricao={modalExcluir}
          onHide={() => setVisibleDelete(false)}
          onClickConfirmar={() => removeParceiro()}
          onClickCancelar={() => setVisibleDelete(false)}
          data-testid="excluirID"
        />
      </LoadingOverlayComponents>
    </>
  );
};
