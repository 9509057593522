import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import React from "react";
import { messageRequired } from "../../../default/messages";
import { brazilianCalendarParams } from "../../../utils";
import "./styles.css";

export const DateInput = ({
  label,
  value,
  setState,
  endValue,
  setEndState,
  type,
  isEmpty,
  required,
  style,
  rangeOfDate,
  directionCol,
  datatesteid,
  datatesteidrange,
  validacao,
  setAlteracoes = () => { },
}) => {
  addLocale("pt-br", brazilianCalendarParams);

  return (
    <div style={style} key={label} className="dateInput__form">
      <div>
        <label>{label}</label>
        {required && <abbr className="form__asterisk">*</abbr>}
      </div>
      <div
        data-testid="containerDateInput"
        className={
          directionCol
            ? "dateInput__container--col"
            : "dateInput__container--row"
        }
      >
        <Calendar
          validacao
          mask="99/99/9999"
          className="dateInput__field"
          showIcon
          dateFormat={"dd/mm/yy"}
          type={type}
          value={value}
          onChange={(e) => {
            setState(e.target.value);
            setAlteracoes(true);
          }}
          locale="pt-br"
          data-testid={datatesteid}
        />

        {rangeOfDate && (
          <Calendar
            mask="99/99/9999"
            className="dateInput__field"
            showIcon
            dateFormat={"dd/mm/yy"}
            type={type}
            value={endValue}
            onChange={(e) => {
              setEndState(e.target.value);
            }}
            locale="pt-br"
            data-testid={datatesteidrange}
          />
        )}
      </div>
      <small className="form__errorMsg" hidden={!isEmpty}>
        {messageRequired}
      </small>
    </div>
  );
};
