import axios from "axios";
const url = process.env.REACT_APP_API_URL;
export default axios.create({
    baseURL: `${url}`,
});

export const getFiltroSalvoByNomeFiltro = (nomeFiltro) =>
    axios
        .get(`${url}/usuario-filtro/filtro-nome/${nomeFiltro}`)
        .then((response) => response.data);

export const saveUsuarioFiltro = (filtro) =>
    axios
        .post(`${url}/usuario-filtro`, filtro)
        .then((response) => response.data);

export const deleteFiltroSalvo = (id) =>
    axios
        .delete(`${url}/usuario-filtro/${id}`)
        .then((response) => response.data);
