import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { ButtonComponent } from '../ButtonComponent';
import './styles.css';
import { useHistory } from 'react-router-dom';


export const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const {logout} = useContext(AuthContext)
  const history = useHistory();


  return (
    <>
        <header className='navbar__header'>
          <Button
            className='navbar__button'
            icon="pi pi-align-justify"
            tooltip="Abrir Menu"
            onClick={showSidebar}
            data-testid="navbar__button"
          />
          <ButtonComponent style={{
            position: 'absolute',
            right: '0',
            top: '12px'
          }} type='logout' onClick={logout}/>
        </header>
        <nav className={sidebar ? 'navbar__active navbar__menu' : 'navbar__menu'}>
          <ul className='navbar__menu--items' style={{ display: 'flex',alignItems: 'flex-start', justifyContent: 'start', flexDirection: 'column' }}>
            <li className='navbar__toggle'>
              <Button
                className='navbar__button navbar__button--close'
                onClick={showSidebar}
                tooltip="Fechar Menu"
                icon="pi pi-times"
              />
            </li>
            <Fieldset
              className="navbar__fields"
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'start', flexDirection: 'column'}}
              legend="Sistema"
              toggleable={true}
              collapsed={true}
            >
              <Link to="/parametros-do-sistema" className='navbar__fields--text'>
                Parâmetros
              </Link>
            </Fieldset>
            <Fieldset
              className="navbar__fields"
              legend="Cadastrar" 
              toggleable={true}
              collapsed={true}
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'start' }}
            >
              <Link to="/centros-de-custos" className='navbar__fields--text'>
                Centro de Custo
              </Link>
              <Link to="/contas-correntes" className='navbar__fields--text'>
                Conta Corrente
              </Link>
              <Link to="/empresas" className='navbar__fields--text'>
                Empresa
              </Link>
              <Link to="/grupos-economicos" className='navbar__fields--text'>
                Grupo Econômico
              </Link>
              <Link to="/naturezas" className='navbar__fields--text'>
                Natureza
              </Link>
              <Link to="/parceiros" className='navbar__fields--text'>
                Parceiros
              </Link>
              <Link to="/contratos" className='navbar__fields--text'>
                Contratos
              </Link>
              <Link to="/cobranca-inadimplente" className='navbar__fields--text'>
                Cobrança Inadimplente
              </Link>
              <Link to="/emails-enviados" className='navbar__fields--text'>
                E-mails Enviados
              </Link>
              <Link to="/produtos-servicos" className='navbar__fields--text'>
                Produtos e Serviços
              </Link>
            </Fieldset>
            <Fieldset
              className="navbar__fields"
              legend="Movimentação Financeira"
              toggleable={true}
              collapsed={true}
            >
              <Link to="/movimentacoes" className='navbar__fields--text'>
                Lista Pagar/Receber
              </Link>
              {/* <Link to="/FluxoDeCaixa" className='navbar__fields--text'>
                Fluxo de Caixa
              </Link> */}
              <Link to="/conciliacoes-bancarias" className='navbar__fields--text'>
                Conciliação Bancária
              </Link>
            </Fieldset>
            {/* <Fieldset
              className="navbar__fields"
              legend="Conciliação"
              toggleable={true}
              collapsed={true}
            >
              <Link to="/conciliacoes-bancarias" className='navbar__fields--text'>
                Manual
              </Link>
              <Link to="/anexos" className='navbar__fields--text'>
                Arquivo
              </Link>
              <Link to="/Home" className='navbar__fields--text'>
                Automática
              </Link>
            </Fieldset> */}
          <button
            className='navbar__fields--text'
            id='navbar__fields__unique'
            style={{ color: '#fff', cursor: 'pointer' }}
            onClick={() => history.push("/dfc")}
          >
            DFC
          </button>
        </ul>
      </nav>
    </>
  );
}
