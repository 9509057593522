export const addProdutoServico = (produtoServico) => ({
  type: "ADD_MOV_PRODUTO_SERVICO",
  payload: produtoServico,
});

export const setFiltroProdServico = (filtroProd) => ({
  type: "SET_FILTRO_PRODUTO_SERVICO",
  payload: filtroProd,
});

export const setFiltroMultipleProdServico = (produtosServicos) => ({
  type: "SET_FILTRO_MULTIPLE_PRODUTO_SERVICO",
  payload: produtosServicos,
});

export const setDescricaoProdServ = (descricao) => ({
  type: "SET_DESCRICAO",
  payload: descricao,
});

export const setStatus = (status) => ({
  type: "SET_STATUS",
  payload: status,
});

export const setNome = (nome) => ({
  type: "SET_NOME",
  payload: nome,
});

export const setDescricaoProdFiltroDrop = (descricao) => ({
  type: "SET_FILTRO_DESCRICAO_PROD_DROP",
  payload: descricao,
});
