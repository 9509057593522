import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getAllMeta = () =>
  axios.get(`${url}/metas`).then((response) => response.data);

export const putPorcentagemMeta = (percentual, id, usuario) =>
  axios
    .put(`${url}/metas/${id}`, null, {
      params: {
        percentual: percentual,
        usuarioUltAlt: usuario,
      },
    })
    .then((response) => response.data);

export const deleteMeta = (id) =>
  axios.delete(`${url}/metas/${id}`).then((response) => response.data);

export const saveMeta = (data) =>
  axios.post(`${url}/metas`, data).then((response) => response.data);

export const getMetaByNaturezaId = (naturezaId, requestParams) =>
  axios
    .get(`${url}/metas/natureza/${naturezaId}${handlePaginationUri(requestParams)}`)
    .then((response) => response.data);
