import _ from "lodash";
import { addLocale } from "primereact/api";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroEmailsEnviados } from "../../actions";
import { paginationDefault } from "../../default/pagination";
import { brazilianCalendarParams } from "../../utils";
import { Filtro } from "../Filtro";

export const FiltroEmailsEnviados = ({
  setAbrirFiltroEmailsEnviados,
  abrirFiltroEmailsEnviados,
  clear,
  setVisible,
  setLazyParamsProp,
  reduxStateAtual,
  reduxStateInicial
}) => {

  const dispatch = useDispatch();
  const [dataInicio, setDataInicio] = useState(
    useSelector((state) => state.filtroEmailsEnviados.dataInicio)
  );
  const [dataFim, setDataFim] = useState(
    useSelector((state) => state.filtroEmailsEnviados.dataFim)
  );
  const [id, setId] = useState(
    useSelector((state) => state.filtroEmailsEnviados.id)
  );
  const [mensagem, setMensagem] = useState(
    useSelector((state) => state.filtroEmailsEnviados.mensagem)
  );
  const [status, setStatus] = useState(
    useSelector((state) => state.filtroEmailsEnviados.status)
  );

  const selectStatus = [
    { name: "Ambos", value: null },
    { name: "Enviado", value: "OK" },
    { name: "Erro", value: "ERRO" },
  ];

  const filterFields = [
    {
      name: "id",
      label: "Código:",
      value: id,
      setState: setId,
      dataType: "pint",
      datatesteid: "idTest",
    },
    {
      label: "Intervalo de Datas:",
      type: "date",
      rangeOfDate: true,
      value: dataInicio,
      setState: setDataInicio,
      endValue: dataFim,
      setEndState: setDataFim,
      directionCol: true,
      datatesteid: "dataInicioId",
      datatesteidrange: "dataFimId",
    },
    {
      name: "mensagem",
      label: "Mensagem:",
      value: mensagem,
      setState: setMensagem,
      datatesteid: "mensagemId",
    },
    {
      name: "status",
      label: "Status:",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: selectStatus,
      optionLabel: "name",
      optionValue: "value",
    },
  ];

  addLocale("pt-br", brazilianCalendarParams);

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroEmailsEnviados({
        id: id !== "" ? id : null,
        dataInicio: dataInicio ? dataInicio : null,
        dataFim: dataFim ? dataFim : null,
        mensagem: mensagem !== "" ? mensagem : null,
        status,
      })
    );
  };

  const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial);

  const handleSetVisible = useCallback((value) => {
    setVisible(value);
  }, [setVisible]);

  const limpar = useCallback(() => {
    setId("");
    setDataInicio(null);
    setDataFim(null);
    setMensagem("");
    setStatus(null);
    handleSetVisible(false);
  }, [handleSetVisible]);
  
  useEffect(() => {
    if (!filterActive) {
      handleSetVisible(true);
    }
  }, [filterActive, handleSetVisible]);
  
  const clearCallback = useCallback(() => {
    limpar();
  }, [limpar]);
  
  useEffect(() => {
    if (clear === true) {
      clearCallback();
    }
  }, [clear, clearCallback]);  

  return (
    <Filtro
      filterFields={filterFields}
      setIsFiltroVisible={setAbrirFiltroEmailsEnviados}
      isFiltroVisible={abrirFiltroEmailsEnviados}
      clearFilter={limpar}
      handleConfirmFilter={filtrar}
    />
  );
};
