const INITIAL_STATE = {
    idContrato: null,
    contrato: null,
    numeroContrato: null,
    numeroParcela: null,
    dataEnvioEmail: null,
    vencimentoPlanejado: null,
    vencimentoOriginal: null,
    automatizarCob: null,
    // idUsuario: null,
}

export const filtroEmailCob = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_EMAIL_COB_INAD":
            return { ...action.payload };
        // case "SET_PAGINA":
        //     return { ...state, pagina: action.payload };
        // case "SET_QTD_REGISTRO":
        //     return { ...state, qtdRegistros: action.payload };

        default:
            return state;
    }
};
