import { MultiSelect } from 'primereact/multiselect';
import React from "react";
import "./styles.css";


export const MultiSelectComponent = ({
  editable,
  label,
  options,
  value,
  setState,
  placeholder,
  optionLabel,
  optionValue,
  required,
  disabled,
  isEmpty,
  validacao,
  errorMessage,
  datatesteid,
  onFocus,
  style,
  noClass,
  filter,
  setAlteracoes = () => { }
}) => { 
  return (

    <div key={label} className={!noClass && "select__form"}>
      <div>
        <label>{label}</label>
        {required && (
          <abbr data-testid="abbr-asteristic" className="form__asterisk">
            *
          </abbr>
        )}
      </div>
      {options.length !== 0 && required && <MultiSelect
        style={style}
        filter={filter}
        filterBy={optionLabel}
        validacao
        disabled={disabled}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={(e) => {
          setState(e.value[optionValue] || e.value);
          setAlteracoes(true)
        }}
        className="select__dropdown"
        panelStyle={{ width: "0px" }}
        data-testid={datatesteid}
        display="chip"
        optionLabel={optionLabel}
        optionValue={optionValue}
        onFocus={onFocus}
      />}
      {!required && <MultiSelect
        validacao
        filter={filter}
        filterBy={optionLabel}
        disabled={disabled}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={(e) => {
          setState(e.value[optionValue] || e.value);
          setAlteracoes(true)
        }}
        className="select__dropdown"
        panelStyle={{ width: "0px" }}
        data-testid={datatesteid}
        display="chip"
        optionLabel={optionLabel}
        optionValue={optionValue}
        onFocus={onFocus}
      />}
      <small
        className="form__errorMsg"
      // hidden={!isEmpty}
      >
        {errorMessage}
      </small>
    </div>
  );
};

