import { AutoComplete } from "primereact/autocomplete";
import React from "react";
import { useDispatch } from "react-redux";

export const AutoCompleteComponent = ({
  value,
  options,
  setValue,
  filter,
  attribute,
  errorMessage,
  optionLabel,
  label,
  required,
  name,
  disabled,
  datatesteid,
  genericFunction = () => {}
}) => {
  function handleChangeValues(e) {
    genericFunction();
    setValue(e.value);
  }

  const dispatch = useDispatch();

  function filterBy(prop) {
    dispatch(
      filter({
        [attribute]: prop?.originalEvent?.target?.value,
      })
    );
  }

  return (
    <div key={label} className={label && "p-fluid select__form"}>
      {label && (
        <div>
          <label>{label}</label>
          {required && (
            <abbr data-testid="abbr-asteristic" className="form__asterisk">
              *
            </abbr>
          )}
        </div>
      )}
      <AutoComplete
        name={name}
        value={value}
        suggestions={options}
        field={optionLabel}
        onChange={(e) => handleChangeValues(e)}
        completeMethod={filterBy}
        minLength="3"
        className="select__dropdown"
        tooltip={disabled ? '' : "Digite pelo menos 3 (três) caracteres."}
        tooltipOptions={disabled ? '' : { event: "hover", position: "left" }}
        delay={800}
        disabled={disabled}
        data-testid={datatesteid}
      />
      <small className="form__errorMsg">{errorMessage}</small>
    </div>
  );
};
