import React from "react";
import { EditarParceiro } from "../../components/EditarParceiro";

export const ParceirosAdicionar = (props) => {

    return (
        <>
            <EditarParceiro
                editParceiro={props?.location?.state?.parceiro}
                page={props?.location?.state?.page}
            />
        </>
    )
}
