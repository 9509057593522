const INITIAL_STATE = {
  dataInicioModificada: new Date(),
  dataFimModificada: null,
  dataInicioPrevista: null,
  dataFimPrevista: null,
  dataInicioBaixa: null,
  dataFimBaixa: null,
  valorMinimo: "",
  valorMaximo: "",
  baixaBoolean: false,
  semDocumentosComBaixa: false,
  vencidoSemEmissaoComBaixa: false,
  parceiro: null,
  numeroDocumento: null,
  empresa: null,
  tipoLancamento: { name: 'Ambos', value: 'Ambos' },
  pagina: 0,
  tamanhoPagina: 400,
  // naturezaHierarquica: null
};

export const filtro = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO":
      return { ...action.payload };
    case "SET_DATA_INICIO_MODIFICADA":
      return { ...state, dataInicioModificada: action.payload };
    case "SET_DATA_FIM_MODIFICADA":
      return { ...state, dataFimModificada: action.payload };
    case "SET_DATA_INICIO_PREVISTA":
      return { ...state, dataInicioPrevista: action.payload };
    case "SET_DATA_FIM_PREVISTA":
      return { ...state, dataFimPrevista: action.payload };
    case "SET_DATA_INICIO_BAIXA":
      return { ...state, dataInicioBaixa: action.payload };
    case "SET_DATA_FIM_BAIXA":
      return { ...state, dataFimBaixa: action.payload };
    case "SET_PARCEIRO":
      return { ...state, parceiro: action.payload };
    case "SET_PROJETO":
      return { ...state, projeto: action.payload };
    case "SET_CNPJ":
      return { ...state, cnpj: action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_TAMANHO_PAGINA":
      return { ...state, tamanhoPagina: action.payload };
    case "SET_TAMANHO_TOTAL":
      return { ...state, tamanhoTotal: action.payload };
    case "SET_SORT_FIELD":
      return { ...state, sortField: action.payload };
    case "SET_SORT_ORDER":
      return { ...state, sortOrder: action.payload };
    case "SET_VALOR_MINIMO":
      return { ...state, valorMinimo: action.payload };
    case "SET_VALOR_MAXIMO":
      return { ...state, valorMaximo: action.payload };
    case "SET_BAIXA_BOOLEAN":
      return { ...state, baixaBoolean: action.payload };
    case "SET_TIPO_LANCAMENTO":
      return { ...state, tipoLancamento: action.payload };
    case "SET_SEM_DOCUMENTOS_COM_BAIXA":
      return { ...state, semDocumentosComBaixa: action.payload };
    case "SET_VENCIDO_SEM_EMISSAO_COM_BAIXA":
      return { ...state, vencidoSemEmissaoComBaixa: action.payload };
    // case "ADD_NATUREZA_HIERARQUICA":
    // return { ...state, naturezaHierarquica: action.payload };
    default:
      return state;
  }
};
