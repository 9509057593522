import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getProdutoServicoByPai = (editing, id) =>
  axios
    .get(`${url}/produtos/combo-pai`, {
      params: {
        editing: editing,
        id: id,
      },
    })
    .then((response) => response.data);

export const saveProdutoServico = (filtroProd) =>
  axios.post(`${url}/produtos`, filtroProd);
// .then((response) => response.data);

export const getAllProdutoServico = (filtroProd) =>
  axios.get(`${url}/produtos/summary`, filtroProd).then((response) => response.data);

export const getFilterProdutoServico = (requestParams) =>
  axios
    .post(
      `${url}/produtos/lista-filtrada${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const putProdutoServico = (produto, id) =>
  axios
    .put(`${url}/produtos/${id}`, produto)
    .then((response) => response.status);

export const deleteProdutoServico = (id) =>
  axios.delete(`${url}/produtos/${id}`).then((response) => response.status);

export const putProdutoPai = (id, novoPaiId) =>
  axios
    .put(`${url}/produtos/${id}/pai/${novoPaiId}`)
    .then((response) => response.data);

export const getByDescricaoProduto = (descricao) =>
  axios
    .get(`${url}/produtos/filtro-descricao/${descricao}`)
    .then((response) => response.data);

export const getByDescricaoProdutoEAtivo = (descricao) =>
  axios
    .get(`${url}/produtos/filtro-descricao-ativo/${descricao}`)
    .then((response) => response.data);

export const getByNomeProduto = (nome) =>
  axios
    .get(`${url}/produtos/filtrar-por-nome-com-abreviacao/${nome}`)
    .then((response) => response.data);
