import _ from "lodash";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addListaContasCorrentes,
  esconderCCSalva,
  esconderLoadingCC,
  exibirLoadingCC,
  setFiltroCC,
} from "../../actions";
import { deleteContaCorrente, getAllFilterContaCorrente } from "../../api";
import { FiltroContaCorrente } from "../../components/FiltroContaCorrente";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { SaldoConta } from "../../components/SaldoConta";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { Navbar } from '../../components/Navbar';

export const ContasCorrentes = (props) => {
  const page = props?.location?.state?.page;
  const [isFiltroVisible, setIsFiltroVisible] = useState(false);
  const [idExcluir, setIdExcluir] = useState("");
  const [descricaoConta, setDescricaoConta] = useState("");
  const [dialogExcluir, setDialogExcluir] = useState(false);
  const descricao = useSelector((state) => state.filtroCC.descricao);
  const nomeFantasiaEmpresa = useSelector(
    (state) => state.filtroCC.nomeFantasiaEmpresa
  );

  const nomeTratamentoParceiro = useSelector(
    (state) => state.filtroCC.nomeTratamentoParceiro
  );

  const [contaCorrenteLinha, setContaCorrenteLinha] = useState([]);

  const agencia = useSelector((state) => state.filtroCC.agencia);
  const contaCorrente = useSelector((state) => state.filtroCC.contaCorrente);
  const nomeBanco = useSelector((state) => state.filtroCC.nomeBanco);
  const idBanco = useSelector((state) => state.filtroCC.idBanco);
  const ativa = useSelector((state) => state.filtroCC.ativa);
  const alertaSalvo = useSelector((state) => state.contasCorrentes.alertaSalvo);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [abrirConta, setAbrirConta] = useState(false);

  const lista = useSelector((state) => state.contasCorrentes.lista);
  const loading = useSelector((state) => state.contasCorrentes.loading);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);
  const reduxStateAtual = useSelector((state) => state.filtroCC);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState("");
  const [clearInputs, setClearInputs] = useState(false);
  

  useEffect(() => {
    if (page) {
      setLazyParams(page);
    }
  }, [props, page]);

  async function clearFilter() {
    const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial.filtroCC);
    setVisible(!filterActive);
    if (filterActive === false) {
      setClear(true);
      dispatch(setFiltroCC(reduxStateInicial.filtroCC));
      // setClearInputs(true)
      // setVisible(false)
    } else {
      setClear(false);
    }
  }

  let history = useHistory();
  function functionExcluirNatureza(rowData) {
    //função coom parametro por linha para excluir CC.
    setDialogExcluir(true);
    setIdExcluir(rowData.id);
    setDescricaoConta(rowData.descricao);
  }

  function editarContaCorrente(rowData) {
    history.push(`/conta-corrente/${rowData.id}`, {
      page: lazyParams,
      conta: rowData,
    });
  }

  function principal(rowData) {
    if (rowData.principal === "S" || rowData.principal === "A") {
      return (
        <Button
          className="formattedStyles"
          tooltip="Sim"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillLike
            data-testid="principal--icon"
            className="formattedStyles--check"
            alt="Conta Principal"
            size={15}
          />
        </Button>
      );
    } else if (rowData.principal === "N" || rowData.principal === "I") {
      return (
        <Button
          className="formattedStyles"
          tooltip="Não"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillDislike
            data-testid="principal--icon"
            className="formattedStyles--times"
            size={15}
          />
        </Button>
      );
    }
  }

  function verificarStatus(rowData) {
    //mostra icone se estiver ativa
    if (rowData.ativa === "S" || rowData.ativa === "A") {
      return (
        <Button
          className="formattedStyles formattedStyles--check"
          icon="pi pi-check-circle"
          tooltip="Conta Ativa"
          tooltipOptions={{ position: "left" }}
          data-testid="status--icon"
        />
      );
    } else if (rowData.ativa === "N" || rowData.ativa === "I") {
      return (
        <Button
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times-circle"
          tooltip="Conta Inativa"
          tooltipOptions={{ position: "left" }}
          data-testid="status--icon"
        />
      );
    }
  }

  const montarFiltro = useCallback(() => {
    const filtroCC = {
      // idUsuario: null
      descricao,
      agencia,
      contaCorrente,
      nomeBanco,
      ativa,
      idBanco,
      nomeFantasiaEmpresa,
      nomeTratamentoParceiro,
    };
    return filtroCC;
  }, [
    descricao,
    agencia,
    contaCorrente,
    nomeBanco,
    ativa,
    idBanco,
    nomeFantasiaEmpresa,
    nomeTratamentoParceiro,
  ]);

  const carregarContas = useCallback(() => {
    dispatch(exibirLoadingCC());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };

    getAllFilterContaCorrente(requestParams)
      .then((resultado) => {
        if (resultado.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "O filtro não obteve resultado",
          });
          dispatch(addListaContasCorrentes([]));
        } else {
          resultado.content.forEach((conta) => {
            if (conta.classe === "C") {
              conta.nomeClasse = "Conta Corrente";
            } else if (conta.classe === "I") {
              conta.nomeClasse = "Investimento";
            } else if (conta.classe === "P") {
              conta.nomeClasse = "Caixa Pequeno";
            } else {
              conta.nomeClasse = "";
            }
          });

          dispatch(addListaContasCorrentes(resultado.content));
        }
        setTotalRecords(resultado.totalElements);
        dispatch(esconderLoadingCC());
      })
      .catch((e) => e)
      .finally(() => {
        dispatch(esconderLoadingCC());
      });
    setIsFiltroVisible(false);
  },[dispatch, lazyParams, montarFiltro])

  useEffect(() => {
    const buttonClearFilter = _.isEqual(
      reduxStateAtual,
      reduxStateInicial?.filtroCC
    );
    setVisible(!buttonClearFilter);
    carregarContas();
  }, [montarFiltro, lazyParams, carregarContas, reduxStateAtual, reduxStateInicial]);

  useEffect(() => {
    if (alertaSalvo) {
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Conta Corrente salva com sucesso",
      });
      dispatch(esconderCCSalva());
    }
  }, [dispatch, alertaSalvo]);

  const deleteConta = async (contaId) => {
    setCarregandoRequisicao(true);
    try {
      await deleteContaCorrente(contaId).then(() => {
        dispatch(setFiltroCC(reduxStateInicial.filtroCC));
        setClearInputs(true);
      });
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Conta corrente excluída com sucesso!",
        life: 5000,
      });
      setDialogExcluir(false);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e.response?.data?.detail}`,
        life: 5000,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  };

  const columns = [
    {
      field: "descricao",
      header: "Descrição",
      style: { minWidth: 100, maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "empresa.nomeFantasia",
      header: "Empresa",
      style: { minWidth: 130, maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "parceiro.nomeTratamento",
      header: "Parceiro",
      style: { minWidth: 100, maxWidth: "10vw" },
      type: "left",
    },
    {
      field: "banco.nomeFantasia",
      header: "Banco",
      style: { minWidth: 100, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "banco.idBanco",
      header: "Código do Banco",
      style: { minWidth: 100, maxWidth: "10vw" },
    },
    {
      field: "nomeClasse",
      header: "Classe",
      style: { minWidth: 100, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "agencia",
      header: "Agência",
      style: { minWidth: 50, maxWidth: "5vw" },
    },
    {
      field: "contaCorrenteNumero",
      header: "Número da Conta",
      style: { minWidth: 100, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "principal",
      header: "Principal",
      style: { minWidth: 50, maxWidth: "5vw" },
      type: "custom",
      customFunc: principal,
    },
    {
      field: "ativa",
      header: "Ativa",
      style: { minWidth: 50, maxWidth: "5vw" },
      type: "custom",
      customFunc: verificarStatus,
    },
  ];

  const modalExcluir = [
    { label: "Código:", value: idExcluir },
    { label: "Descrição:", value: descricaoConta },
  ];

  function abrirSaldo(contaCorrente) {
    setAbrirConta(true);
    setContaCorrenteLinha(contaCorrente);
  }

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <Table
          arrayData={lista}
          loading={loading}
          headerTitle="Contas Correntes"
          columns={columns}
          onClickFiltrar={() => setIsFiltroVisible(true)}
          onClickAdicionar={() =>
            history.push("/conta-corrente/cadastro", { page: lazyParams })
          }
          onClickEdit={(rowData) => editarContaCorrente(rowData)}
          tooltipEdit="Conta Corrente"
          onClickDelete={(rowData) => functionExcluirNatureza(rowData)}
          onClickSaldo={(rowData) => abrirSaldo(rowData)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
        <FiltroContaCorrente
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          isFiltroVisible={isFiltroVisible}
          setIsFiltroVisible={setIsFiltroVisible}
          setLazyParamsProp={setLazyParams}
        />
        <ModalComponents
          onClick="delete"
          visible={dialogExcluir}
          onHide={() => setDialogExcluir(false)}
          title="Conta"
          descricao={modalExcluir}
          onClickCancelar={() => setDialogExcluir(false)}
          onClickConfirmar={() => deleteConta(idExcluir)}
        />

        <SaldoConta
          setAbrirSaldoConta={setAbrirConta}
          abrirSaldoConta={abrirConta}
          contaCorrente={contaCorrenteLinha}
          setLoadingComponent={setCarregandoRequisicao}
        />
      </LoadingOverlayComponents>
    </>
  );
};
