import React, { useState } from "react";
import './styles.css';

export const InfoIcon = ({ tooltipText, left }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="div_icon" onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            <p
                className="p_icon"
            >
                i
            </p>
            {showTooltip && <div className={left ? 'tooltip-left' : 'tooltip'}>{tooltipText}</div>}
        </div>
    )
}