import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getProjetoByNomeProjeto = (nomeProjeto) =>
  axios
    .get(`${url}/projetos/nome-projeto/${nomeProjeto}`)
    .then((response) => response.data);
