const INITIAL_STATE = {
    alertas: []
};

export const alertas = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_ALERTA':
            return { alertas: action.payload };
        default:
            return state
    }
}
