import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getAllParametrosSistema = (requestParams) =>
  axios
    .get(`${url}/parametros${handlePaginationUri(requestParams)}`)
    .then((response) => response.data);

export const saveParametroSistema = (parametroSistema) =>
  axios
    .post(`${url}/parametros`, parametroSistema)
    .then((response) => response.data);
