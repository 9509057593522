import _ from "lodash";
import moment from "moment";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addListaEmailCobInad,
  esconderLoading,
  exibirLoading,
  setFiltroEmailCobInad
} from "../../actions";
import {
  getContratoByName,
  getEmailManualCobranca,
  getListaEmailCobrancaInadimplente
} from "../../api";
import { DetalhesListaPorLinha } from "../../components/DetalhesListaPorLinha";
import { FiltroCobrancaInadimplente } from "../../components/FiltroCobrancaInadimplente";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from "../../context/ReduxContext";
import { paginationDefault } from "../../default/pagination";
import { formatISO8601ToUser, transformDateToEndOfDay } from "../../utils";
import { Navbar } from "../../components/Navbar";

export const EmailCobrancaInad = () => {
  const dispatch = useDispatch();
  const movListaEmailCobInadimplente = useSelector(
    (state) => state.grupoEconomico.listaEmailCobInadimp
  );
  const valueDropContrato = useSelector(
    (state) => state.filtroContratoDrop.nomeContrato
  );
  const idContrato = useSelector((state) => state.filtroEmailCob.idContrato);
  const contrato = useSelector((state) => state.filtroEmailCob.contrato);
  const numeroParcela = useSelector(
    (state) => state.filtroEmailCob.numeroParcela
  );
  const numeroContrato = useSelector(
    (state) => state.filtroEmailCob.numeroContrato
  );

  const dataEmailEdit = useSelector(
    (state) => state.filtroEmailCob.dataEnvioEmail
  )
  const vencimentoPlanejadoEdit = useSelector(
    (state) => state.filtroEmailCob.vencimentoPlanejado
  );

  const [dataEnvioEmail, setDataEnvioEmail] = useState(new Date());
  const [vencimentoPlanejado, setVencimentoPlanejado] = useState(new Date());

  useEffect(() => {
    if (dataEmailEdit !== null) {
      setDataEnvioEmail(dataEmailEdit)
    }

  }, [dataEmailEdit])

  useEffect(() => {
    if (vencimentoPlanejadoEdit !== null) {
      setVencimentoPlanejado(vencimentoPlanejadoEdit)
    }

  }, [vencimentoPlanejadoEdit])

  const automatizarCob = useSelector(
    (state) => state.filtroEmailCob.automatizarCob
  );
  const vencimentoOriginal = useSelector(
    (state) => state.filtroEmailCob.vencimentoOriginal
  );
  const [abrirFiltro, setAbrirFiltro] = useState(false);
  const toast = useRef(null);

  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState(false);
  const reduxStateAtual = useSelector(state => state.filtroEmailCob);
  const reduxStateInicial = useContext(ReduxContext);
  const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroEmailCob)
  const [clearInputs, setClearInputs] = useState(false);
  console.log('filterActive', filterActive)

  async function clearFilter() {
    const reduxObj = reduxStateAtual
    const reduxObj2 = reduxStateInicial.filtroEmailCob
    const obj1 = JSON.stringify(reduxObj)
    const obj2 = JSON.stringify(reduxObj2)
    const filterActive = obj1 === obj2
    setVisible(!filterActive)
    setClear(true)
    setClearInputs(true)
    const filtroEmailCob = { ...reduxStateInicial.filtroEmailCob };
    filtroEmailCob.automatizarCob = "TODOS"
    filtroEmailCob.vencimentoPlanejado = null
    filtroEmailCob.dataEnvioEmail = null
    setDataEnvioEmail(null)
    setVencimentoPlanejado(null)
    dispatch(setFiltroEmailCobInad(filtroEmailCob));
  }

  const [exibirDetalhesLinha, setExibirDetalhesLinha] = useState(false);
  const [conteudoPorLinha, setConteudoPorLinha] = useState(null);
  const [dialogEmailCobranca, setDialogEmailCobranca] = useState(false);
  const [listaContratoDrop, setListaContratoDrop] = useState([]);
  const [nomeContratoManual, setNomeContratoManual] = useState(null);
  const [idMovimentacaoManual, setIdMovimentacaoManual] = useState(null);
  const [idContratoManual, setIdContratoManual] = useState(null);
  const [emailCobrancaManual, setEmailCobrancaManual] = useState(null);
  const [movimentacaoParcelaManual, setMovimentacaoParcelaManual] =
    useState(null);
  const [numeroContratoManual, setNumeroContratoManual] = useState(null);
  const [vencimentoManual, setVencimentoManual] = useState(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(paginationDefault);

  const selecionarDetalhesPorLinha = (rowData) => {
    setExibirDetalhesLinha(true); //abre a linha.
    setConteudoPorLinha(rowData); //passa todo o conteúdo da linha.
  };

  const modalEmailManual = (rowData) => {
    console.log("selecionarDetalhesPorLinhaManual: ", rowData);
    setDialogEmailCobranca(true); //abre a linha.
    setNomeContratoManual(rowData.nomeContratoParcela);
    setIdContratoManual(rowData.idContrato);
    setIdMovimentacaoManual(rowData.idMovimentacao);
    setEmailCobrancaManual(rowData.parcelaContrato?.contrato.emailCobranca);
    setMovimentacaoParcelaManual(rowData.movimentacaoParcela);
    setNumeroContratoManual(rowData.numeroContrato);
    setVencimentoManual(rowData.vencimentoOriginal);
  };

  async function envioManualDeEmail() {
    let formato = moment(vencimentoManual).format("YYYY-MM-DDT00:00:00.000");
    let idFormatado = parseInt(idMovimentacaoManual);
    setCarregandoRequisicao(true);
    try {
      await getEmailManualCobranca(
        idFormatado,
        nomeContratoManual,
        emailCobrancaManual,
        movimentacaoParcelaManual,
        numeroContratoManual,
        formato
      ).then((resultado) => {
        toast.current.show({
          severity: "success",
          summary: "Sucesso",
          detail: `${resultado}`,
        });
      });
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao enviar E-mail",
      });
    } finally {
      setDialogEmailCobranca(false);
      setCarregandoRequisicao(false);
    }
  }

  const montarFiltro = useCallback(() => {
    let filtroEmailCob = {
      // idUsuario: null,
      idContrato,
      contrato,
      numeroContrato,
      numeroParcela,
      dataEnvioEmail: dataEnvioEmail === '' ? null : dataEnvioEmail === null ? null : transformDateToEndOfDay(dataEnvioEmail),
      vencimentoPlanejado: vencimentoPlanejado === '' ? null : vencimentoPlanejado,
      vencimentoOriginal,
      automatizarCob: automatizarCob === null ? true : automatizarCob === "TODOS" ? null : automatizarCob,
    };
    console.log('reduxObj', filtroEmailCob)
    let formatDataPlanejada = moment(vencimentoPlanejado);
    if (formatDataPlanejada.isValid()) {
      filtroEmailCob.vencimentoPlanejado = formatDataPlanejada.format(
        "YYYY-MM-DD"
      );
    }
    let formatDataOriginal = moment(vencimentoOriginal);
    if (formatDataOriginal.isValid()) {
      filtroEmailCob.vencimentoOriginal = formatDataOriginal.format(
        "YYYY-MM-DD"
      );
    }
    return filtroEmailCob;
  }, [
    idContrato,
    contrato,
    numeroContrato,
    numeroParcela,
    dataEnvioEmail,
    vencimentoPlanejado,
    vencimentoOriginal,
    automatizarCob
  ]);

  const  filtroListaEmailCobInadimp = useCallback(() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };
    getListaEmailCobrancaInadimplente(requestParams)
      .then((resultado) => {
        if (resultado.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "O filtro não obteve resultado",
          });
        }
        dispatch(esconderLoading());
        dispatch(addListaEmailCobInad(resultado.content));
        setTotalRecords(resultado.totalElements);
      })
      .catch(() => {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "A busca não obteve resultado",
        });
        dispatch(addListaEmailCobInad([]));
        dispatch(esconderLoading());
      });
    // getEmailAutomaticoCobranca().then((resultado) => {});
    setAbrirFiltro(false);
  }, [dispatch, lazyParams, montarFiltro])

  const copyReduxObjs = useCallback(() => {
    const reduxObj = montarFiltro();
    const reduxObj2 = reduxStateInicial?.filtroEmailCob;
    const copy1 = { ...reduxObj };
    const copy2 = { ...reduxObj2 };
    return _.isEqual(copy1, copy2);
  }, [montarFiltro, reduxStateInicial]);
  
  useEffect(() => {
    const areEqual = copyReduxObjs();
    setVisible(!areEqual);
    filtroListaEmailCobInadimp();
  }, [lazyParams, montarFiltro, copyReduxObjs, filtroListaEmailCobInadimp]);
  

  const columns = [
    {
      field: "parcelaContrato.contrato.id",
      header: "Código do Contrato",
      style: { width: "60px" }
    },
    {
      field: "nomeContratoParcela",
      header: "Contrato",
      style: { width: "100px" },
      type: "customEllipsis",
    },
    {
      field: "numeroContrato",
      header: "Número do Contrato",
      style: { width: "100px" },
      type: "customEllipsis",
    },
    {
      field: "parcelaContrato.numeroParcela",
      header: "Número da Parcela",
      style: { width: "100px" },
      type: "customEllipsis",
    },
    {
      field: "valorTotalContratoParcela",
      header: "Parcela",
      style: { width: "100px" },
      type: "customEllipsis",
    },
    {
      field: "vencimentoOriginal",
      header: "Vencimento",
      style: { width: "100px" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.vencimentoOriginal),
    },
    {
      field: "vencimentoPlanejado",
      header: "Vencimento Prorrogado",
      style: { width: "100px" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.vencimentoPlanejado),
    },
    {
      field: "dataLimiteEnvioEmail",
      header: "Limite de Envio do E-mail",
      style: { width: "100px" },
    },
    {
      field: "dataEnvioEmail",
      header: "Data Envio de E-mail",
      style: { width: "100px" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataEnvioEmail),
    },
  ];

  const modalEnviarEmail = [
    { label: "Número do Contrato:", value: idContratoManual },
    { label: "Nome do Contrato:", value: nomeContratoManual },
  ];

  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param !== null) {
      await endpoint(param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não encontrado. Tente novamente.`,
            });
          }
          setState(resultado);
        })
        .catch((error) => console.log(error));
    }
  }

  useEffect(() => {
    callFilterEndpoints(
      setListaContratoDrop,
      getContratoByName,
      valueDropContrato,
      "Contrato"
    );
  }, [valueDropContrato]);

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <DetalhesListaPorLinha
          setExibirDetalhesLinha={setExibirDetalhesLinha}
          exibirDetalhesLinha={exibirDetalhesLinha}
          conteudoPorLinha={conteudoPorLinha}
        />
        <ModalComponents
          onClick="sendEmail"
          visible={dialogEmailCobranca}
          onHide={() => setDialogEmailCobranca(false)}
          onClickCancelar={() => setDialogEmailCobranca(false)}
          onClickConfirmar={() => envioManualDeEmail()}
          descricao={modalEnviarEmail}
          datatesteidconfirm="buttonConfirmModal"
          datatesteid="buttonCancelModal"
        />
        <Table
          arrayData={movListaEmailCobInadimplente}
          loading={recarregando}
          headerTitle="Cobrança Inadimplente"
          columns={columns}
          onClickDetails={(rowData) => selecionarDetalhesPorLinha(rowData)}
          onClickSendEmail={(rowData) => modalEmailManual(rowData)}
          tooltipEdit="Movimentação"
          onClickFiltrar={() => setAbrirFiltro(true)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          // visible={visible}
          visible={visible}
        />
        <FiltroCobrancaInadimplente
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          setAbrirFiltro={setAbrirFiltro}
          abrirFiltro={abrirFiltro}
          getListaContrato={listaContratoDrop}
        />
      </LoadingOverlayComponents>
    </>
  );
};
