const INITIAL_STATE = {
    nomeTratamento: null,
};

export const filtroParceiroDrop = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_PARCEIRO_DROP":
            return { ...action.payload };
        default:
            return state;
    }
};
