import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getAllContas = () =>
  axios.get(`${url}/contas-corrente`).then((response) => response.data);

export const getContaCorrenteByNomeBanco = (contaCorrente) =>
  axios
    .get(`${url}/contas-corrente/nome-banco/${contaCorrente}`)
    .then((response) => response.data);

export const getAllFilterContaCorrente = (requestParams) =>
  axios
    .post(
      `${url}/contas-corrente/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const updateContaCorrente = (id, conta) =>
  axios
    .put(`${url}/contas-corrente/${id}`, conta)
    .then((response) => response.status);

export const deleteContaCorrente = (id) =>
  axios
    .delete(`${url}/contas-corrente/${id}`)
    .then((response) => response.status);

export const saveContaCorrente = (conta) =>
  axios.post(`${url}/contas-corrente`, conta).then((response) => response.data);

export const getContaCorrenteByNumeroBanco = (numerobanco) =>
  axios
    .get(`${url}/contas-corrente/numero-banco/${numerobanco}`)
    .then((response) => response.data);

export const getContaCorrenteByIdAndNomeBanco = (nomeBanco) =>
  axios
    .get(`${url}/contas-corrente/busca-banco-Nome?nomeBanco=${nomeBanco}`)
    .then((response) => response.data);

export const getContaCorrenteByDescricao = (descricao) =>
  axios
    .get(`${url}/contas-corrente/nome-descricao/${descricao}`)
    .then((response) => response.data);

export const getContaCorrenteByIdEmpresa = (idEmpresa) =>
  axios
    .get(`${url}/empresas/${idEmpresa}/contas-corrente`)
    .then((response) => response.data);

export const getFilterNumeroContaCorrente = (numeroContaCorrente) =>
  axios
    .get(`${url}/contas-corrente/numero-contaCorrente/${numeroContaCorrente}`)
    .then((response) => response.data);

export const getSaldoByContaId = (contaCorrenteSaldoId) =>
  axios
    .get(`${url}/contas-corrente-saldo/consultarContaCorrenteSaldo/${contaCorrenteSaldoId}`)
    .then((response) => response.data);

export const putSaldoContaCorrente = (id, saldoConta) =>
  axios
    .put(`${url}/contas-corrente-saldo/${id}`, saldoConta)
    .then((response) => response.data);

export const postSaldoContaCorrente = (id, saldoConta) =>
  axios
    .post(`${url}/contas-corrente-saldo/contaCorrenteSaldo/${id}`, saldoConta)
    .then((response) => response.data);

export const deleteSaldoContaCorrente = (id) =>
  axios.delete(`${url}/contas-corrente-saldo/deletarSaldoContaCorrete/${id}`).then((response) => response.status);