import { yupResolver } from "@hookform/resolvers/yup";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { setFiltroGrupoEconomico } from "../../actions";
import {
  getApiViaCep,
  saveGrupoEconomico,
  updateGrupoEconomico,
} from "../../api";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired, messageRequiredMinimumCharacters, messageValidEmail } from "../../default/messages";
import { ButtonComponent } from "../ButtonComponent";
import { Header } from "../Header";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import { EMAIL_REGEX } from "../../utils/regex";
import "./styles.css";

export const EditarGrupoEconomico = ({ editGrupoEconomico, page }) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  const history = useHistory();
  const [descricao, setDescricao] = useState(null);
  const [dialogCadastro, setDialogCadastro] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState(null);
  const [inscEstadual, setInscEstadual] = useState(null);
  const [inscMunicipal, setInscMunicipal] = useState(null);
  const [ufInscricao, setUfInscricao] = useState(null);
  const [logradouro, setLogradouro] = useState(null);
  const [numLogradouro, setNumLogradouro] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [estado, setEstado] = useState(null);
  const [pais, setPais] = useState(null);
  const [emailNotaFiscal, setEmailNotaFiscal] = useState(null);
  const [status, setStatus] = useState(null);
  const [ddd, setDdd] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [filial, setFilial] = useState(null);
  const [cep, setCep] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);

  const validationPost = yup.object({
    filial: yup.string().required(messageRequired),
    status: yup.string().required(messageRequired),
    razaoSocial: yup.string().required(messageRequired).trim(),
    nomeFantasia: yup.string().required(messageRequired).trim().min(3, messageRequiredMinimumCharacters("O Nome Fantasia", 2)),
    cnpj: yup.string().required(messageRequired),
    emailNotaFiscal: yup.string().email(messageValidEmail).required(messageRequired).matches(EMAIL_REGEX, messageValidEmail),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  const optionsStatus = [
    { name: "Ativo", value: "Ativo" },
    { name: "Inativo", value: "Inativo" },
  ];

  const optionsFilial = [
    { name: "Sim", value: "Sim" },
    { name: "Não", value: "Nao" },
  ];

  function settingCepDataWithApiReponse(data) {
    setCidade(data?.localidade);
    setLogradouro(data?.logradouro);
    setEstado(data?.uf);
    setComplemento(data?.complemento);
    setBairro(data?.bairro);
  }

  function viaCepGe() {
    if (cep?.length > 7 && cep?.length < 10) {
      getApiViaCep(cep).then((data) => {
        settingCepDataWithApiReponse(data);
      });
    }
  }

  useEffect(() => {
    if (editGrupoEconomico) {
      setDescricao(editGrupoEconomico.nomeFantasia);
      setRazaoSocial(editGrupoEconomico.razaoSocial);
      setInscEstadual(editGrupoEconomico.inscEstadual);
      setInscMunicipal(editGrupoEconomico.inscMunicipal);
      setUfInscricao(editGrupoEconomico.ufInscricao);
      setLogradouro(editGrupoEconomico.logradouro);
      setNumLogradouro(editGrupoEconomico.numLogradouro);
      setComplemento(editGrupoEconomico.complemento);
      setCidade(editGrupoEconomico.cidade);
      setEstado(editGrupoEconomico.estado);
      setPais(editGrupoEconomico.pais);
      setEmailNotaFiscal(editGrupoEconomico.emailNotaFiscal);
      setStatus(editGrupoEconomico.status);
      setDdd(editGrupoEconomico.ddd);
      setTelefone(editGrupoEconomico.telefone);
      setCnpj(editGrupoEconomico.cnpj);
      setFilial(editGrupoEconomico.filial);
      setCep(editGrupoEconomico.cep);
      setBairro(editGrupoEconomico.bairro);
      setValue("filial", editGrupoEconomico.filial);
      setValue("status", editGrupoEconomico.status);
      setValue("razaoSocial", editGrupoEconomico.razaoSocial);
      setValue("cnpj", editGrupoEconomico.cnpj);
      setValue("emailNotaFiscal", editGrupoEconomico.emailNotaFiscal);
      setValue("nomeFantasia", editGrupoEconomico.nomeFantasia);
    }
  }, [editGrupoEconomico, setValue]);

  function validandandoCpfCpnj() {
    if (cnpj) {
      return cnpj
        ?.replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "")
        .replaceAll("_", "");
    }
  }

  const userId = localStorage.getItem("loggerUser");
  const user = JSON.parse(userId);

  const grupoEconomico = {
    idUsuario: user?.userId,
    bairro: bairro,
    cep: cep,
    cidade: cidade,
    cnpj: validandandoCpfCpnj(),
    complemento: complemento,
    ddd: ddd,
    emailNotaFiscal: emailNotaFiscal,
    estado: estado,
    filial: filial,
    inscEstadual: inscEstadual,
    inscMunicipal: inscMunicipal,
    logradouro: logradouro,
    nomeFantasia: descricao,
    numLogradouro: numLogradouro,
    pais: pais,
    razaoSocial: razaoSocial,
    status: status,
    telefone: telefone,
    ufInscricao: ufInscricao,
  };

  const grupoEconomicoUpdate = {
    idUsuarioAlterado: user?.userId,
    bairro: bairro,
    cep: cep,
    cidade: cidade,
    cnpj: validandandoCpfCpnj(),
    complemento: complemento,
    ddd: ddd,
    emailNotaFiscal: emailNotaFiscal,
    estado: estado,
    filial: filial,
    inscEstadual: inscEstadual,
    inscMunicipal: inscMunicipal,
    logradouro: logradouro,
    nomeFantasia: descricao,
    numLogradouro: numLogradouro,
    pais: pais,
    razaoSocial: razaoSocial,
    status: status,
    telefone: telefone,
    ufInscricao: ufInscricao,
  };

  async function salvarEditarGrupoEconomico() {
    setCarregandoRequisicao(true);
    try {
      if (editGrupoEconomico === null || editGrupoEconomico === undefined) {
        await saveGrupoEconomico(grupoEconomico)
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Grupo Econômico salvo com sucesso!",
        });
      } else {
        await updateGrupoEconomico(editGrupoEconomico.id, grupoEconomicoUpdate)
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Grupo Econômico alterado com sucesso!",
        });
      }
      setDialogCadastro(false);
      dispatch(setFiltroGrupoEconomico(reduxStateInicial.filtroGE));
      setTimeout(() => {
        history.push("/grupos-economicos");
      }, 1500);
    } catch (e) {
      console.log(e);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  const onlyNumbersRegex = /^[0-9\b]+$/;

  const closeModal = () => {
    validationPost.isValid(grupoEconomico).then((response) => {
      if (!response) {
        setDialogCadastro(false);
      }
    });
  };

  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true)
    } else {
      history.push("/grupos-economicos", { page: page })
    }
  }

  const handleDddInput = (event) => {
    const value = event.target.value
    if (/[^0-9]/.test(value)) {
      toast.current.show({
        severity: "error",
        summary: "DDD Inválido",
        detail: "Insira um valor numérico válido para o campo 'DDD'",
        life: 5000,
      });
    } else {
      setDdd(value);
      setAlteracoes(true);
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  return (
    <>
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <div className="grupoEconomico__wrapper">
          <Toast ref={toast} />
          <ModalComponents
            visible={alteracoesFeitas}
            onHide={() => setAlteracoesFeitas(false)}
            onClickCancelar={() => setAlteracoesFeitas(false)}
            onClickConfirmar={() => {
              history.push("/grupos-economicos", { page: page })
            }}
            onClick="warning"
            descricao={modalWarning}
          />
          <ModalComponents
            title="Grupo Econômico"
            visible={dialogCadastro}
            editOrRegister={editGrupoEconomico}
            onHide={() => setDialogCadastro(false)}
            onClick="confirm"
            onClickCancelar={() => setDialogCadastro(false)}
            handleSubmit={handleSubmit}
            onClickConfirmarForm={salvarEditarGrupoEconomico}
            validationForm={closeModal}
            datatesteidconfirm={"confirmarModal"}
          />
          <Header
            title="Grupo Econômico"
            onClick={editGrupoEconomico ? "edit" : "add"}
          />
          <div className="grupoEconomicoForm">
            <form>
              <div className="grupoEconomicoForm--row grupoEconomicoForm__selectButtons--width">
                <div className="grupoEconomico__field">
                  <label>
                    Status <abbr className="form__asterisk">*</abbr>
                  </label>
                  <SelectButton
                    {...register("status")}
                    className="filter-select"
                    options={optionsStatus}
                    value={status}
                    onChange={(e) => {
                      setStatus(e.value);
                      setValue("status", e.value);
                      setAlteracoes(true)
                    }}
                    optionLabel="name"
                    optionValue="value"
                    data-testid="statusID"
                  />
                  <small className="form__errorMsg">
                    {errors.status?.message}
                  </small>
                </div>
                <div className="grupoEconomico__field">
                  <label>
                    Filial <abbr className="form__asterisk">*</abbr>
                  </label>
                  <SelectButton
                    {...register("filial")}
                    className="filter-select"
                    options={optionsFilial}
                    value={filial}
                    onChange={(e) => {
                      setFilial(e.value);
                      setValue("filial", e.value);
                      setAlteracoes(true)
                    }}
                    optionLabel="name"
                    optionValue="value"
                    data-testid="filialID"
                    disabled={editGrupoEconomico}
                  />
                  <small className="form__errorMsg">
                    {errors.filial?.message}
                  </small>
                </div>
              </div>

              <div className="grupoEconomico__field">
                <label>
                  Razão Social <abbr className="form__asterisk">*</abbr>
                </label>
                <InputTextarea
                  {...register("razaoSocial")}
                  field="razaoSocial"
                  value={razaoSocial}
                  onChange={(e) => {
                    setRazaoSocial(e.target.value)
                    setAlteracoes(true)
                  }}
                  maxlength="400"
                  rows={1}
                  cols={30}
                  autoResize
                  data-testid="razaoID"
                />
                <small className="form__errorMsg">
                  {errors.razaoSocial?.message}
                </small>
              </div>
              <div className="grupoEconomico__field">
                <label>
                  Nome Fantasia<abbr className="form__asterisk">*</abbr>
                </label>
                <InputTextarea
                  {...register("nomeFantasia")}
                  field="nomeFantasia"
                  value={descricao}
                  onChange={(e) => {
                    setDescricao(e.target.value)
                    setAlteracoes(true)
                  }}
                  maxlength="100"
                  rows={1}
                  cols={300}
                  data-testid="nomeFantasiaID"
                />
                <small className="form__errorMsg">
                  {errors.nomeFantasia?.message}
                </small>
              </div>
              <div className="grupoEconomicoForm--row">
                <div className="grupoEconomicoForm__section">
                  <div className="grupoEconomico__field">
                    <label>
                      CNPJ <abbr className="form__asterisk">*</abbr>
                    </label>
                    <InputMask
                      id="cnpj"
                      mask="99.999.999/9999-99"
                      value={cnpj}
                      onChange={(e) => {
                        setCnpj(e.value);
                        setValue("cnpj", e.value);
                        setAlteracoes(true)
                      }}
                      data-testid="cnpjID"
                    />
                    <small className="form__errorMsg">
                      {errors.cnpj?.message}
                    </small>
                  </div>
                  <div className="grupoEconomico__field">
                    <label>UF da Inscrição Municipal</label>
                    <InputText
                      field="ufInscricao"
                      value={ufInscricao}
                      onChange={(e) => {
                        setUfInscricao(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="2"
                      data-testid="ufID"
                    />
                  </div>
                  <div className="grupoEconomico__field">
                    <label data-testid="nomeCepID">CEP</label>
                    <InputText
                      field="cep"
                      value={cep}
                      onChange={(e) => {
                        setCep(e.target.value)
                        setAlteracoes(true)
                      }}
                      onBlur={viaCepGe}
                      maxLength={8}
                      data-testid="cepID"
                      keyfilter={onlyNumbersRegex}
                    />
                  </div>
                  <div className="grupoEconomico__field">
                    <label>Número do Logradouro</label>
                    <InputText
                      keyfilter="pint"
                      value={numLogradouro}
                      onChange={(e) => {
                        setNumLogradouro(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="10"
                      data-testid="numLogradouroID"
                    />
                  </div>
                  <div className="grupoEconomico__field">
                    <label>Cidade</label>
                    <InputText
                      field="grau"
                      value={cidade}
                      onChange={(e) => {
                        setCidade(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="200"
                      data-testid="cidadeID"
                    />
                  </div>
                  <div className="grupoEconomico__field">
                    <label>Bairro</label>
                    <InputText
                      field="bairro"
                      value={bairro}
                      onChange={(e) => {
                        setBairro(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="100"
                      data-testid="bairroID"
                    />
                  </div>
                </div>
                <div className="grupoEconomicoForm__section">
                  <div className="grupoEconomicoForm--row">
                    <div className="grupoEconomico__field">
                      <label>Inscrição Estadual</label>
                      <InputText
                        field="inscEstadual"
                        value={inscEstadual}
                        onChange={(e) => {
                          setInscEstadual(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="20"
                        data-testid="inscricaoEstadualID"
                      />
                    </div>
                    <div className="grupoEconomico__field">
                      <label>Inscrição Municipal</label>
                      <InputText
                        field="inscMunicipal"
                        value={inscMunicipal}
                        onChange={(e) => {
                          setInscMunicipal(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="20"
                        data-testid="inscricaoMunicipalID"
                      />
                    </div>
                  </div>
                  <div className="grupoEconomico__field">
                    <label>
                      E-mail para envio da Nota Fiscal
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <InputText
                      {...register("emailNotaFiscal")}
                      name="emailNotaFiscal"
                      field="emailNotaFiscal"
                      value={emailNotaFiscal}
                      onChange={(e) => {
                        setEmailNotaFiscal(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="200"
                      data-testid="emailID"
                    />
                    <small className="form__errorMsg">
                      {errors.emailNotaFiscal?.message}
                    </small>
                  </div>
                  <div className="grupoEconomico__field">
                    <label>Logradouro</label>
                    <InputTextarea
                      name="logradouro"
                      field="logradouro"
                      value={logradouro}
                      onChange={(e) => {
                        setLogradouro(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="300"
                      rows={1}
                      cols={30}
                      data-testid="logradouroID"
                    />
                  </div>
                  <div className="grupoEconomico__field">
                    <label>Complemento</label>
                    <InputText
                      value={complemento}
                      onChange={(e) => {
                        setComplemento(e.target.value)
                        setAlteracoes(true)
                      }}
                      maxlength="100"
                      data-testid="complementoID"
                    />
                  </div>
                  <div className="grupoEconomicoForm--row">
                    <div className="grupoEconomico__field">
                      <label>Estado</label>
                      <InputText
                        field="estado"
                        value={estado}
                        onChange={(e) => {
                          setEstado(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="2"
                        data-testid="estadoID"
                      />
                    </div>
                    <div className="grupoEconomico__field">
                      <label>País</label>
                      <InputText
                        field="pais"
                        value={pais}
                        onChange={(e) => {
                          setPais(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="10"
                        data-testid="paisID"
                      />
                    </div>
                  </div>
                  <div className="grupoEconomicoForm--row">
                    <div className="grupoEconomico__field">
                      <label>DDD</label>
                      <InputText
                        field="ddd"
                        value={ddd}
                        onChange={handleDddInput}
                        maxlength="2"
                        data-testid="dddID"
                      />
                    </div>
                    <div className="grupoEconomico__field">
                      <label>Telefone</label>
                      <InputText
                        keyfilter="pint"
                        value={telefone}
                        onChange={(e) => {
                          setTelefone(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="15"
                        data-testid="telefoneID"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="grupoEconomico__containerButtons">
              <ButtonComponent
                datatesteid="cancelButtonID"
                type="cancel"
                onClick={() => validateGoBack()}
              />

              <ButtonComponent
                datatesteid="confirmButtonID"
                type="confirm"
                onClick={() => setDialogCadastro(true)}
              />
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};
