import { Button } from "primereact/button";
import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import Atencao from "../../assets/triangulo.png";
import { AuthContext } from "../../context/AuthContext";
import "./styles.css";

export const NaoEncontrada = () => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  } else {
    return (
      <div className="nao-encontrada">
        <img src={Atencao} alt="Página não encontrada" />
        <h1>Ops!</h1>
        <p>A página que você está procurando não foi encontrada.</p>
        <Link to="/">
          <Button
            label="Voltar para a tela de login"
            className="btn--nao-encontrada"
          />
        </Link>
      </div>
    );
  }
};
