import React from "react";
import { EditarParametroSistema } from "../../components/EditarParametroSistema";

export const AlterarParametroSistema = (props) => {

    return (
        <>
            <EditarParametroSistema
                editarParametroSistema={props?.location?.state?.parametroSistema}
                page={props?.location?.state?.page}
            />
        </>
    )


}
