const INITIAL_STATE = {
  lista: []
};

export const contasCorrentes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_LISTA_CONTAS_CORRENTES':
      return { lista: action.payload, loading: false };
    case 'EXIBIR_LOADING_CC':
      return { ...state, loading: true };
    case 'ESCONDER_LOADING_CC':
      return { ...state, loading: false };
    case 'EXIBIR_CC_SALVA':
      return { ...state, alertaSalvo: true };
    case 'ESCONDER_CC_SALVA':
      return { ...state, alertaSalvo: false };
    default:
      return state
  }
}
