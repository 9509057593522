const INITIAL_STATE = {
  lista: [],
  listaRateio: [],
  centroDeCusto: []
};

export const movimentacoes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_LISTA_MOVIMENTACOES':
      return { lista: action.payload, loading: false };
    case 'ADD_LISTA_RATEIO':
      return { listaRateio: action.payload };
    case 'EXIBE_LOADING':
      return { ...state, loading: true };
    case 'ESCONDER_LOADING':
      return { ...state, loading: false };
    case 'ADD_CENTRO_DE_CUSTO':
      return { centroDeCusto: action.payload };

    default:
      return state
  }
}
