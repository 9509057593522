const INITIAL_STATE = {
    nomeFantasia: null,
};

export const grupoEconomicoFiltroDrop = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case "SET_FILTRO_GRUPO_ECONOMICO_DROP":
            return { ...action.payload };
        default:
            return state;
    }
};