import moment from "moment";
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import React, { useCallback, useEffect, useState } from "react";
import { getByIdMovimentacao } from "../../api";
import { formatValue } from "../../utils/value";
import { Loading } from "../Loading";
import "./styles.css";

export const DetalhesListaPorLinha = ({
  setExibirDetalhesLinha,
  exibirDetalhesLinha,
  conteudoPorLinha,
}) => {
  const [movimentacao, setMovimentacao] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const traco = "-"
  const idMovimentacao =
    conteudoPorLinha?.idMovimentacao || conteudoPorLinha?.id;
  const fecharModal = () => {
    setExibirDetalhesLinha(false);
  };

  console.log("mov==>>", movimentacao)

  const movimentacaoById = useCallback(() => {
    setError(false);
    setLoading(true);
    if (idMovimentacao !== undefined && idMovimentacao !== null) {
      getByIdMovimentacao(idMovimentacao)
        .then((resultado) => {
          setMovimentacao(resultado);
          setLoading(false);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    }
  }, [idMovimentacao]);

  useEffect(() => {
    if (idMovimentacao) {
      movimentacaoById();
    }
  }, [idMovimentacao, movimentacaoById]);

  const styles = {
    table: {
      borderCollapse: 'collapse',
      width: '300px',
    },
    th: {
      border: '1px solid black',
      padding: '8px',
      textAlign: 'center',
      backgroundColor: '#f2f2f2',
    },
    td: {
      border: '1px solid black',
      padding: '8px',
      textAlign: 'center',
    },
  };

  function validateReturn() {
    switch (true) {
      case loading:
        return (
          <div className="detalhes__loading">
            <Loading />
          </div>
        );
      case error:
        return <h4>Nenhum registro encontrado.</h4>;
      default:
        return (
          <>
            <div className="detalhes__header">
              <div className="detalhes__header--gap">
                <div>
                  {conteudoPorLinha && (
                    <p className={movimentacao?.tipoLancamento === 'DESPESA' ?
                      'detalhes__header--red' : 'detalhes__header--green'}>
                      {movimentacao?.tipoLancamento || traco}
                    </p>
                  )}
                </div>
                <div>
                  <span>Conciliado: </span>
                  <Checkbox
                    checked={movimentacao?.dataConciliado ? true : false}
                    disabled
                  />
                </div>
                <div>
                  <span>Baixado: </span>
                  <Checkbox
                    checked={movimentacao?.baixa ? true : false}
                    disabled
                  />
                </div>
                <div>
                  <span>Provisionado: </span>
                  <Checkbox
                    checked={movimentacao?.provisao ? true : false}
                    disabled
                  />
                </div>
                <div>
                  <span>Rateado: </span>
                  <Checkbox
                    checked={movimentacao?.rateado === "N" ? false : true}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="detalhes__body">
              <div>
                {conteudoPorLinha && (
                  <p>
                    <span>Projeto ERP Origem: </span>
                    {movimentacao?.codProjetoErpOrigem || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Centro de custos: </span>
                    {movimentacao?.centroCusto?.descricao || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Natureza: </span>
                    {movimentacao?.natureza?.naturezaHierarquica || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Parceiro: </span>
                    {movimentacao?.parceiro?.nomeTratamento || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Conta Corrente: </span>
                    {movimentacao?.contaCorrente?.descricao || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Replanejado: </span>
                    {movimentacao?.replanejado !== null ? movimentacao?.replanejado + ' vezes' : traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Dias de atraso: </span>
                    {movimentacao?.diasAtraso + ' dia(s)' || traco}
                  </p>
                )}
                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td style={styles.td}>Bruto</td>
                      <td style={styles.td}>{formatValue(movimentacao?.desdobramento ? movimentacao?.desdobramento : 0)}</td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Desconto (-)</td>
                      <td style={styles.td}>{formatValue(movimentacao?.desconto ? movimentacao?.desconto : 0)}</td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Juros (+)</td>
                      <td style={styles.td}>{formatValue(movimentacao?.juros ? movimentacao?.juros : 0)}</td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Multa (+)</td>
                      <td style={styles.td}>{formatValue(movimentacao?.multa ? movimentacao?.multa : 0)}</td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Baixa (+)</td>
                      <td style={styles.td}>{formatValue(movimentacao?.valorBaixa ? movimentacao?.valorBaixa : 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="detalhes__lastColumn">
                {conteudoPorLinha && (
                  <p>
                    <span>Histórico: </span>
                    {movimentacao?.historico || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Número do Cheque: </span>
                    {movimentacao?.numeroCheque || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Série da Nota: </span>
                    {movimentacao?.serieNota || traco}
                  </p>
                )}
                {conteudoPorLinha && (
                  <p>
                    <span>Parcela: </span>
                    {movimentacao?.parcela || traco}
                  </p>
                )}
                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td style={styles.td}>Negociação</td>
                      <td style={styles.td}>{movimentacao?.negociacao ?
                        moment(
                          movimentacao?.negociacao,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Cadastro</td>
                      <td style={styles.td}>{movimentacao?.cadastro ?
                        moment(
                          movimentacao?.cadastro,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Vencimento</td>
                      <td style={styles.td}>{movimentacao?.vencimentoOriginal ?
                        moment(
                          movimentacao?.vencimentoOriginal,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Venc. Prorrogado</td>
                      <td style={styles.td}>{movimentacao?.vencimentoPlanejado ?
                        moment(
                          movimentacao?.vencimentoPlanejado,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Faturamento</td>
                      <td style={styles.td}>{movimentacao?.emissao ?
                        moment(
                          movimentacao?.emissao,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Prazo</td>
                      <td style={styles.td}>{movimentacao?.prazo ?
                        moment(
                          movimentacao?.prazo,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.td}>Baixa</td>
                      <td style={styles.td}>{movimentacao?.baixa ?
                        moment(
                          movimentacao?.baixa,
                          "YYYY-MM-DDT00:00:00"
                        ).format("DD/MM/YYYY") : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {conteudoPorLinha && (
                  <p className="detalhes__lastColumn--align">
                    <span>Última alteração realizada em: </span>
                    {movimentacao?.ultimaAlteracao ?
                      moment(
                        movimentacao?.ultimaAlteracao,
                        "YYYY-MM-DDT00:00:00"
                      ).format("DD/MM/YYYY") : "-"}
                  </p>
                )}
              </div>
            </div >
          </>
        );
    }
  }
  return (
    <Dialog
      className="detalhes"
      header="Detalhes"
      visible={exibirDetalhesLinha}
      modal={true}
      onHide={() => setExibirDetalhesLinha(false)}
    >
      {validateReturn()}
      <div
        className="detalhes__containerButtons"
        data-testid="dialog-close-button"
      >
        <Button
          className="detalhes__button"
          label="Fechar"
          icon="pi pi-times-circle"
          onClick={fecharModal}
        />
      </div>
    </Dialog>
  );
};
