import { Dialog } from "primereact/dialog";
import React from "react";
import { ButtonComponent } from "../ButtonComponent";
import "./styles.css";

export const ModalComponents = ({
  visible,
  onHide,
  title,
  editOrRegister,
  conciliacao,
  descricao,
  onClickCancelar,
  onClickConfirmar,
  onClick,
  handleSubmit,
  onClickConfirmarForm,
  validationForm,
  datatesteid,
  datatesteidconfirm,
}) => {
  function validationType() {
    return editOrRegister ? "alteração" : "cadastro";
  }

  function validationTypeAction() {
    return conciliacao === true ? "Conciliação" : "Desconciliação";
  }

  const type = {
    confirm: `Deseja confirmar ${validationType()} de ${title}?`,
    edit: `Deseja confirmar alteração no ${title}?`,
    delete: `Deseja excluir ${title}?`,
    conciliation: `Deseja confirmar ${validationTypeAction()}?`,
    sendEmail: `Deseja confirmar envio do E-mail de Cobrança?`,
    warning: `Atenção`,
    custom: `${title}`,
  };

  return (
    <>
      <Dialog
        header={type[onClick]}
        className="modal__body"
        visible={visible}
        onHide={onHide}
        data-testid="modal_component"
      >
        <form
          onSubmit={onClickConfirmarForm && handleSubmit(onClickConfirmarForm)}
        >
          <div className="modal__description" data-testid="div_form_component">
            {descricao?.map((e) => (
              <span key={e.value}>
                <label
                  className="modal__description--labels"
                  data-testid="label_form_component"
                >
                  {e.label}
                </label>
                <label className="modal__description--labels">{e.value}</label>
              </span>
            ))}
          </div>
          <div className="modal__buttons" data-testid="button_form_component">
            <ButtonComponent
              type="cancel"
              onClick={onClickCancelar}
              datatesteid={datatesteid}
            />
            {validationForm ? (
              <ButtonComponent
                type="confirmForm"
                onClick={validationForm}
                datatesteid={datatesteidconfirm}
              />
            ) : (
              <ButtonComponent
                type="confirm"
                onClick={onClickConfirmar}
                datatesteid={datatesteidconfirm}
              />
            )}
          </div>
        </form>
      </Dialog>
    </>
  );
};
