import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import ReduxContext from './context/ReduxContext';
import './index.css';
import rootReducer from './reducers';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const store = createStore(rootReducer, applyMiddleware(thunk))
const reduxStateInicial = store.getState();

root.render(
    <ReduxContext.Provider value={reduxStateInicial}>
        <Provider store={store}>
            <App />
        </Provider>
    </ReduxContext.Provider>
);
