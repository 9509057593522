const INITIAL_STATE = {
  nomeContrato: null,
};

export const filtroContratoDrop = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_CONTRATO_DROP":
      return { ...action.payload };
    default:
      return state;
  }
};
