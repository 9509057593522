const INITIAL_STATE = {
  id: null,
  // idUsuario: null,
  nomeContrato: null,
  numeroContrato: null,
  tipoLancamento: null,
  valorTotal: null,
  numeroParcelas: null,
  status: null,
  nomeParceiro: null,
  empresaNomeFantasia: null,
  dataInicioPeriodo: null,
  dataFimPeriodo: null
};

export const filtroContrato = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_CONTRATO":
      return { ...action.payload };
    default:
      return state;
  }
};
