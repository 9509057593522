import { addLocale } from "primereact/api";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroParcela } from "../../actions";
import { paginationDefault } from "../../default/pagination";
import { brazilianCalendarParams, convertToISO8601 } from "../../utils";
import { Filtro } from "../Filtro";

export const FiltroParcela = ({
  setAbrirFiltroParcela,
  abrirFiltroParcela,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();
  addLocale("pt-br", brazilianCalendarParams);

  const [id, setId] = useState(useSelector((state) => state.filtroParcela.id));
  const [idContrato, setIdContrato] = useState(
    useSelector((state) => state.filtroParcela.id)
  );
  const [descricaoParcela, setDescricaoParcela] = useState(
    useSelector((state) => state.filtroParcela.descricaoParcela)
  );
  const [numeroParcela, setNumeroParcela] = useState(
    useSelector((state) => state.filtroParcela.numeroParcela)
  );
  const [valorParcela, setValorParcela] = useState(
    useSelector((state) => state.filtroParcela.valorParcela)
  );
  const [dataEmissao, setDataEmissao] = useState(
    useSelector((state) => state.filtroParcela.dataEmissao)
  );
  const [dataVencimentoPrevisto, setDataVencimentoPrevisto] = useState(
    useSelector((state) => state.filtroParcela.dataVencimentoPrevisto)
  );
  const [dataBaixa, setDataBaixa] = useState(
    useSelector((state) => state.filtroParcela.dataBaixa)
  );
  const [valorBaixa, setValorBaixa] = useState(
    useSelector((state) => state.filtroParcela.valorBaixa)
  );
  const [statusParcela, setStatusParcela] = useState(
    useSelector((state) => state.filtroParcela.statusParcela)
  );
  const dataCriacao = useSelector((state) => state.filtroParcela.dataCriacao);
  const dataAlteracao = useSelector(
    (state) => state.filtroParcela.dataAlteracao
  );

  const optionsStatus = [
    { name: "Em Aberto", value: "EM ABERTO" },
    { name: "Provisionado", value: "PROVISIONADO" },
    { name: "Conciliado", value: "CONCILIADO" },
  ];

  const filterFields = [
    {
      name: "status",
      label: "Status:",
      value: statusParcela,
      setState: setStatusParcela,
      type: "selectButton",
      options: optionsStatus,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      name: "id",
      label: "Código:",
      value: id,
      setState: setId,
      dataType: "pint",
      datatesteid: "idID",
    },
    {
      name: "descricao",
      label: "Descrição da Parcela:",
      value: descricaoParcela,
      setState: setDescricaoParcela,
      datatesteid: "descricaoID",
    },
    {
      name: "numParcelas",
      label: "Número da Parcela:",
      value: numeroParcela,
      setState: setNumeroParcela,
      dataType: "pint",
      datatesteid: "numParcelasID",
    },
    {
      name: "valorParcela",
      label: "Valor:",
      value: valorParcela,
      setState: setValorParcela,
      datatesteid: "valorParcelaID",
    },
    {
      label: "Emissão:",
      value: dataEmissao,
      setState: setDataEmissao,
      type: "date",
      datatesteid: "dataEmissaoID",
    },
    {
      label: "Vencimento:",
      value: dataVencimentoPrevisto,
      setState: setDataVencimentoPrevisto,
      type: "date",
      datatesteid: "dataVencPrevistoID",
    },
    {
      label: "Baixa:",
      value: dataBaixa,
      setState: setDataBaixa,
      type: "date",
      datatesteid: "dataBaixaID",
    },
    {
      name: "valorBaixa",
      label: "Valor da Baixa:",
      value: valorBaixa,
      setState: setValorBaixa,
      datatesteid: "valorBaixaID",
    },
  ];

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroParcela({
        id: id !== "" ? id : null,
        idContrato: idContrato !== "" ? idContrato : null,
        descricaoParcela: descricaoParcela !== "" ? descricaoParcela : null,
        numeroParcela: numeroParcela !== "" ? numeroParcela : null,
        valorParcela: valorParcela !== "" ? valorParcela : null,
        dataEmissao: dataEmissao !== "" ? dataEmissao : null,
        dataVencimentoPrevisto:
          dataVencimentoPrevisto !== "" ? dataVencimentoPrevisto : null,
        dataBaixa: dataBaixa !== "" ? convertToISO8601(dataBaixa) : null,
        valorBaixa: valorBaixa !== "" ? valorBaixa : null,
        statusParcela: statusParcela !== "" ? statusParcela : null,
        dataCriacao: dataCriacao !== "" ? dataCriacao : null,
        dataAlteracao: dataAlteracao !== "" ? dataAlteracao : null,
      })
    );
    setAbrirFiltroParcela(false);
  };

  const limpar = useCallback(() => {
    setId("");
    setIdContrato("");
    setDescricaoParcela("");
    setNumeroParcela("");
    setValorParcela("");
    setDataEmissao("");
    setDataVencimentoPrevisto("");
    setDataBaixa("");
    setValorBaixa("");
    setStatusParcela("");
    setVisible(() => false)
    setClearInputs(false)
  },[setVisible, setClearInputs]);

  useEffect(() => {
    if (clear === true) {
      limpar()
    }
  }, [clear, limpar])

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <Filtro
      filterFields={filterFields}
      setIsFiltroVisible={setAbrirFiltroParcela}
      isFiltroVisible={abrirFiltroParcela}
      clearFilter={limpar}
      handleConfirmFilter={filtrar}
    />
  );
};
