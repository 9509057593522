import moment from "moment";
import { Dialog } from "primereact/dialog";
import React from "react";
import "./styles.css";

export const DialogDetails = ({
  details,
  detailsColumns,
  isVisible,
  onHide,
}) => {

  console.log("details: ", details )
  console.log("detailsColumn: ", detailsColumns )
  function formatarData(data) {
    return moment(data).format("DD/MM/YYYY");
  }

  const renderDetails = detailsColumns?.map((d, __, index) => {
    return (
      <div key={index}>
        {d.map((item) => {
          let value;
          switch (item.type) {
            case "date":
              if (details[item.field]) {
                value = (
                  <p key={item.id}>{formatarData(details[item.field])}</p>
                );
              } else {
                value = <p key={item.id}>-</p>;
              }
              break;
            default:
              value = <p key={item.id}>{details[item.field]}</p>;
          }

          return (
            <div className="dialogDetails__field" key={item.id}>
              <p>{item.label + ":"}</p>
              {value}
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <Dialog
      className="dialogDetails__container"
      header="Detalhes"
      visible={isVisible}
      onHide={onHide}
    >
      <div className="dialogDetails__sections">{renderDetails}</div>
    </Dialog>
  );
};
