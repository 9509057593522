import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCentroDeCustoByDescricaoEAtivo,
  getContaCorrenteByDescricao,
  getEmpresaByNomeFantasiaEAtivo,
  getNaturezaByNomeNaturezaHierarquicaEAtiva,
  getParceiroByNomeTratamentoEAtivo,
  getParcelaByDescricao,
  getProjetoByNomeProjeto,
} from "../../api";
import { RateioTelaEditarAdicLista } from "../../components/RateioTelaEditarAdicLista";
import { RegistrarEditarLista } from "../../components/RegistrarEditarLista";

export const SalvarEditar = (props) => {
  const { idMovimentacao } = useParams();
  console.log("idMovimentacaoidMovimentacao", idMovimentacao);
  const [abrirRateio, setAbrirRateio] = useState(false);
  const [movimentacao, setMovimentacao] = useState("");
  const [listaParceiros, setListaParceiros] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaContasCorrentes, setListaContasCorrentes] = useState([]);
  const [listaNaturezaHierarquica, setListaNaturezaHierarquica] = useState([]);
  
  const [listaCentroDeCusto, setListaCentroDeCusto] = useState([]);
  const [listaProjeto, setListaProjeto] = useState([]);
  const [listaParcela, setListaParcela] = useState([]);
  const valueDropParceiro = useSelector(
    (state) => state.filtroParceiroDrop.nomeTratamento
  );
  const valueDropEmpresa = useSelector(
    (state) => state.empresaFiltroDrop.nomeFantasia
  );
  const valueDropContaCorrente = useSelector(
    (state) => state.descricaoContaCorrenteFiltroDrop.descricao
  );
  const valueDropNatureza = useSelector(
    (state) => state.filtroNaturezaDrop.nomeNaturezaHierarquica
  );
  const valueDropCentroDeCusto = useSelector(
    (state) => state.filtroCentroDeCustoDrop.descricao
  );
  const valueDropProjeto = useSelector(
    (state) => state.filtroProjetoDrop.nomeProjeto
  );
  const valueDropParcela = useSelector(
    (state) => state.descricaoParcelaContratoDrop.descricao
  );
  const toast = useRef(null);

  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param !== null) {
      await endpoint(param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não ${
                filterBy === "Natureza" ? "encontrada" : "encontrado"
              }. Tente novamente.`,
            });
          } else {
            setState(resultado);
          }
        })
        .catch((error) => {
          console.log(error);
        })

    }
  }

  useEffect(() => {
    callFilterEndpoints(
      setListaParceiros,
      getParceiroByNomeTratamentoEAtivo,
      valueDropParceiro,
      "Parceiro"
    );
  }, [valueDropParceiro]);

  useEffect(() => {
    callFilterEndpoints(
      setListaEmpresas,
      getEmpresaByNomeFantasiaEAtivo,
      valueDropEmpresa,
      "Empresa"
    );
  }, [valueDropEmpresa]);

  useEffect(() => {
    callFilterEndpoints(
      setListaContasCorrentes,
      getContaCorrenteByDescricao,
      valueDropContaCorrente,
      "Conta Corrente"
    );
  }, [valueDropContaCorrente]);

  useEffect(() => {
    callFilterEndpoints(
      setListaNaturezaHierarquica,
      getNaturezaByNomeNaturezaHierarquicaEAtiva,
      valueDropNatureza,
      "Natureza"
    );
  }, [valueDropNatureza]);

  useEffect(() => {
    callFilterEndpoints(
      setListaCentroDeCusto,
      getCentroDeCustoByDescricaoEAtivo,
      valueDropCentroDeCusto,
      "Centro de Custo"
    );
  }, [valueDropCentroDeCusto]);

  useEffect(() => {
    callFilterEndpoints(
      setListaProjeto,
      getProjetoByNomeProjeto,
      valueDropProjeto,
      "Projeto"
    );
  }, [valueDropProjeto]);

  useEffect(() => {
    callFilterEndpoints(
      setListaParcela,
      getParcelaByDescricao,
      valueDropParcela,
      "Parcela"
    );
  }, [valueDropParcela]);

  return (
    <>
      <Toast ref={toast} />
      <RegistrarEditarLista
        idMovimentacao={idMovimentacao}
        // idMovimentacao={props?.location?.state?.id}
        setAbrirRateio={setAbrirRateio}
        setMovimentacao={setMovimentacao}
        getListaParceiros={listaParceiros}
        getListaEmpresas={listaEmpresas}
        getListaContasCorrentes={listaContasCorrentes}
        getNaturezaHierarquica={listaNaturezaHierarquica}
        getCentroDeCusto={listaCentroDeCusto}
        getListaProjetos={listaProjeto}
        getListaParcelas={listaParcela}
        page={props?.location?.state?.page}
      />

      <RateioTelaEditarAdicLista
        listaNaturezas={listaNaturezaHierarquica}
        listaParceiros={listaParceiros}
        listaProjetos={listaProjeto}
        setAbrirRateio={setAbrirRateio}
        abrirRateio={abrirRateio}
        movimentacao={movimentacao}
      />
    </>
  );
};
