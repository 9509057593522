const INITIAL_STATE = {
  listaGP: [],
  empresa: [],
  produtoServico: [],
  listaContrato: [],
  listaParcelas: [],
  listaAnexos: [],
  listaEmailCobInadimp: [],
  listaEmailsEnviados: [],
  listaParceiros: [],
};

export const grupoEconomico = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_MOVIMENTACAO_GRUPO_ECONOMICO':
      return { listaGP: action.payload, loading: false };
    case 'ADD_MOV_EMPRESA':
      return { empresa: action.payload, loading: false };
    case 'ADD_MOV_PRODUTO_SERVICO':
      return { produtoServico: action.payload, loading: false };
    case 'EXIBE_LOADING':
      return { ...state, loading: true };
    case 'ESCONDER_LOADING':
      return { ...state, loading: false };
    case 'ADD_LISTA_CONTRATO':
      return { listaContrato: action.payload, loading: false };
    case 'ADD_LISTA_PARCELAS':
      return { listaParcelas: action.payload, loading: false };
    case 'ADD_LISTA_ANEXOS':
      return { listaAnexos: action.payload, loading: false };
    case 'ADD_LISTA_EMAIL_COB_INAD':
      return { listaEmailCobInadimp: action.payload, loading: false };
    case 'ADD_LISTA_EMAILS_ENVIADOS':
      return { listaEmailsEnviados: action.payload, loading: false };
    case 'ADD_LISTA_PARCEIROS':
      return { listaParceiros: action.payload, loading: false };
    default:
      return state
  }
}
