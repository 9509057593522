const INITIAL_STATE = {
  descricao: null,
};

export const descricaoProdFiltroDrop = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case "SET_FILTRO_DESCRICAO_PROD_DROP":
      return { ...action.payload };
    default:
      return state;
  }
};
