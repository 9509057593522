const INITIAL_STATE = {
    id: null,
    descricao: null,
    status: null,
    // idUsuario: null
};

export const filtroCentroDeCusto = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_CENTRO_DE_CUSTO":
            return { ...action.payload };
        default:
            return state;
    }
};
