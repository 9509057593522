import React from "react";
import "./style.css";

export const Loading = () => {
    return (
        <div>
            <div className="loading" data-testid="loading"/>
        </div>
    )
}
