const INITIAL_STATE = {
  totalizadores: []
};

export const totalizadores = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_LISTA_TOTALIZADORES':
      return { totalizadores: action.payload };
    default:
      return state
  }
}
