import React from "react";
import { EditarEmpresa } from "../../components/EditarEmpresa";

export const AlterarEmpresa = (props) => {

    return (
        <>
            <EditarEmpresa
                editEmpresa={props?.location?.state?.empresa}
                page={props?.location?.state?.page}
            />
        </>
    )
}
