import { InputNumber } from "primereact/inputnumber";
import React from "react";

export const CurrencyInput = ({
  datatestid,
  value,
  setValue,
  disabled,
  maxLength,
  className,
  onBlur,
  genericFunction = () => { },
}) => {
  return (
    <InputNumber
      className={className}
      data-testid={datatestid}
      value={value}
      onChange={(e) => {
        setValue(e.value);
        genericFunction();
      }}
      mode="currency"
      currency="BRL"
      locale="pt-BR"
      maxLength={maxLength}
      placeholder="R$ 0,00"
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};
