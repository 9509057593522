const INITIAL_STATE = {

  movimentacaoListaLancamento: [],
  listaParametroSistema: []
};

export const movimentacaoListaLancamento = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_MOVIMENTACAO_LISTA_LANCAMENTO':
      return { movimentacaoListaLancamento: action.payload };
    case 'ADD_LISTA_PARAMETRO_SISTEMA':
      return { listaParametroSistema: action.payload };
    case 'EXIBE_LOADING':
      return { ...state, loading: true };
    case 'ESCONDER_LOADING':
      return { ...state, loading: false };
    default:
      return state
  }
}
