import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getAllCentroDeCusto = (requestParams) =>
  axios
    .post(`${url}/centro-custos/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const getCentroDeCustoByDescricao = (descricao) =>
  axios
    .get(`${url}/centro-custos/filtro-descricao/${descricao}`)
    .then((response) => response.data);

export const getCentroDeCustoByDescricaoEAtivo = (descricao) =>
  axios
    .get(`${url}/centro-custos/filtro-descricao-ativo/${descricao}`)
    .then((response) => response.data);

export const deleteCentroCusto = (id) =>
  axios.delete(`${url}/centro-custos/${id}`).then((response) => response.data);

export const updateCentroCusto = (id, centroDeCusto) =>
  axios
    .put(`${url}/centro-custos/${id}`, centroDeCusto)
    .then((response) => response.data);

export const getCentroDeCustoByAtivos = () =>
  axios.get(`${url}/centro-custos/ativos`).then((response) => response.data);

export const saveCentroCusto = (centroDeCusto) =>
  axios
    .post(`${url}/centro-custos`, centroDeCusto)
    .then((response) => response.data);
