import React from "react";
import "./style.css";

export const Header = ({ title, titleItem, onClick, rightButton }) => {
  const typeTitle = {
    edit: "Editar",
    add: "Adicionar",
  };

  return (
    <header>
      <div className="header">
        <label className="header__label">
          {onClick && typeTitle[onClick]} {title} {titleItem}
        </label>
        {rightButton}
      </div>
      <div className="header__line" />
    </header>
  );
};
