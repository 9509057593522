export const addListaCentroCusto = (centroDeCusto) => ({
    type: "ADD_CENTRO_DE_CUSTO",
    payload: centroDeCusto,
});

export const setFiltroCentroDeCustoDrop = (centroDeCusto) => ({
    type: "SET_FILTRO_CENTRO_DE_CUSTO_DROP",
    payload: centroDeCusto,
});

export const setFilterCentroDeCusto = (filtroCentroDeCusto) => ({
    type: "SET_FILTRO_CENTRO_DE_CUSTO",
    payload: filtroCentroDeCusto,
  });