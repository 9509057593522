import React from 'react';
import "./styles.css";

export const InputFile = ({
    label,
    value,
    setState,
    type,
    required,
    style,
    maxLength,
    dataType,
    disabled,
    validacao,
    errorMessage,
    datatesteid,
    setAlteracoes = () => { }
}) => {
    return (
        <div className='inputFile__container--formInputs'>
              {required &&
                    <abbr data-testid="abbr-asteristic" className="form__asterisk">*</abbr>
                }
              <div class="custom-file">
                <input
                    type="file"
                    class="custom-file-input"
                    id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01" 
                    validacao
                    className='input__field'
                    onChange={(e) => {
                        setState(e.target.files[0])
                        setAlteracoes(true)
                    }}
                    maxLength={maxLength}
                    keyfilter={dataType}
                    disabled={disabled}
                    data-testid={datatesteid} 
                />
                <label
                  class="custom-file-label file-label"
                  for="inputGroupFile01"
                >
                  {value?.name || "Escolha um arquivo"}
                </label>
              </div>
               <small
                className="form__errorMsg"
            >
                {errorMessage}
            </small>
        </div>
    )
}
