export const setFiltroParcela = (filtroParcela) => ({
    type: "SET_FILTRO_PARCELA",
    payload: filtroParcela,
});

export const AddListaParcela = (listaParcela) => ({
    type: "ADD_LISTA_PARCELAS",
    payload: listaParcela,
});

export const setFiltroDescricaoParcelaDrop = (descricao) => ({
    type: "SET_FILTRO_DESCRICAO_PARCELA_DROP",
    payload: descricao,
});

export const setFiltroDescricaoParcelaContratoDrop = (descricao) => ({
    type: "SET_FILTRO_DESCRICAO_PARCELA_CONTRATO_DROP",
    payload: descricao,
});