import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getGrupoEconomicoByNomeFantasia = (nomeFantasia) =>
  axios
    .get(`${url}/grupos-economicos/nome-fantasia/${nomeFantasia}`)
    .then((response) => response.data);


export const getGrupoEconomicoByNomeFantasiaEAtivo = (nomeFantasia) =>
  axios
  .get(`${url}/grupos-economicos/nome-fantasia-ativo/${nomeFantasia}`)
  .then((response) => response.data);

export const getAllFilterGrupoEconomico = (requestParams) =>
  axios
    .post(
      `${url}/grupos-economicos/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const deleteGrupoEconomico = (id) =>
  axios.delete(`${url}/grupos-economicos/${id}`);

export const saveGrupoEconomico = (grupoEconomico) =>
  axios
    .post(`${url}/grupos-economicos`, grupoEconomico)
    .then((response) => response.data);

export const updateGrupoEconomico = (id, grupoEconomico) =>
  axios
    .put(`${url}/grupos-economicos/${id}`, grupoEconomico)
    .then((response) => response.data);

export const getAllGrupoEconomico = () =>
  axios.get(`${url}/grupos-economicos`).then((response) => response.data);
