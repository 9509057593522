import { Button } from "primereact/button";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { esconderLoading, exibirLoading } from "../../actions";
import { getByIdDetalhesEmailsEnviados } from "../../api";
import { DetalhesListaPorLinha } from "../../components/DetalhesListaPorLinha";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";

export const DetalhesEmailsEnviados = (props) => {
  const dispatch = useDispatch();

  const idMovimentacao = props?.location?.state?.id;

  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );

  const [conteudoPorLinha, setConteudoPorLinha] = useState({});

  const [exibirDetalhesLinha, setExibirDetalhesLinha] = useState(false);

  const [detalhesEmailsEnviados, setDetalhesEmailsEnviados] = useState([]);

  function principal(rowData) {
    if (rowData.status === "ENVIADO" || rowData.status === "enviado") {
      return (
        <Button
          className="formattedStyles"
          tooltip="Sucesso no Envio de E-mail"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillLike
            data-testid="success-icon"
            className="formattedStyles--check"
            alt="Ícone de sucesso"
            title="Sucesso no Envio de E-mail"
            size={15}
          />
        </Button>
      );
    } else {
      return (
        <Button
          className="formattedStyles"
          tooltip="Erro no Envio de E-mail"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillDislike
            data-testid="fail-icon"
            className="formattedStyles--times"
            alt="Ícone de erro"
            title="Erro no Envio de E-mail"
            size={15}
          />
        </Button>
      );
    }
  }

  const selecionarDetalhesPorLinha = (rowData) => {
    setExibirDetalhesLinha(true); //abre a linha.
    setConteudoPorLinha(rowData);
    //     getMovimentacaoListaLancamento({ idMov: rowData.idMovimentacao }).then(response => {
    //         setConteudoPorLinha(response[0])
    //     })
  };

  const detalhesEmailsEnviadosTable = useCallback(() => {
    dispatch(exibirLoading());
    getByIdDetalhesEmailsEnviados(idMovimentacao)
      .then((resultado) => {
        if (resultado.length !== 0) {
          console.log('detalhesEmailsEnviadosdetalhesEmailsEnviados', resultado)
          setDetalhesEmailsEnviados(resultado);
        }
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        dispatch(esconderLoading());
      });
  }, [dispatch, idMovimentacao]);

  useEffect(() => {
    detalhesEmailsEnviadosTable();
  }, [detalhesEmailsEnviadosTable]);

  const columns = [
    { field: "id", header: "Código", style: { maxWidth: "5vw" } },
    {
      field: "dataHoraEnvio",
      header: "Data / Hora",
      style: { maxWidth: "10vw" },
    },
    {
      field: "mensagem",
      header: "Mensagem",
      style: { maxWidth: "20vw" },
      type: "customEllipsisLeft",
    },
    {
      field: "resposta",
      header: "Resposta",
      style: { maxWidth: "20vw" },
      type: "customEllipsis",
    },
    { field: "assunto", header: "Assunto", style: { maxWidth: "15vw" } },
    { field: "remetente", header: "Remetente", style: { maxWidth: "15vw" } },
    {
      field: "destinatario",
      header: "Destinatário",
      style: { maxWidth: "11vw" },
    },
    {
      field: "status",
      header: "Status",
      style: {
        maxWidth: "6vw",
        textAlign: "center",
      },
      type: "custom",
      customFunc: principal,
    },
  ];

  console.log('detalhesEmailsEnviadosdetalhesEmailsEnviados', detalhesEmailsEnviados)

  return (
    <>
      <DetalhesListaPorLinha
        setExibirDetalhesLinha={setExibirDetalhesLinha}
        exibirDetalhesLinha={exibirDetalhesLinha}
        conteudoPorLinha={conteudoPorLinha}
      />
      <ReactHintComponents />
      <Table
        arrayData={detalhesEmailsEnviados}
        loading={recarregando}
        headerTitle="Detalhes E-mails Enviados"
        columns={columns}
        onClickVoltar
        page={props?.location?.state?.page}
        onClickDetails={(rowData) => selecionarDetalhesPorLinha(rowData)}
        tooltipEdit="Movimentação"
        data={props?.location?.state}
      />
    </>
  );
};
