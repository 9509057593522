import _ from 'lodash';
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addMovimentacaoGrupoEconomico,
  esconderLoading,
  exibirLoading,
  setFiltroGrupoEconomico,
} from "../../actions";
import { deleteGrupoEconomico, getAllFilterGrupoEconomico } from "../../api";
import { FiltroGrupoEconomico } from "../../components/FiltroGrupoEconomico";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { formatCnpj } from "../../utils/cpfCnpj";
import { Navbar } from '../../components/Navbar';

export const GrupoEconomico = (props) => {
  const page = props?.location?.state?.page
  const dispatch = useDispatch();
  const reduxStateAtual = useSelector(state => state.filtroGE);
  const reduxStateInicial = useContext(ReduxContext);
  console.log('reduxStateInicial', reduxStateInicial?.filtroGE)
  console.log('reduxStateAnual', reduxStateAtual)
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState('');
  const [clearInputs, setClearInputs] = useState(false);
  console.log(visible)

  async function clearFilter() {
    const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial.filtroGE)
    if (filterActive === false) {
      setClear(true)
      dispatch(setFiltroGrupoEconomico(reduxStateInicial.filtroGE));
      // setVisible(false)
    } else {
      setClear(false)
    }
  }

  const movimentacaoGrupoEconomico = useSelector(
    (state) => state.grupoEconomico.listaGP
  );

  const nomeFantasia = useSelector((state) => state.filtroGE.nomeFantasia);
  const razaoSocial = useSelector((state) => state.filtroGE.razaoSocial);
  const id = useSelector((state) => state.filtroGE.id);
  const cnpj = useSelector((state) => state.filtroGE.cnpj);
  const inscEstadual = useSelector((state) => state.filtroGE.inscEstadual);
  const inscMunicipal = useSelector((state) => state.filtroGE.inscMunicipal);
  const ufInscricao = useSelector((state) => state.filtroGE.ufInscricao);
  const logradouro = useSelector((state) => state.filtroGE.logradouro);
  const numLogradouro = useSelector((state) => state.filtroGE.numLogradouro);
  const complemento = useSelector((state) => state.filtroGE.complemento);
  const cidade = useSelector((state) => state.filtroGE.cidade);
  const estado = useSelector((state) => state.filtroGE.estado);
  const pais = useSelector((state) => state.filtroGE.pais);
  const emailNotaFiscal = useSelector(
    (state) => state.filtroGE.emailNotaFiscal
  );
  const status = useSelector((state) => state.filtroGE.status);
  const ddd = useSelector((state) => state.filtroGE.ddd);
  const telefone = useSelector((state) => state.filtroGE.telefone);
  const filial = useSelector((state) => state.filtroGE.filial);
  const [abrirFiltroGe, setAbrirFiltroGe] = useState(false);

  const history = useHistory();
  const toast = useRef(null);
  const [excluirGrupoEconomico, setExcluirGrupoEconomico] = useState(""); //excluir linha
  const [visualizarDescricaoModal, setVisualizarDescricaoModal] = useState("");
  const [dialogExcluirGrupoEconomico, setDialogExcluirGrupoEconomico] =
    useState(false); //dialog de excluir linha
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);

  const callBackLazyParams = useCallback((page) => {
    setLazyParams(page)
  },
    [])

  useEffect(() => {
    if (page) {
      callBackLazyParams(page)
    }
  }, [page, callBackLazyParams])

  function functionExcluirGrupoEconomico(rowData) {
    setDialogExcluirGrupoEconomico(true);
    setExcluirGrupoEconomico(rowData.id);
    setVisualizarDescricaoModal(rowData.nomeFantasia);
  }

  function abrirGpComId(rowData) {
    history.push(`/grupo-economico/${rowData.id}`, { page: lazyParams, grupoEconomico: rowData });
  }

  const deleteGP = async (rowData) => {
    setCarregandoRequisicao(true);
    try {
      await deleteGrupoEconomico(rowData).then(() => {
        dispatch(setFiltroGrupoEconomico(reduxStateInicial.filtroGE))
        setClearInputs(true)
      });
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: 'Grupo Econômico excluído com sucesso!',
        life: 5000,
      });
      setDialogExcluirGrupoEconomico(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Info",
        life: 8000,
        detail: error?.response?.data,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  };

  function iconFilial(rowData) {
    if (rowData.filial === "Sim") {
      return (
        <Button
          className="opcoes-lupa p-button-secondary"
          tooltip="Sim"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillLike
            className="p-button-secondary"
            alt="Tem Filial"
            size={15}
            color="#5CB85C"
          />
        </Button>
      );
    } else if (rowData.filial === "Nao") {
      return (
        <Button
          className="opcoes-lupa p-button-secondary"
          tooltip="Não"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillDislike
            className="p-button-secondary"
            size={15}
            color="#CA4141"
            alt="Não tem Filial"
          />
        </Button>
      );
    }
  }

  function verificarStatus(rowData) {
    //mostra icone se estiver analitica
    if (rowData.status === "Ativo") {
      return (
        <Button
          className="formattedStyles formattedStyles--check"
          icon="pi pi-check-circle"
          tooltip="Status Ativo"
        />
      );
    } else if (rowData.status === "Inativo") {
      return (
        <Button
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times-circle"
          tooltip="Status Inativo"
        />
      );
    }
  }

  const montarFiltro = useCallback(() => {
    const filtroGE = {
      // idUsuario: null,
      nomeFantasia,
      razaoSocial,
      id,
      cnpj: cnpj ? cnpj
      ?.replaceAll(".", "")
      .replaceAll("-", "")
      .replaceAll("/", "")
      .replaceAll("_", "") : null,
      inscEstadual,
      inscMunicipal,
      ufInscricao,
      logradouro,
      numLogradouro,
      complemento,
      cidade,
      estado,
      pais,
      emailNotaFiscal,
      status,
      ddd,
      telefone,
      filial,
    };
    return filtroGE;
  }, [
    nomeFantasia,
    razaoSocial,
    id,
    cnpj,
    inscEstadual,
    inscMunicipal,
    ufInscricao,
    logradouro,
    numLogradouro,
    complemento,
    cidade,
    estado,
    pais,
    emailNotaFiscal,
    status,
    ddd,
    telefone,
    filial,
  ]);

  const carregarGrupoEconomico = useCallback(() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };

    getAllFilterGrupoEconomico(requestParams).then((resultado) => {
      if (resultado.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "O filtro não obteve resultado",
        });
      }
      dispatch(addMovimentacaoGrupoEconomico(resultado.content));
      setTotalRecords(resultado.totalElements);
      dispatch(esconderLoading());
    });
    setAbrirFiltroGe(false);
  }, [dispatch, lazyParams, montarFiltro])

  useEffect(() => {
    const buttonClearFilter = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroGE)
    setVisible(!buttonClearFilter)
    carregarGrupoEconomico();
  }, [lazyParams, montarFiltro, carregarGrupoEconomico, reduxStateAtual, reduxStateInicial]);

  const columns = [
    { field: "id", header: "Código", style: { minWidth: 50, maxWidth: "5vw" } },
    {
      field: "nomeFantasia",
      header: "Nome Fantasia",
      style: { minWidth: 125, maxWidth: "20vw" },
      type: "customEllipsis",
    },
    {
      field: "razaoSocial",
      header: "Razão Social",
      style: { minWidth: 125, maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "cnpj",
      header: "CNPJ",
      style: { minWidth: 105, maxWidth: "10vw" },
      type: "custom",
      customFunc: (e) => formatCnpj(e.cnpj),
    },
    {
      field: "inscEstadual",
      header: "Inscrição Estadual",
      style: { minWidth: 115, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "inscMunicipal",
      header: "Inscrição Municipal",
      style: { minWidth: 115, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "emailNotaFiscal",
      header: "E-mail para Nota Fiscal",
      style: { minWidth: 140, maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "status",
      header: "Status",
      style: { minWidth: 60, maxWidth: "5vw" },
      type: "custom",
      customFunc: verificarStatus,
    },
    {
      field: "filial",
      header: "Tem Filial",
      style: { minWidth: 60, maxWidth: "5vw" },
      type: "custom",
      customFunc: iconFilial,
    },
  ];

  const modalExcluir = [
    { label: "Código:", value: excluirGrupoEconomico },
    { label: "Descrição:", value: visualizarDescricaoModal },
  ];

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <ModalComponents
          title="Grupo Econômico"
          visible={dialogExcluirGrupoEconomico}
          onHide={() => setDialogExcluirGrupoEconomico(false)}
          onClick="delete"
          descricao={modalExcluir}
          onClickCancelar={() => setDialogExcluirGrupoEconomico(false)}
          onClickConfirmar={() => deleteGP(excluirGrupoEconomico)}
          datatesteid="cancelButtonID"
        />
        <Table
          arrayData={movimentacaoGrupoEconomico}
          loading={recarregando}
          headerTitle="Grupo Econômico"
          columns={columns}
          onClickFiltrar={() => setAbrirFiltroGe(true)}
          onClickAdicionar={() => history.push("/grupo-economico/cadastro", { page: lazyParams })}
          onClickEdit={(rowData) => abrirGpComId(rowData)}
          tooltipEdit="Grupo Econômico"
          onClickDelete={(rowData) => functionExcluirGrupoEconomico(rowData)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
        <FiltroGrupoEconomico
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          setAbrirFiltroGe={setAbrirFiltroGe}
          abrirFiltroGe={abrirFiltroGe}
          setLazyParamsProp={setLazyParams}
        />
      </LoadingOverlayComponents>
    </>
  );
};
