import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilterCentroDeCusto } from "../../actions";
import { paginationDefault } from "../../default/pagination";
import { Filtro } from "../Filtro";

export const FiltroCentroDeCusto = ({
    filtroCentroDeCusto,
    setFiltroVisible,
    clear,
    setVisible,
    setLazyParamsProp,
    clearInputs,
    setClearInputs,
}) => {
    const dispatch = useDispatch();

    const [id, setId] = useState(useSelector((state) => state.filtroCentroDeCusto.id));
    const [descricao, setDescricao] = useState(
        useSelector((state) => state.filtroCentroDeCusto.descricao)
    );
    const [status, setStatus] = useState(
        useSelector((state) => state.filtroCentroDeCusto.status)
    );

    const optionsStatus = [
        { name: "Ambos", value: null },
        { name: "Ativo", value: "ATIVA" },
        { name: "Inativo", value: "INATIVA" },
    ];

    const filterFields = [
        {
            name: "id",
            label: "Código:",
            value: id,
            setState: setId,
            dataType: "pint",
            datatesteid: "idTest",
        },
        {
            name: "descricao",
            label: "Descrição:",
            value: descricao,
            setState: setDescricao,
        },
        {
            name: "status",
            label: "Status:",
            value: status,
            setState: setStatus,
            type: "selectButton",
            options: optionsStatus,
            optionLabel: "name",
            optionValue: "value",
        },
    ];

    const filtrar = () => {
        setLazyParamsProp(() => paginationDefault)
        setFiltroVisible(false);
        dispatch(
            setFilterCentroDeCusto({
                id: id === '' ? null : id,
                descricao: descricao === '' ? null : descricao,
                status: status
            })
        );
        setVisible(() => true)
    };

    const limpar = useCallback(() => {
        setId("");
        setDescricao("");
        setStatus(null);
        setVisible(() => false)
        setClearInputs(false)
    }, [setClearInputs, setVisible]);

    useEffect(() => {
        if (clear === true) {
          limpar()
        }
      }, [clear, limpar])
    
      useEffect(() => {
        if (clearInputs === true) {
          limpar()
        }
      }, [clearInputs, limpar])

    return (
        <Filtro
            filterFields={filterFields}
            setIsFiltroVisible={setFiltroVisible}
            isFiltroVisible={filtroCentroDeCusto}
            clearFilter={limpar}
            handleConfirmFilter={filtrar}
        />
    );
};
