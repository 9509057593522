import axios from "../../api/index"
import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { Toast } from 'primereact/toast';

const GlobalToast = () => {
    const toast = useRef(null);
    const [errorToastMessage, setErrorToastMessage] = useState(null);
    const {logout} = useContext(AuthContext)

    const callBackLogout = useCallback(() => {
        logout()
    }, [logout])

    useEffect(() => {
        const showErrorToast = async () => {
            try {
                if (errorToastMessage) {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: errorToastMessage,
                    });

                    setTimeout(() => {
                      callBackLogout();
                     }, 3000);
                }
            } catch (error) {
                console.error("Error displaying toast:", error);
            }
        };

        showErrorToast();
    }, [errorToastMessage, toast, callBackLogout]);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error && error.response && error.response.status === 403) {
                const userMessage = error.response.data.userMessage;
                setErrorToastMessage(userMessage);
            }
            return Promise.reject(error);
        }
    );

    return <Toast ref={toast} />;
};

export default GlobalToast;
