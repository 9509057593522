const INITIAL_STATE = {
  descricao: null,
  nomeBanco: null,
};

export const filtroContaCorrenteDrop = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_CONTA_CORRENTE_DROP":
      return { ...action.payload };
    default:
      return state;
  }
};
