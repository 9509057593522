import moment from "moment";

export function convertToISO8601(date) {
  if (date === null || undefined) {
    return null;
  }
  const moment = require("moment");
  return moment(date).format("YYYY-MM-DD");
}

export function formatISO8601ToUser(date) {
  const moment = require("moment");
  if (date) {
    return moment(date).format("DD/MM/YYYY");    
  }
  return '';
}

export function convertToLocalOffset(date) {
  if (date === null) {
    return null;
  }
  const offset = moment().format("Z");
  const dateWithOffset = moment(date)
    .utcOffset(offset)
    .format("YYYY-MM-DDTHH:mm:ssZ");

  return dateWithOffset;
}

export function autoFillDropdown({ list, setState, value }) {
  if (value.id) {
    const initialValue = list.find((item) => item.id === value.id);
    initialValue !== undefined && setState(initialValue);
  }
}

export function handlePaginationUri(requestParams) {
  return `?page=${requestParams?.paginationParams?.page}&size=${requestParams?.paginationParams?.rows}`;
}

export const brazilianCalendarParams = {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado",
  ],
  dayNamesShort: ["dom", "seg", "ter", "quar", "qui", "sex", "sáb"],
  dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  today: "Hoje",
  clear: "Claro",
};

export function dateFormattedForInput(data) {
  if (data) {
      const newDate = new Date(data.split("/").reverse().join("-") + "T00:00:00")
      return newDate
  }
};

export function transformDateToStartOfDay(dateStr) {
  const date = new Date(dateStr);
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
}

export function transformDateToEndOfDay(dateStr) {
  const date = new Date(dateStr);
  date.setUTCHours(23, 59, 0, 0);
  return date.toISOString();
}
