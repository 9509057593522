import axios from "axios";
import { Toast } from "primereact/toast";
import React, { createContext, useEffect, useRef, useState } from "react";
import { autenticarUsuario } from "../api/Autenticacao";
import { encodePassword } from "../service/encodePassword";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const salvarLogin = localStorage.getItem("isAuthenticated");
  const inicialState = salvarLogin ? JSON.parse(salvarLogin) : false;
  const toast = useRef(null);

  const [isAuthenticated, setIsAuthenticated] = useState(inicialState);

  const recoveredUser = localStorage.getItem("loggerUser");

  if (recoveredUser) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JSON.parse(recoveredUser).bearerToken}`;
  }
  
  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);
  

  const signIn = async (login, password) => {
    try {
      await autenticarUsuario(login, await encodePassword(password));

      if (isAuthenticated) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${JSON.parse(recoveredUser).bearerToken}`;
      }

      toast.current.show({
        severity: "success",
        summary: "Bem vindo",
        detail: "Login realizado com sucesso",
      });
      setIsAuthenticated(true);
      setTimeout(() => {
         window.location.href = "/home";
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Usuário ou senha inválidos",
      });
      setIsAuthenticated(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('loggerUser')
    localStorage.setItem('isAuthenticated', false)
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, signIn, logout }}>
      {children}
      <Toast ref={toast} />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
