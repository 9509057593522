import { addLocale } from "primereact/api";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroParceiro } from "../../actions";
import { getAllTiposParceiro } from "../../api";
import { paginationDefault } from "../../default/pagination";
import { brazilianCalendarParams } from "../../utils";
import { Filtro } from "../Filtro";

export const FiltroParceiro = ({
  abrirFiltro,
  setAbrirFiltro,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();

  const [id, setId] = useState( useSelector((state) => state.filtroParceiro.id));
  const [parceiroTipos, setTipo] = useState(
    useSelector((state) => state.filtroParceiro.parceiroTipos)
  );
  const [nomeTratamento, setNomeTratamento] = useState(
    useSelector((state) => state.filtroParceiro.nomeTratamento)
  );
  const [nomeCidadePrincipal, setNomeCidadePrincipal] = useState(
    useSelector((state) => state.filtroParceiro.nomeCidadePrincipal)
  );
  const [nomeCompleto, setNomeCompleto] = useState(
    useSelector((state) => state.filtroParceiro.nomeCompleto)
  );
  const [historico, setHistorico] = useState(
    useSelector((state) => state.filtroParceiro.historico)
  );
  const [dataNasc, setDataNasc] = useState(
    useSelector((state) => state.filtroParceiro.dataNasc)
  );
  const [cpfCnpj, setCpfCnpj] = useState(
    useSelector((state) => state.filtroParceiro.cpfCnpj)
  );
  const [rgInscEstadual, setRgInscEstadual] = useState(
    useSelector((state) => state.filtroParceiro.rgInscEstadual)
  );
  const [ufInscricao, setUfInscricao] = useState(
    useSelector((state) => state.filtroParceiro.ufInscricao)
  );
  const [insMunicipal, setInsMunicipal] = useState(
    useSelector((state) => state.filtroParceiro.insMunicipal)
  );
  const [ativo, setAtivo] = useState(
    useSelector((state) => state.filtroParceiro.ativo)
  );
  const [tipoPessoa, setTipoPessoa] = useState(
    useSelector((state) => state.filtroParceiro.tipoPessoa || "F")
  );
  const [colaborador, setColaborador] = useState(
    useSelector((state) => state.filtroParceiro.colaborador)
  );
  const [sexo, setSexo] = useState(
    useSelector((state) => state.filtroParceiro.sexo)
  );
  const [dataPrimeiroFaturamento, setDataPrimeiroFaturamento] = useState(
    useSelector((state) => state.filtroParceiro.dataPrimeiroFaturamento)
  );
  const [ufPrincipal, setUfPrincipal] = useState(
    useSelector((state) => state.filtroParceiro.ufPrincipal)
  );
  const [diasMedios, setDiasMedios] = useState(
    useSelector((state) => state.filtroParceiro.diasMedios)
  );
  const [listaTipo, setListaTipo] = useState([]);

  async function getAllTypesParceiro() {
    if (listaTipo.length === 0) {
      await getAllTiposParceiro().then((resultado) => {
        setListaTipo(resultado)
      })
    }
  }

  const listaUf = [
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ];

  const filterFields = [
    {
      label: "Código:",
      value: id,
      setState: setId,
      type: "input",
      dataType: "pint",
      datatesteid: "idTest",
    },
    {
      label: "Tipo ",
      value: parceiroTipos,
      setState: setTipo,
      type: "multiSelect",
      options: listaTipo,
      optionLabel: "tipo",
      optionValue: "id",
      onFocus: getAllTypesParceiro
    },
    {
      label: "Nome de Tratamento:",
      value: nomeTratamento,
      setState: setNomeTratamento,
      type: "input",
      datatesteid: "nomeTratamentoTest",
      maxLength: 100,
    },
    {
      label: "Status:",
      value: ativo,
      setState: setAtivo,
      type: "selectButton",
      options: [
        { name: "Ativo", value: "S" },
        { name: "Inativo", value: "N" },
      ],
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "statusTest",
    },
    {
      label: "Tipo de Pessoa:",
      value: tipoPessoa,
      setState: setTipoPessoa,
      type: "selectButton",
      options: [
        { label: "Física", value: "F" },
        { label: "Jurídica", value: "J" },
      ],
      datatesteid: "tipoPessoaTest",
    },
    {
      label: "CPF/CNPJ:",
      value: cpfCnpj,
      setState: setCpfCnpj,
      type: "inputMaskCpfCnpj",
      state: tipoPessoa,
      datatesteid: "cpfCnpjTest",
    },
    {
      label: "Sexo:",
      value: sexo,
      setState: setSexo,
      options: [
        { label: "Masculino", value: "M" },
        { label: "Feminino", value: "F" },
      ],
      type: "selectButton",
      placeholder: "Escolha um Sexo",
      datatesteid: "sexoTest",
    },
    {
      label: "UF Principal:",
      value: ufPrincipal,
      setState: setUfPrincipal,
      type: "dropdown",
      options: listaUf,
      datatesteid: "ufPrincipalTest",
    },
    {
      label: "Nome Cidade Principal:",
      value: nomeCidadePrincipal,
      setState: setNomeCidadePrincipal,
      datatesteid: "nomeCidadePrincipalTest",
      maxLength: 200,
    },
  ];

  addLocale("pt-br", brazilianCalendarParams);

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroParceiro({
        id: id !== "" ? id : null,
        parceiroTipos,
        nomeTratamento: nomeTratamento !== "" ? nomeTratamento : null,
        nomeCidadePrincipal:
          nomeCidadePrincipal !== "" ? nomeCidadePrincipal : null,
        nomeCompleto,
        historico,
        dataNasc,
        cpfCnpj,
        rgInscEstadual,
        ufInscricao,
        insMunicipal,
        ativo,
        tipoPessoa,
        // cliente,
        // fornecedor,
        colaborador,
        sexo,
        dataPrimeiroFaturamento,
        ufPrincipal,
        diasMedios,
      })
    );
  };

  const limpar = useCallback(async() => {
    setId('');
    setTipo(null);
    setNomeTratamento('');
    setNomeCompleto(null);
    setHistorico(null);
    setDataNasc(null);
    setCpfCnpj(null);
    setRgInscEstadual(null);
    setUfInscricao(null);
    setInsMunicipal(null);
    setTipoPessoa(null);
    // setCliente(null);
    // setFornecedor(null);
    setColaborador(null);
    setSexo(null);
    setDataPrimeiroFaturamento(null);
    setUfPrincipal(null);
    setNomeCidadePrincipal('');
    setDiasMedios(null);
    setAtivo(null);
    setVisible(() => false)
    setClearInputs(false)
  }, [setClearInputs, setVisible]);

  useEffect(() => {
    if (clear === true) {
      limpar()
    }
  }, [clear, limpar])

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <Filtro
      filterFields={filterFields}
      setIsFiltroVisible={setAbrirFiltro}
      isFiltroVisible={abrirFiltro}
      clearFilter={limpar}
      handleConfirmFilter={filtrar}
    />
  );
};
