import { SelectButton } from "primereact/selectbutton";
import React from "react";
import "./styles.css";

export const SelectButtonForm = ({
  label,
  value,
  setState,
  options,
  optionLabel,
  optionValue,
  required,
  errorMessage,
  datatesteid,
  setAlteracoes = () => {}
}) => {
  return (
    <div key={label} className="selectButton__form">
      <div>
        <label>{label}</label>
        {required && <abbr className="form__asterisk">*</abbr>}
      </div>

      <div className="selectButton__container">
        <SelectButton
          validacao
          className="filter-select"
          value={value}
          options={options}
          optionLabel={optionLabel}
          data-testid={datatesteid}
          optionValue={optionValue}
          onChange={(e) => {
            setState(e.target.value)
            setAlteracoes(true)
          }}
        />
      </div>
      <small className="form__errorMsg">{errorMessage}</small>
    </div>
  );
};
