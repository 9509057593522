const INITIAL_STATE = {
  listaConciliacao: [],
  salvarConciliacao: null,
};

export const conciliacao = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_CONCILIACAO":
      return { ...state, salvarConciliacao: action.payload };
    case "ADD_LISTA_CONCILIACAO":
      return { listaConciliacao: action.payload, loading: false };
    case 'EXIBE_LOADING':
      return { ...state, loading: true };
    case 'ESCONDER_LOADING':
      return { ...state, loading: false };
    default:
      return state;
  }
};
