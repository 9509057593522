import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;
const urlGcp = process.env.REACT_APP_API_GCP;
const directory = process.env.REACT_APP_DIRECTORY_GOOGLE_CLOUD

export default axios.create({
  baseURL: `${url}`,
});

export const saveParcela = (parcelaDTO) =>
  axios.post(`${url}/parcelas`, parcelaDTO).then((response) => response.data);

export const getAllFilterParcela = (requestParams) =>
  axios
    .post(
      `${url}/parcelas/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const deleteParcela = async (id) =>
  axios.delete(`${url}/parcelas/${id}`).then((response) => response.status);

export const deletarArquivosParcela = async (id) =>
  axios.delete(`${urlGcp}/gcp/deleteObjectsWithPrefix?directoryPrefix=${directory}parcela/${id}/`)
    .then((response) => response.data);

export const updateParcela = (parcela, id) =>
  axios
    .put(`${url}/parcelas/${id}`, parcela)
    .then((response) => response.data);

export const getParcelaByDescricao = (descricao) =>
  axios
    .get(`${url}/parcelas/nome-descricao/${descricao}`)
    .then((response) => response.data);
