import { Dialog } from "primereact/dialog";
import React from "react";
import { ButtonComponent } from "../ButtonComponent";
import { FormInputs } from "../FormInputs";
import "./styles.css";

export const ModalAdicao = ({ title, visible, setVisible, cancel, confirm, fields, onSubmit, onHide, message, dialogStyle, dialogStyleForm }) => {

    return (
        <Dialog className="modalAdicao" header={title} visible={visible} onHide={onHide} data-testid="modalAdicao" style={dialogStyle}>
            <form style={dialogStyleForm}>
                <FormInputs arrayInputs={fields} />
            </form>
            {message && (
                <p className="message_modal">{message}</p>
            )}
            <div data-testid="modalAdicao__buttons" className="modalAdicao__buttons">
                <ButtonComponent datatesteid="modalAdicao__cancel" type="cancel" onClick={() => cancel()} />
                <ButtonComponent datatesteid="modalAdicao__confirm" type="confirm" onClick={() => confirm(true)} />
            </div>
        </Dialog>
    )
}
