import React from "react";
import ReactHintFactory from "react-hint";

export const ReactHintComponents = () => {
    const ReactHint = ReactHintFactory(React);

    return (
        <>
            <ReactHint autoPosition events delay={{ show: 100, hide: 100 }} /><ReactHint
                persist
                attribute="data-custom"
                className="custom-hint"
                events={{ click: true }}
            />
        </>
    )
}
