import axios from "axios";

const url = process.env.REACT_APP_SSO_BASE_URL;

export default axios.create({
    baseURL: `${url}`,
  });

export const autenticarUsuario = (login, password) => {
  return axios
    .post(`${url}/product-login/auth/login`, { login, password })
    .then((response) => {
      localStorage.setItem("loggerUser", JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => {
      console.error("Erro na autenticação:", error);
      throw error;
    });

};