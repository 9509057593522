import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import React from "react";

export const AutoCompletarComponent = ({
  value,
  options,
  setValue,
  errorMessage,
  optionLabel,
  label,
  required,
  disabled,
  datatesteid,
  onClickDelete,
  completeMethod,
  deleteDisable,
  minLength,
  genericFunction = () => {}
}) => {
  let searchParam;
  function handleChangeValues(e) {
    genericFunction();
    setValue(e.value);
    searchParam = e.value;
  }

  return (
    <div key={label} className={label && "p-fluid select__form"}>
      {label && (
        <div>
          <label>{label}</label>
          {required && (
            <abbr data-testid="abbr-asteristic" className="form__asterisk">
              *
            </abbr>
          )}
        </div>
      )}
      <div className={onClickDelete && "select"}>
        <AutoComplete
          value={value}
          suggestions={options}
          aria-label={label}
          field={optionLabel}
          onChange={(e) => handleChangeValues(e)}
          completeMethod={() => completeMethod(searchParam)}
          minLength={minLength || "3"}
          className="select__dropdown"
          tooltip={minLength ? `Digite pelo menos ${minLength} caracter.` : "Digite pelo menos 3 caracteres."}
          tooltipOptions={{ event: "hover", position: "left" }}
          delay={800}
          disabled={disabled}
          data-testid={datatesteid}
        />
        <small className="form__errorMsg">{errorMessage}</small>
        {onClickDelete && (
          <Button
            style={{
              minWidth: "50px",
              background: "red",
              border: "red",
            }}
            icon="pi pi-trash"
            tooltip="Excluir Filtro"
            tooltipOptions={{ position: "left" }}
            disabled={deleteDisable}
            data-testid="deleteTest"
            onClick={onClickDelete}
          />
        )}
      </div>
    </div>
  );
};
