const INITIAL_STATE = {
  id: null,
  parceiroTipos: null,
  nomeTratamento: null,
  nomeCidadePrincipal: null,
  nomeCompleto: null,
  historico: null,
  dataNasc: null,
  cpfCnpj: null,
  rgInscEstadual: null,
  ufInscricao: null,
  insMunicipal: null,
  ativo: null,
  tipoPessoa: null,
  // cliente: null,
  // fornecedor: null,
  // colaborador: null,
  sexo: null,
  dataPrimeiroFaturamento: null,
  ufPrincipal: null,
  diasMedios: null,
};

export const filtroParceiro = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_PARCEIRO":
      return { ...action.payload };
    default:
      return state;
  }
};
