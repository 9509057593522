export const setFiltro = (filtro) => ({
    type: "SET_FILTRO",
    payload: filtro,
});

export const setDataInicioModificada = (dataInicioModificada) => ({
    type: "SET_DATA_INICIO_MODIFICADA",
    payload: dataInicioModificada,
});

export const setDataFimModificada = (dataFimModificada) => ({
    type: "SET_DATA_FIM_MODIFICADA",
    payload: dataFimModificada,
});

export const setDataInicioPrevista = (dataInicioPrevista) => ({
    type: "SET_DATA_INICIO_PREVISTA",
    payload: dataInicioPrevista,
});

export const setDataFimPrevista = (dataFimPrevista) => ({
    type: "SET_DATA_FIM_PREVISTA",
    payload: dataFimPrevista,
});

export const setDataInicioBaixa = (dataInicioBaixa) => ({
    type: "SET_DATA_INICIO_BAIXA",
    payload: dataInicioBaixa,
});

export const setDataFimBaixa = (dataFimBaixa) => ({
    type: "SET_DATA_FIM_BAIXA",
    payload: dataFimBaixa,
});

export const setProjeto = (projeto) => ({
    type: "SET_PROJETO",
    payload: projeto,
});

export const setCnpj = (cnpj) => ({
    type: "SET_CNPJ",
    payload: cnpj,
});

export const setPagina = (pagina) => ({
    type: "SET_PAGINA",
    payload: pagina,
});

export const setQtdRegistro = (pagina) => ({
    type: "SET_QTD_REGISTRO",
    payload: pagina,
});

export const filtroVencidoSemBaixa = (vencidoSemBaixa) => ({
    type: "FILTRO_VENCIDO_SEM_BAIXA",
    payload: vencidoSemBaixa,
});

export const setTamanhoPagina = (tamanhoPagina) => ({
    type: "SET_TAMANHO_PAGINA",
    payload: tamanhoPagina,
});

export const setTamanhoTotal = (tamanhoTotal) => ({
    type: "SET_TAMANHO_TOTAL",
    payload: tamanhoTotal,
});

export const setSortField = (sortField) => ({
    type: "SET_SORT_FIELD",
    payload: sortField,
});

export const setSortOrder = (sortOrder) => ({
    type: "SET_SORT_ORDER",
    payload: sortOrder,
});

export const setValorMinimo = (valorMinimo) => ({
    type: "SET_VALOR_MINIMO",
    payload: valorMinimo,
});

export const setValorMaximo = (valorMaximo) => ({
    type: "SET_VALOR_MAXIMO",
    payload: valorMaximo,
});

export const setBaixaBoolean = (baixaBoolean) => ({
    type: "SET_BAIXA_BOOLEAN",
    payload: baixaBoolean,
});

export const setSemDocumentoComBaixa = (semDocumentosComBaixa) => ({
    type: "SET_SEM_DOCUMENTOS_COM_BAIXA",
    payload: semDocumentosComBaixa,
});

export const setVencidoSemEmissaoComBaixa = (vencidoSemEmissaoComBaixa) => ({
    type: "SET_VENCIDO_SEM_EMISSAO_COM_BAIXA",
    payload: vencidoSemEmissaoComBaixa,
});

export const addListaTotalizadores = (totalizadores) => ({
    type: "ADD_LISTA_TOTALIZADORES",
    payload: totalizadores,
});

export const addAlerta = (alertas) => ({
    type: "ADD_ALERTA",
    payload: alertas,
});
