const INITIAL_STATE = {
  id: null,
  // idUsuario: null,
  idContrato: null,
  descricaoParcela: null,
  numeroParcela: null,
  valorParcela: null,
  dataEmissao: null,
  dataVencimentoPrevisto: null,
  dataBaixa: null,
  valorBaixa: null,
  statusParcela: null,
  dataCriacao: null,
  dataAlteracao: null,
  idUsuarioCriou: null,
  idUsuarioAlterou: null,
};

export const filtroParcela = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_PARCELA":
      return { ...action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_QTD_REGISTRO":
      return { ...state, qtdRegistros: action.payload };

    default:
      return state;
  }
};
