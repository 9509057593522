import { Button } from "primereact/button";
import React from "react";
import "./styles.css";

export const ButtonComponent = ({
  onClick,
  type,
  disabled,
  datatesteid,
  tooltip,
  tooltipOptions,
  ...props
}) => {
  const buttonTypes = {
    confirm: {
      label: "Confirmar",
      className: "button__confirm",
      type: "button",
    },
    confirmForm: {
      label: "Confirmar",
      className: "button__confirm",
      type: "submit",
    },
    cancel: { label: "Cancelar", className: "button__cancel", type: "button" },
    delete: { label: "Deletar", className: "button__cancel", type: "button" },
    rateio: { label: "Rateio", className: "button__rateio", type: "button" },
    saldo: { label: "Saldo", className: "button__rateio", type: "button" },
    info: {
      label: "",
      className: "button__info",
      type: "button",
      icon: "pi pi-question-circle",
    },
    logout: {
      label: "Sair",
      className: "button__logout",
      type: "button",
      icon: "pi pi-sign-out",
    },
  };

  return (
    <div data-testid="buttonComponent__container">
      <Button
        className={buttonTypes[type].className}
        label={buttonTypes[type].label}
        onClick={onClick}
        disabled={disabled}
        type={buttonTypes[type].type}
        tooltip={tooltip}
        icon={buttonTypes[type]?.icon}
        data-testid={datatesteid}
        tooltipOptions={tooltipOptions}
        {...props}
      />
    </div>
  );
};
