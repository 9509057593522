import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { EditarContaCorrente } from "../../components/EditarContaCorrente";

export const AlterarContaCorrente = (props) => {
    const toast = useRef(null);

    return (
        <>
            <Toast ref={toast} />
            <EditarContaCorrente
                editConta={props?.location?.state?.conta}
                page={props?.location?.state?.page}
            />
        </>
    )
}
