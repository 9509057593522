const INITIAL_STATE = {

    naturezaHierarquica: null
};

export const naturezaHierarquica = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_NATUREZA_HIERARQUICA':
            return { naturezaHierarquica: action.payload };

        default:
            return state
    }
}
