const INITIAL_STATE = {
    nomeProjeto: null,
};

export const filtroProjetoDrop = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_PROJETO_DROP":
            return { ...action.payload };
        default:
            return state;
    }
};
