import { Checkbox } from 'primereact/checkbox';
import React from 'react';
import { messageRequired } from '../../../default/messages';
import "./styles.css";

export const CheckBoxForm = ({ label, value, setState, isEmpty }) => {
    return (
        <div key={label} className="checkbox__form">
            <div className="checkbox__container">
                <label> Vencidos Sem Baixa</label>
                <Checkbox checked={value} onChange={e => setState(e.checked)} data-testid="checkboxTest" />
            </div>

            <small
                className='form__errorMsg'
                hidden={!isEmpty}
                data-testid="smallTest"
            >
                {messageRequired}
            </small>
        </div>
    )
}
